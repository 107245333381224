// DO NOT modify this file
// ** auto generated by "npm run get-text2" (Or "yarn get-text2")

export default {
  "1": "12",
  "222": "2",
  "Server.codes.DRIVERLICENSENUMBER_HAS_ALREADY_REGISTERED": "Driver license number has already registered.",
  "Server.codes.IDENTIFICATIONNUMBER_HAS_ALREADY_REGISTERED": "Identification number has already registered.",
  "fiat.MEMO": "MEMO",
  "Server.codes.INSUFFICIENT_FUNDS": "Error: Insufficient Funds",
  "page-block-trading-create-SuitabilityQuestionnaire": "RFQ - Client Suitability Questionnaire",
  "pages.kycIndividual.englishfieldError3": "Only numbers, alphabets, hyphens, underscores, dots and spaces are accepted.",
  "page.Tag.copied": "Tag copy success.",
  "page.MemoId.copied": "Memo ID copy success.",
  "pages.RechargePage.tip12": "Minimum {currency} Deposit:",
  "fiat.MinimumSUDDeposit": "Minimum USD Deposit",
  "Server.codes.USERNAME_NOT_CAN_SIGN_IN_USERNAME_CLOSED": "Your account has been locked, please contact support@matrix.co for assistance",
  "pages.RechargePage.tip11": "Deposit to the {currency} address requires 30 network confirmations.",
  "pages.RechargePage.tip10": "{currency} address only accepts {currency} deposits from the Ethereum (ERC-20) network. Any other assets deposited to {currency} address will be irretrievable.",
  "Server.codes.GA_USED": "Ga Has Been Used",
  "pages.kyc.TooltipExplainingIBANsubTips": "Enter IBAN if it is mandatory for the Bank",
  "pages.kyc.form.tagRules3": "The input is invalid.",
  "pages.kyc.form.tagRules2": "Tags ranges should from 1 until 4294967295.\n",
  "components.Footer.footerExplainNew": "Virtual Assets can be volatile and may not be suitable for everyone. Please ensure you fully understand the risks involved. Matrix does not issue advice, make recommendations or opinion in relation to acquiring, holding or disposing of Virtual Assets. Matrix  is not a financial advisor, and all services are provided on an execution only basis. Matrix is a company registered at Office 5, Floor 7, Al Sarab Tower, Abu Dhabi Global Market Square, Al Maryah Island, Abu Dhabi, United Arab Emirates, and is authorised and regulated (Registered Number: 000001876) by the Financial Services Regulatory Authority under the laws of Abu Dhabi Global Market. The information on this site is not directed at residents and Citizens of the Chinese Mainland and the Chinese Macau Special Administrative Region. The information on this site is also not directed at residents of the United States, US Persons, or use by any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.",
  "page.cryptoAssets.table.blockTrade": "To Be Release From Block Trading",
  "components.Header.BlockTrade": "Block Trade",
  "page-block-trading-create-RequestAQuote": "Request A Quote\n",
  "page-block-trading-email-context": "You are not eligible for Block Trading as you are classified as a Retail Client.\nIf you’d like to request a reclassification to Professional, please email",
  "page-block-trading-wrap2-MatrixGuarantee-cont": "Unlike in the open order book where you are transacting against all Matrix customers who are required to prefund there account, the liquidity \nproviders are 3rd party companies with the specific reasonability of providing our customers quotes for larger volume trades. This creates a credit \nrisk between you and the liquidity provider. That is why at Matrix we have instituted the Guarantee that all Block Trades will be honored by Matrix so \nif a liquidity provider was to renege on a trade Matrix will make the customer complete and honor the trade.",
  "page-block-trading-wrap2-MatrixGuarantee": "Matrix Guarantee",
  "page-block-trading-wrap2-Rules-cont": "Requirements and rules to participate in Block Trading can be found in our",
  "page-block-trading-wrap2-Rules": "Rules",
  "page.HomePage.BTtopItemContent4": "Matrix receives the best block trades from leading trading desks around the world.\n",
  "page.HomePage.BTtopItemTitle4": "Trusted Partners \n",
  "page.HomePage.BTtopItemContent3": "Speed, transparency, and easy access of electronically traded markets, \nwith the flexibility and efficient price discovery of a brokered market",
  "page.HomePage.BTtopItemTitle3": "Flexible System\n",
  "page.HomePage.BTtopItemContent2": "Allows participants to get competitive quotes, even during times of \nlow market activity",
  "page.HomePage.BTtopItemTitle2": "24/7 x 365\n",
  "page.HomePage.BTtopItemContent1": "Execute large orders as block trades without undue slippage.",
  "page.HomePage.BTtopItemTitle1": "Efficient Orders\n",
  "page-block-trading-wrap3-t": "Benefits of Block Trading",
  "page-block-trading-wrap2-cont2": "Matrix has multiple liquidity providers that we have reviewed and approved to provide quotes for Block Trades. By having multiple this means they \nneed to fight to get your business requiring them to submit the best possible quote.",
  "page-block-trading-wrap2-cont1": "The Block Trade works by allowing you to make a Request for Quote (RFQ) for a specific size of transaction in the Virtual Asset you are interested in. \nThis request is electronically sent to Matrix approved liquidity providers. They will review your request and send back a price to Matrix that they \nwould be willing to execute your trade at. Matrix will review all the prices and displays to you the best one. You will then have a short period of time \nwhere you can decide if you want to complete the trade, or you can let the quote time out and submit a new request at a later date",
  "page-block-trading-wrap2-t": "How Block Trading Works",
  "app.containers.LoginPage.Eligibility": "Sign In to Check Eligibility\n",
  "page.homepage.ipaTextbt": "Matrix now offers a Block Trade function for larger trades that execute off the \nopen order book. No more worrying about undue slippage.",
  "page.HomePage.BTb2": "For Professional Clients\n",
  "page.HomePage.BTb1": "Block Trading \n",
  "components.Header.PRO": "PRO",
  "components.Header.BlockTrading": "Block Trading",
  "page.tradingView.midpoint.price.tip": "Midpoint between current bid and offer on Matrix Orderbook.(Refreshes every second)",
  "Server.codes.REFUND_RULES_NOT_FOUND": "Refund rules of XRP not found.",
  "page.WithdrawForm.XRP2.content": "Please confirm if the receiving address requires a XRP Tag. If it is filled incorrectly, the asset will be lost. Other exchanges or wallets also call XRP Tag names Memo ID, Digital ID, Label, Notes, and Comments.",
  "pages.component.model-xlm-copy.content": "Kindly ensure to mention the Memo ID while depositing XLM to your Matrix Wallet. Failure to mention the Memo ID or mentioning incorrect Memo ID will result in loss of funds. Other exchanges or wallets also call Memo ID names Tag, Digital ID, Label, Notes, and Comments.",
  "pages.component.model-xml.content": "Kindly ensure to mention the Memo ID while depositing XLM to your Matrix Wallet. Failure to mention the Memo ID or mentioning incorrect Memo ID will result in loss of funds. Other exchanges or wallets also call Memo ID names Tag, Digital ID, Label, Notes, and Comments.",
  "pages.kyc.form.tagRules": "Please enter 1-9 digits",
  "input.placeholder.enterTag": "Please enter your Tag",
  "component.priceOrder.priceTitle": "Matrix Orderbook",
  "component.priceOrder.title": "Matrix Historical Trades",
  "pages.component.model.XRPcontent": "Kindly ensure to mention the XRP Tag while depositing XRP to your Matrix Wallet. Failure to mention the Tag or mentioning incorrect Tag will result in loss of funds. Other exchanges or wallets also call XRP Tag names Memo ID, Digital ID, Label, Notes, and Comments.",
  "page.WithdrawForm.XML.content": "Please confirm if the receiving address requires a Memo ID. If it is filled incorrectly, the asset will be lost. Other exchanges or wallets also call Memo ID names Tag, Digital ID, Label, Notes, and Comments.",
  "page.WithdrawForm.XRP.warningTip": "If the withdrawal address requires a Tag, please fill it.",
  "page.WithdrawForm.XRP.content": "Please confirm if the receiving address requires a Tag. If it is filled incorrectly, the asset will be lost. Other exchanges or wallets also call XRP Tag names Memo ID, Digital ID, Label, Notes, and Comments.",
  "page.WithdrawForm.XLM.warningTip": "If the withdrawal address requires a Memo ID, please fill it.",
  "pages.kycIndividual.requiredDocument.desc6": "•  All uploaded pictures of your documents must be photographs or scans of original documents. Do not upload photocopies of those documents as these will be rejected.",
  "pages.kyc.verificationContent6": "All uploaded pictures of your documents must be photographs or scans of original documents. Do not upload photocopies of those documents as these will be rejected.",
  "components.Footer.Social": "Social",
  "components.Footer.Medium": "Medium",
  "components.Footer.Linkedln": "Linkedln",
  "components.Footer.Twitter": "Twitter",
  "pages.kycIndividual.withdrawVirtualAssetsNumber": "How much virtual assets do you expect to withdraw each month?",
  "pages.kycIndividual.withdrawVirtualAsset": "How often will you withdraw  virtual assets each month? ",
  "pages.kyc.sideBarMessage7": "WE DO NOT PROVIDE SERVICES TO ANY RESTRICTED PERSON. IF YOU ARE AN RESTRICTED PERSON, PLEASE IMMEDIATELY STOP USING OUR SERVICES.PLEASE FIND THE DEFINITION OF RESTRICTED PERSON IN OUR USER AGREEMENT.FOR EXAMPLE, A US PERSON, OR A PERSON RESIDENT AND/OR DOMICILED IN THE CHINESE MAINLAND OR IN CHINESE MACAU SPECIAL ADMINISTRATIVE REGION ARE AMONG THOSE RESTRICTED PERSONS.",
  "Server.codes.MEMO_INVALID": "Memo ID/Tags error, please check it.",
  "page.WithdrawForm.warningTip": "If it is filled incorrectly, the asset will be lost.",
  "page.WithdrawForm.content": "Please confirm if the receiving address requires a Memo ID. If it is filled incorrectly, the asset will be lost.\n            Other exchanges or wallets also call Memo ID names Tag, Digital ID, Label, Notes, and Comments.",
  "pages.component.model.content": "Kindly ensure to mention Memo ID while depositing XLM to your Matrix wallet. Failure to do so will result in loss of funds.",
  "pages.component.model.title": "Important",
  "pages.api.form.refundMemoIdLabel": "refundMemoId",
  "input.placeholder.enterMemoId": "Please enter your Memo ID",
  "pages.kycInstitution.form.NationalIDExpiryDate": "National ID Expiry Date",
  "pages.kycIndividual.Multiple": "Dual/Multiple Nationality",
  "pages.kyc.TooltipExplainingIBAN": "The structure of your bank account number including the banking details for an International transfer, as provided by your Bank.",
  "pages.kyc.bankAccountNumberTip": "Your acount number issued by your Bank.",
  "pages.kyc.tip.imgVolumeSize": "File size should be no more than 10M.",
  "pages.kyc.submitDeclareAll": "Please declare all the above before submitting your kyc.",
  "pages.kyc.tip.fileSize": "File size can't exceed 10M",
  "pages.kyc.VerifyIdentityTips2": "withdrawals and start trading on our platform.",
  "pages.kyc.VerifyIdentityTips1": "Now that you've registered, we will need to verify your identity so you can make deposits,",
  "pages.kyc.tip.imgEdgeLength": "The length of the longer edge of the image should be larger than 300 and smaller than 30000.",
  "pages.kyc.tip.imgVolume": "Image must smaller than 300 * 300 !",
  "pages.kyc.tip.imgTypeTip": "You can only upload JPG/PNG file!",
  "pages.kyc.useResidentialAddress": "Use Residential Address",
  "pages.step5.marketDes": "A Market Counterparty is provided with the lowest degree of protection and can be a Financial Institution for example, a Bank or a Hedge Fund.",
  "pages.step5.marketTitle": "Are you a Market Counterparty ?",
  "pages.step5.professinalDes": "A professional client is generally a client who has sufficient experience, knowledge, expertise and understanding of relevant financial markets, products or transactions to make own investment decisions and can assess properly the associated risks when trading. Professional Clients are provided a lower degree of protection than Retail Clients. A Professional Client can opt to be classified as a Retail Client.",
  "pages.step5.professinalTitle": "Are you a Professional Client ?",
  "pages.step5.retailDes2": "Retail Clients are provided the highest degree of protection than Professional Clients or Market Counterparty.",
  "pages.step5.retailDes1": "A Person who cannot be classified as a Professional Client or a Market Counterparty in accordance with the Rules is a Retail Client.",
  "pages.step5.retailTitle": "Are you a Retail Client ?",
  "pages.step5.kycLevel.reminder": "In case you wish to change your client classification status, you can either opt-up or opt-down. For example, if you are a Professional Client and you would like to receive a higher level of protection, you can choose to submit your request under the “Edit Profile” section by selecting a Retail Client status. Another example could be, if you would like to opt-up and change your status from Retail Client to Professional Client, you will be exposed to more risks. As a Professional Client you may lose your assets and you will not be entitled to the highest level of protection.",
  "pages.kyc.form.addVisa": "Add UAE Visa",
  "pages.kyc.kycEditMessage2": "You can also submit a request to change your Account Status (from Retail to Professional or vice versa) by editing your Account Status on the left.",
  "pages.kyc.kycEditMessage1": "If you’d like to change some of the information you provided us to verify your identity, please select the relevant section on the left.",
  "pages.kyc.kycEditTitle": "You can edit the information you gave us:",
  "pages.kyc.sliderBarDeclaration": "Declaration",
  "pages.kyc.sliderBarMessage4": "I, declare that the information and documents provided are true, exact, accurate and complete. I agree to update my profile with Matrix immediately and as appropriate.",
  "pages.kyc.sliderBarMessage3": "I confirm that the information and documents provided are true, exact and complete, and I agree to notify Matrix of any modification to any of the information provided.",
  "pages.kyc.sliderBarMessage2": "I confirm that the bank details that I provided which will be used for all fiat deposits and withdrawals are mine.",
  "pages.kyc.sliderBarMessage1": "I confirm that I am acting on my own behalf and not on behalf of any second party.",
  "pages.kyc.verifyYourIdentity": "Verify Your Identity",
  "pages.kyc.sideBarMessage6": "Selfie of yourself holding up a piece of paper with the following written on it: your name, date of birth, date of when selfie was taken and “Matrix Limited”",
  "pages.kyc.sideBarMessage5": "Scan of another form of government issued photo ID",
  "pages.kyc.sideBarMessage4": "Scan of your passport",
  "pages.kyc.sideBarMessage3": "Bank statement showing the last 3 months of bank account activity",
  "pages.kyc.sideBarMessage2": "Two proofs of address",
  "pages.kyc.sideBarMessage1": "Tax ID (if you have one)",
  "pages.kyc.sideBarTitle": "These documents are required to complete the verification process:",
  "pages.kyc.completed": "Completed",
  "pages.kyc.inProgress": "In Progress",
  "pages.kyc.unstarted": "Unstarted",
  "pages.kyc.Senior_Management.Add": "Add Senior Management Details",
  "pages.kyc.Board_of_Directors.Add": "Add Board of Directors Details",
  "pages.kyc.Shareholders_Entity.Add": "Add Shareholders Company Details",
  "pages.kyc.otherPosition.placeholder": "Please fill in the description (required)",
  "pages.kyc.form.countryOfNationality": "Country of Nationality",
  "pages.kyc.shareholders_Individual.Add": "Add Shareholders Individual Details",
  "pages.kyc.choseManageType.atLeastOne": "Complete at least one entry for Shareholder-Individual and Shareholder-Company.",
  "pages.kyc.changeAccountStatusDetail": "You can switch your Account Status if you’re looking to trade with a different level of protection than what we have assigned you. Once your request has been submitted, we will inform you if you are qualified for the Account Status change.",
  "pages.kyc.changeAccountStatus": "You can change your Account Status:",
  "pages.kyc.imgProfessional": "Professional",
  "pages.kyc.imgRetail": "Retail",
  "pages.kyc.exposedToMoreRisk": "You will be exposed to more risk and you may lose your assets.",
  "pages.kyc.levelOfProtection": "The highest level of protection.",
  "page.CookieAlert.agreement": "Yes, I agree to all cookies",
  "page.CookieAlert.alink": "Privacy Policy",
  "page.CookieAlert.description": "We use cookies to improve your online experience and are necessary to make the site\n            work. All our cookie data is anonymised. Learn more about cookies in our  ",
  "pages.kyc.clientClassification": "Client Classification",
  "pages.kyc.uploadDocuments": "Upload Documents",
  "pages.kyc.whereYouBank": "Where You Bank",
  "pages.kyc.yourAssets": "Your Assets and Investments",
  "pages.kyc.aboutYou": "About You",
  "pages.kyc.idDocumentSelﬁe": "Selﬁe",
  "pages.kyc.idDocumentPhoto": "ID Document: Additional type of government ID with photo",
  "pages.kyc.idDocumentPassport": "ID Document: Passport",
  "pages.kyc.someGuidanceContent4": "You should hold up a piece of paper next to your head on which is written your name, your date of birth,date when the selfie was taken and the words \"Matrix  Limited\"",
  "pages.kyc.someGuidanceContent3": "Please do not wear sunglasses, tinted glasses, headphones, headsets, wireless hand free devices or hats",
  "pages.kyc.someGuidanceContent2": "The selfie should show your head and shoulders and should not be taken too far away or too close",
  "pages.kyc.someGuidanceContent1": "The selfie should not be blurry, grainy, pixelated, nor too bright or show shadows on your face",
  "pages.kyc.someGuidanceTitle": "Some guidance so your selfie will be accepted:",
  "pages.kyc.verificationContent5": "Document uploads must be in JPEG or PNG format.",
  "pages.kyc.verificationContent4": "Please upload the front and back of all ID (expect passport which only needs to be an\nupload of the ID page)",
  "pages.kyc.verificationContent3": "Please make sure that all of the information on the documents are clearly readable.",
  "pages.kyc.verificationContent2": "The scan/photo of the ID must be in the original color (and not black & white or any\nother color).",
  "pages.kyc.verificationContent1": "The documents must be valid official government ID.",
  "pages.kyc.verificationTitle": "Please follow these instructions when uploading your ID documents:",
  "pages.kyc.houseNumberAndStreet": "House/Apartment Number and Street",
  "pages.kyc.addressOfBank": "Address of Bank",
  "pages.kyc.uploadRecentBank": "Please upload a recent bank statement with at least three months of activity",
  "pages.kyc.nameOfBank": "Name of Bank",
  "pages.kyc.addressOfAccountHolder": "Address of Account Holder",
  "pages.kyc.nameOfAccountHolder": "Name of Account Holder",
  "page.kyc.accountHolderDetails": "Account Holder Details",
  "page.kyc.tradingTip": "Buy and Sell a certain asset on the platform.",
  "page.kyc.liquidityManagementTip": "Strategy to reduce liquidity risk exposure.",
  "page.kyc.investingTip": "Acquiring an asset with a long term view to sell it by making a profit.",
  "page.kyc.hedgingTip": "Mitigating the risk of price fluctuation through dfferent trading techniques.",
  "page.kyc.arbitrageTip": "Trading an asset on different platforms by taking advantage of price differences of the same asset.",
  "page.kyc.virtualAssetsTip": "An asset that could not be recognised as a legal tender, not issued by a Government and could be traded digitally.",
  "page.kyc.fiatCurrencyTip": "Currency issued by a Government as a legal tender, i.e: USD and EUR are fiat currencies.",
  "page.kyc.netWorthTip": "Total estimated wealth that is accumulated or acquired.",
  "page.kyc.addAdditionalType": "Add Additional Type",
  "pages.kyc.ResidentialAddressOption5": "Your most recent confirmation from an Electoral Register Search that a person of such a name lives at your current address.",
  "pages.kyc.ResidentialAddressOption4": "A valid tenancy agreement for the current year.",
  "pages.kyc.ResidentialAddressOption3": "An account statement or passbook from a bank account or Post Office savings bank account showing at least three months of activity.",
  "pages.kyc.ResidentialAddressOption2": "A local authority tax return (e.g. council tax) valid for the current year.",
  "pages.kyc.ResidentialAddressOption1": "A household utility bill (e.g. gas, electric, water, internet or fixed telephone line - but not a mobile phone bill). The bill must be valid within the last three months and show your name and current address.Controlling Person of a legal person – control by other means",
  "pages.kyc.ResidentialAddressMsg3": "of your Residential Address",
  "pages.kyc.ResidentialAddressMsg2": "two different proofs",
  "pages.kyc.ResidentialAddressMsg1": "Please provide",
  "pages.kycIndividual.emailMessage2": "mail@email.com",
  "pages.kycIndividual.emailMessage1": "The email you registered with Matrix is",
  "pages.kyc.MailingSameAsResidential": "My Mailing Address is the same as my Residential Address.",
  "pages.kyc.domicileSameAsResidential": "My Domicile Address is the same as my Residential Address.",
  "pages.kyc..usPersonDeclarationTips": "A person who is a citizen of the U.S.A., a resident of the U.S.A., a holder of a U.S.A. green card or someone who has substantial economic interest in the U.S.A.",
  "pages.kyc.label.faitCurrency": "Fiat currency",
  "pages.kyc.label.depositAndWithdraw": "Deposits and Withdrawals",
  "pages.kycIndividual.purposeAccount": "The purpose of your account",
  "pages.kycIndividual.withdrawVirtualNumber": "How much virtual assets do you expect to withdraw each month?",
  "pages.kycIndividual.withdrawVirtualTime": "How often will you withdraw virtual assets each month?",
  "pages.kycIndividual.depositVirtualNumber": "How much fiat currency do you expect to deposit each month?",
  "pages.kycIndividual.depositVirtualTime": "How often will you deposit virtual assets each month?",
  "pages.kycIndividual.withdrawFiatNumber": "How much fiat currency do you expect to withdraw each month?",
  "pages.kycIndividual.withdrawFiatTime": "How often will you withdraw  fiat currency each month?",
  "pages.kycIndividual.depositFiatNumber": "How much virtual assets do you expect to deposit each month?",
  "pages.kycIndividual.depositFiatTime": "How often will you deposit fiat currency each month?",
  "pages.kycIndividual.Background": "Your Background",
  "pages.kycIndividual.VirtualAssets": "Virtual assets",
  "pages.kycIndividual.Deposits": "Deposits and Withdrawals",
  "pages.kycIndividual.NetWorth": "Net Worth",
  "pages.kycIndividual.Politically": "Politically Exposed Person (PEP) Declaration",
  "pages.kycIndividual.TaxAndEmployment": "Employment Details",
  "pages.fiels.required": "These fields are required",
  "label.editPropfile": "Edit Profile",
  "pages.kyc.overview.declaration": "I, declare that the information and documents provided are true, exact, accurate and complete. I agree to update the Company's profile with Matrix Immediately and as appropriate.",
  "pages.kyc.bankinfo": "Bank Information",
  "pages.kyc.estimate": "Asset and Investment Information",
  "pages.overview.kycLevel.MARKET_COUNTERPARTY": "Market Counterparty",
  "pages.overview.kycLevel.PROFESSIONAL": "Professional Client",
  "pages.overview.kycLevel.RETAIL": "Retail Client",
  "pages.label.uploadDocuments": "Upload Documents",
  "pages.kyc.individual.update": "Individual KYC Update",
  "pages.kyc.company.update": "Company KYC Update",
  "pages.kyc.individual": "Individual KYC",
  "pages.kyc.company": "Company KYC",
  "input.error.emailNotMatch": "The two email are different. Please reenter it.",
  "label.repeatEmail": "Confirm Email",
  "input.error.confirmEmailEmpty": "Please confirm your email.",
  "app.containers.found.timeOut": "until available",
  "app.containers.found.resend": "Resend",
  "pages.monthlyStatements.endStatementTip": "*Fee is inclusive of 5% VAT, where applicable.",
  "pages.monthlyStatements.endStatement": "End of the Monthly Account Statement",
  "pages.monthlyStatements.closeBalance": "Balance at the end of the month",
  "pages.monthlyStatements.openBalance": "Balance brought forward from {date}",
  "pages.monthlyStatements.detailedSummary": "Detailed Transaction Summary-{currency}",
  "pages.monthlyStatements.credit": "Credit",
  "pages.monthlyStatements.debit": "Debit",
  "pages.monthlyStatements.particular": "Particular",
  "pages.monthlyStatements.transactionType": "Transaction Type",
  "pages.monthlyStatements.transactionConfirmationDate": "Transaction Confirmation Date",
  "pages.monthlyStatements.transactionDate": "Transaction Date",
  "pages.monthlyStatements.viewDetail": "View Detail",
  "pages.monthlyStatements.usdv": "USD",
  "pages.monthlyStatements.vat": "VAT",
  "pages.monthlyStatements.fiatAsset": "Fiat Asset",
  "pages.monthlyStatements.virtualAssetNotification": "* Closing balance includes buy and sell of virtual assets which are detailed below.",
  "pages.monthlyStatements.closing": "Closing",
  "pages.monthlyStatements.fee": "Fee",
  "pages.monthlyStatements.trading": "Trading",
  "pages.monthlyStatements.refund": "Refund",
  "pages.monthlyStatements.balanceStar": "Balance*",
  "pages.monthlyStatements.withdrawal": "Withdrawal",
  "pages.monthlyStatements.deposit": "Deposit",
  "pages.monthlyStatements.balance": "Balance",
  "pages.monthlyStatements.opening": "Opening",
  "pages.monthlyStatements.ofMonth": "of month(in USD)",
  "pages.monthlyStatements.assetValAtTheEnd": "Asset Value at the end",
  "pages.monthlyStatements.exchangeRate": "Exchange Rate",
  "pages.monthlyStatements.amount": "Amount",
  "pages.monthlyStatements.asset": "Asset",
  "pages.monthlyStatements.virtual": "Virtual",
  "pages.monthlyStatements.virtualAsset": "Virtual Asset",
  "pages.monthlyStatements.separatorTitle": "Asset Snapshot ({startTime} to {endTime})",
  "pages.monthlyStatements.netAssetValNotification": "* Virtual Asset Value is calculated based on each Virtual Asset’s price at the beginning\n          of the month and at the end of the month. These prices are shown in next table.",
  "pages.monthlyStatements.fiat": "Fiat",
  "pages.monthlyStatements.virtualAssetStar": "Virtual Asset*",
  "pages.monthlyStatements.assetValInUsd": "Asset Value in USD",
  "pages.monthlyStatements.assetType": "Asset Type",
  "pages.monthlyStatements.netAssetVal": "Net Asset Value",
  "pages.monthlyStatements.fromToTo": "{startTime} to {endTime}",
  "pages.monthlyStatements.dataOfIssue": "Date of Issue:",
  "pages.monthlyStatements.clientEmail": "Client Email:",
  "pages.monthlyStatements.fromTo": "From/To:",
  "pages.monthlyStatements.clientName": "Client Name:",
  "pages.monthlyStatements.accountInfo": "Account Information",
  "Server.codes.SYSTEM_BUSY": "System is busy, please try later.",
  "Server.codes.NOT_ALLOWED_SELF_TRADE": "Sorry, due to policy of self trade, this order cannot be placed.",
  "component.modal.title.attention": "Attention",
  "pages.OrderBook.errors.StopIsMoreThanLimit": "{Limit} will be less than 90% of {Stop}",
  "pages.OrderBook.errors.StopIsLessThanLimit": "{Limit} will be more than 110% of {Stop}",
  "input.error.8_11.number": "8 or 11 characters only.",
  "Server.codes.bankDetails.swiftCode": "Swift Code character length has to be between 8 and 13",
  "page.home.kycGotoVerify": "Verify Your Identity",
  "components.Footer.RiskDisclosures": "Risk Disclosures",
  "components.Footer.MarketRuleBook": "Market Rule Book",
  "pages.kyc.server_code.IMAGE_LENGTH_IS_TOO_LONG": "The edge length of the image should be under 30000.",
  "pages.uploader.imageSize": "The uploaded image should be png or jpg. The pixel should be larger than 300*300. The longer edge length of image can not exceed 30000. ",
  "fiat.history.swiftCode": "Silvergate Swift Code",
  "page.withdraw.beyondMax": "Cannot exceed the maximum amount.",
  "pages.kyc.server_code.IMAGE_SIZE_IS_TOO_SMALL": "The uploaded image is too small. Please re-upload a new one.",
  "Server.codes.KYC_PROCESS_UNFINISHED": "Finished KYC process first.",
  "Server.codes.VCODE_LIMIT_COUNT": "The number of code submissions for verification exceeds the limit.",
  "link.deleteTitle": "Delete Confirmation",
  "page.taxInvoice.pageTitle": "Tax Invoice",
  "components.Header.menuInvoice": "Tax Invoice",
  "NATIONALITY_COUNTRY_IS_NOT_ALLOWED": "Country in Passport is not allowed.",
  "test127": "english1271",
  "test126": "english126",
  "test124": "english38",
  "test123": "english37",
  "test111": "english36",
  "table.title.vat": "VAT",
  "label.vatText": "",
  "label.withdraw": "Withdraw",
  "label.vat": "VAT(5% of Fee)",
  "test1": "test1",
  "page.FundPassword.open": "Open Fund Password",
  "table.title.amountUsd": "Amount(USD)",
  "Server.codes.SYMBOL_HALTED": "Trading on this Pair is temporarily suspended, please try again later.",
  "page.trade.Halted": "Halted",
  "Server.codes.VISA_DOCUMENT_IS_MISSING": "Please Upload the UAE Visa Again.",
  "Server.codes.ENTITY_DOCUMENT_IS_MISSING": "Please Upload the Company Documents Again.",
  "Server.codes.DRIVING_DOCUMENT_IS_MISSING": "Please Upload the Driver's License Again.",
  "Server.codes.IDENTITY_DOCUMENT_IS_MISSING": "Please Upload the ID Again.",
  "Server.codes.SELFIE_DOCUMENT_IS_MISSING": "Please Upload the Selfie Again.",
  "Server.codes.PASSPORT_DOCUMENT_IS_MISSING": "Please Upload the Passport Again.",
  "Server.codes.PROOF_OF_ADDRESS_DOCUMENT_IS_MISSING": "Please Upload the Personal Documents Again.",
  "Server.codes.BANK_DOCUMENT_IS_MISSING": "Please Upload the Bank File Again.",
  "Server.codes.REFUND_AMOUNT_NEED_GREATER_THAN_OR_EQUAL_TO_MINIMUM_AMOUNT": "Refund amount over limit. ",
  "Server.codes.BEYOND_WEEKLY_LIMIT": "This account has exceeded the limit, please confirm!",
  "page.kyc.ControllingPersonStatusTip": "Please select the Controlling Person's Status.",
  "page.kyc.typeOfControllingPerson.m": "other (equivalent)",
  "page.kyc.typeOfControllingPerson.l": "beneficiary (equivalent)",
  "page.kyc.typeOfControllingPerson.k": "protector of the trustee (equivalent)",
  "page.kyc.typeOfControllingPerson.j": "trustee (equivalent)",
  "page.kyc.typeOfControllingPerson.i": "settlor (equivalent)",
  "page.kyc.typeOfControllingPerson.h": "other",
  "page.kyc.typeOfControllingPerson.g": "beneficiary",
  "page.kyc.typeOfControllingPerson.f": "protector of the trustee",
  "page.kyc.typeOfControllingPerson.e": "trustee",
  "page.kyc.typeOfControllingPerson.d": "settlor",
  "page.kyc.typeOfControllingPerson.c": "senior managing official",
  "page.kyc.typeOfControllingPerson.b": "control by other means",
  "page.kyc.typeOfControllingPerson.a": "control by ownership",
  "page.kyc.typeOfControllingPerson.ControllingPersonOfALegalArrangementOtherThanATrust": "Controlling Person of a legal arrangement other than a trust",
  "page.kyc.typeOfControllingPerson.ControllingPersonOfATrust": "Controlling Person of a trust",
  "page.kyc.typeOfControllingPerson.ControllingPersonOfALegalPerson": "Controlling Person of a legal person",
  "faitDeposit.DepositFiatFundsSec": "Deposit Instructions",
  "Server.codes.AGREE_EXEMPTION_OPERATION_FREQUENT": "Incorrect data recording.",
  "page.kyc.GIIN.EX": "EX, 09XCEX.00000.BR.832",
  "pages.kyc.form.invalid_GIIN": "Invaild GIIN",
  "page.kyc.GIIN": "GIIN",
  "pages.Account.idl.limited_account.description2": "If you have any questions, please contact our customer service.",
  "pages.Account.idl.limited_account.description1": "Your information is in an abnormal status and cannot be verified.",
  "fiat.bankBranchAddress": "Bank Branch Address",
  "fiat.deposit.importantInformation.c": "c. Please note that the funds sent to the wrong account cannot be retrieved by Matrix. In no case, Matrix can be held liable for such wrong deposit.",
  "fiat.deposit.importantInformation.b": "b. You are allowed to deposit funds from your registered bank account ONLY (the bank account that was already defined in your profile. Your bank account details are also shown in this page) only. If the sender’s bank account number or account name does not match with your registered bank account, Matrix may reject the deposit. \n",
  "fiat.deposit.importantInformation.a": "a. Third party deposits are prohibited. ",
  "fiat.deposit.fiat.funds.d": "d. Comply with your deposit limit. Any deposit transaction of more than available deposit limit will be rejected. Rejected transaction funds will be refunded to the originating bank account. \n",
  "fiat.deposit.fiat.funds.h": "h. Matrix’s Bank details may change. Make sure you check bank account details every time you make a deposit.",
  "fiat.deposit.fiat.funds.g": "g. Your deposit may take 2 - 5 working days to be credited to your wallet. You can track the status of your deposit in Deposit History. ",
  "fiat.deposit.fiat.funds.f": "f. Any deposit in currency other than USD will be converted to USD. Funds received in non - USD currency will be converted to USD by our Bank at its foreign exchange rate. Please note that this exchange rate is out of Matrix’s control. It is recommended that you transfer USD to Matrix’s bank account. However, you can send the funds in other currency as well. \n",
  "fiat.deposit.fiat.funds.e": "e. Matrix do not charge any deposit fee. However, you bank may charge a fee for sending funds to Matrix’s Bank Account. The net amount received by Matrix, after deduction of your bank’s fee, will be credited to your wallet balance. \n",
  "fiat.deposit.fiat.funds.c": "c. Please mention your Unique ID in remarks section when sending funds to Matrix’s Bank Account. Funds send without Unique ID will not be credited to your wallet. ",
  "fiat.deposit.fiat.funds.b": "b. A deposit transaction cannot be cancelled by client. However, you can withdraw the deposit made by using the withdraw option. ",
  "fiat.deposit.fiat.funds.a": "a. To deposit fiat funds to your wallet, send funds to Matrix Bank Account.",
  "fiat.withdraw.importantInformation.e": "e. Your receiving bank may charge a fee for receiving the funds. Therefore, the received funds will be after deduction of your bank’s charges, if any. If your bank account is not a USD currency account, your bank will convert the USD to account currency which may incur exchange charges.",
  "fiat.withdraw.importantInformation.d": "d. Withdrawal request processing may take 2 - 5 working days, depending on the receiving bank account. You can track your request status in withdrawal history. ",
  "fiat.withdraw.importantInformation.c": "c. Third party withdrawal is prohibited. ",
  "fiat.withdraw.importantInformation.b": "b. In case your bank account has changed, please register your new bank account with Matrix before request a withdrawal. ",
  "fiat.withdraw.importantInformation.a": "a. Funds can ONLY be withdrawn to your registered bank account. ",
  "page.deposit.addressLabel": "Please enter your refund address",
  "page.deposit.addressTipGA": "GA",
  "faitDeposit.DepositFiatFunds": "Deposit Fiat Funds",
  "fiat.AccountNumber": "Account Number",
  "page.account.FaitWithdrawalLimit": "Fiat Withdrawal Limit",
  "page.account.FaitDepositLimit": "Fiat Deposit Limit",
  "pages.Account.Preferences": "Preferences",
  "page.account.KYC.LevelOfAuthentication": "Level of Authentication",
  "account_history_SAVE_TIME_ZONE": "Set Time Zone",
  "page.account.VirtualAssetDepositWithdrawalLimit": "Virtual Asset Deposit / Withdrawal Limit (USD)",
  "page.account.FaitDepositWithdrawalLimit": "Fiat Deposit / Withdrawal Limit (USD)",
  "page.error.uploading": "Uploading in progress, non-removable.",
  "pages.Account.TimeZone.description": "Set your common time zone, the default is UTC+00.",
  "pages.Account.TimeZone": "Time Zone",
  "pages.kyc.form.legalEntityTypeOptions.Trust": "Trust",
  "account_history_GET_API_SECRET": "Get API Secret Key",
  "page.kyc.PleaseProvideABreakDownOfYourNetWorth": "Please provide a break down of your Net Worth.",
  "page.kyc.AmountUSD": "Amount USD",
  "page.kyc.NetWorth.amount": "100,000",
  "page.kyc.NetWorth.name": "EX, Real Estate",
  "page.Limit.AccountHoldingLimit.tip": "Maximum amount of Virtual Asset a client can hold with Matrix.",
  "page.Limit.FiatWithdrawalLimit": "Fiat Withdrawal Limit",
  "page.Limit.FiatDepositLimit": "Fiat Deposit Limit",
  "page.Limit.VirtualAssetWithdrawalLimit": "Virtual Asset Withdrawal Limit",
  "page.Limit.VirtualAssetDepositLimit": "Virtual Asset Deposit Limit",
  "page.Limit.AccountHoldingLimit": "Account Holding Limit",
  "page.fiat.Withdrawal.ImportantInformation.desc5": "e.     Your receiving bank may charge a fee for receiving the funds. Therefore, the received funds will be after deduction of your bank’s charges, if any. If your bank account is not a USD currency account, your bank will convert the USD to account currency which may incur exchange charges. ",
  "page.fiat.Withdrawal.ImportantInformation.desc4": "d.     Withdrawal request processing may take 2 - 5 working days, depending on the receiving bank account. You can track your request status in withdrawal history.",
  "page.fiat.Withdrawal.ImportantInformation.desc3": "c.     Third party withdrawal is prohibited.",
  "page.fiat.Withdrawal.ImportantInformation.desc2": "b.     In case your bank account has changed, please register your new bank account with Matrix before request a withdrawal.",
  "page.fiat.Withdrawal.ImportantInformation.desc1": "a.     Funds can ONLY be withdrawn to your registered bank account.",
  "page.fiat.Withdrawal.ImportantInformation": "Important Information",
  "page.fiat.WeeklyWithdrawalLimit": "Weekly Withdrawal Limit",
  "page.fiat.WeeklyDepositLimit": "Weekly Deposit Limit",
  "page.Fiat.Important.Information.desc3": "c.     Please note that the funds sent to the wrong account cannot be retrieved by Matrix. In no case, Matrix can be held liable for such wrong deposit.",
  "page.Fiat.Important.Information.desc2": "b.     You are allowed to deposit funds from your registered bank account ONLY (the bank account that was already defined in your profile. Your bank account details are also shown in this page) only. If the sender’s bank account number or account name does not match with your registered bank account, Matrix may reject the deposit.",
  "page.Fiat.Important.Information.desc1": "a.     Third party deposits are prohibited.",
  "page.Fiat.Important.Information.title": "Important Information",
  "page.Fiat.Deposit.Instructions.desc8": "h.     Matrix’s Bank details may change. Make sure you check bank account details every time you make a deposit.",
  "page.Fiat.Deposit.Instructions.desc7": "g.     Your deposit may take 2 - 5 working days to be credited to your wallet. You can track the status of your deposit in Deposit History.",
  "page.Fiat.Deposit.Instructions.desc6": "f.     Any deposit in currency other than USD will be converted to USD. Funds received in non- USD currency will be converted to USD by our Bank at its foreign exchange rate. Please note that this exchange rate is out of Matrix’s control. It is recommended that you transfer USD to Matrix’s bank account. However, you can send the funds in other currency as well.",
  "page.Fiat.Deposit.Instructions.desc5": "e.     Matrix do not charge any deposit fee. However, you bank may charge a fee for sending funds to Matrix’s Bank Account. The net amount received by Matrix, after deduction of your bank’s fee, will be credited to your wallet balance.",
  "page.Fiat.Deposit.Instructions.desc4": "d.     Comply with your deposit limit. Any deposit transaction of more than available deposit limit will be rejected. Rejected transaction funds will be refunded to the originating bank account.",
  "page.Fiat.Deposit.Instructions.desc3": "c.     Please mention your Unique ID in remarks section when sending funds to Matrix’s Bank Account. Funds send without Unique ID will not be credited to your wallet.",
  "page.Fiat.Deposit.Instructions.desc2": "b.     A deposit transaction cannot be cancelled by client. However, you can withdraw the deposit made by using the withdraw option.",
  "page.Fiat.Deposit.Instructions.desc1": "a.     To deposit fiat funds to your wallet, send funds to Matrix Bank Account.",
  "page.Fiat.Deposit.Instructions.title": "Deposit Instructions",
  "page.kyc.form.LegalEntityUAEResidence": "Do any of your POA, Shareholder(s), Director(s), Senior Management is/are holder(s) of a UAE residence visa with a validity of 5 years or more?",
  "page.kyc.form.ConsultantBrokerSwimmingCoach": "Ex. Consultant, Broker, Swimming Coach, etc.",
  "page.kyc.form.NonBusinessOwner": "Non-Business Owner (Freelancer, Consultant, Contractor, etc).\n",
  "page.kyc.form.BusinessOwner": "Business Owner (I operate my own business under a legal structure).\n",
  "page.kyc.form.AreYouABusinessOwnerOrNonBusinessOwner": "Are you a Business Owner or Non - Business Owner? \n",
  "page.kyc.form.SelfieTips.desc": "The quality of the selfie should not be blurry, grainy, pixelated, not too bright or that show shadows on your face. Please have a neutral facial expression with both eyes open and the selfie should not be taken too far away or too close. Your head, shoulders, and the paper on which you’ll be writing your name and date of birth, addressed to Matrix should clearly appear in the selfie. Please do not wear sunglasses, tinted glasses, headphones, headsets, wireless hands free devices when taking the selfie.\n",
  "page.kyc.form.SelfieTips.title": "Some guidance for your selfie to be accepted:",
  "page.kyc.form.SelfieTips": "Selfie Tips!",
  "pages.kyc.form.ManagementDetails.SeniorManagement": "Senior Management",
  "pages.kyc.form.ManagementDetails.ShareholdersEntity": "Shareholders Company",
  "pages.kyc.form.ManagementDetails.ShareholdersIndividual": "Shareholders Individual",
  "page.kyc.form.AreYouAHolderOfAUAEResidenceVisaFor5YearsOrMore": "Are you a holder of a UAE residence visa with a validity of 5 years or more?",
  "page.kyc.form.IsTheControllingPersonAHolderOfAUAEResidenceVisaFor5YearsOrMore": "Is the controlling person a holder of a UAE residence visa for 5 years or more?",
  "fiat.IntermediaryBankBIC": "Intermediary Bank SWIFT / BIC Code",
  "fiat.IntermediaryBankAddress": "Intermediary Bank Address",
  "fiat.IntermediaryBankName": "Intermediary Bank Name",
  "fiat.BeneficiaryAccountNumber": "Beneficiary Account Number",
  "fiat.BeneficiaryAccountCity": "Beneficiary Account City",
  "fiat.BeneficiaryBankCountry": "Beneficiary Bank Country",
  "fiat.BeneficiaryAccountCountry": "Beneficiary Account Country",
  "fiat.BeneficiaryBankName": "Beneficiary Bank Name",
  "page.kyc.controllingPerson.LegalNameTitle": "Please enter the legal name of the Controlled Entities of which you are a Controlling Person.",
  "page.kyc.form.legalNameOfEntity": "Name of Company",
  "fiat.BeneficiaryBankAddress": "Beneficiary Bank Address",
  "fiat.BeneficiaryBankBIC": "Beneficiary Bank SWIFT / BIC Code",
  "fiat.BeneficiaryAccountName": "Beneficiary Account Name",
  "fiat.BeneficiaryAccountAddress": "Beneficiary Account Address",
  "fiat.transitNumber": "Wire Routing Transit Number (RTN / ABA) ",
  "pages.kyc..usPersonDeclaration": "US Person Declaration",
  "page.form.err.EnglishOrSpace": "English characters or space only.",
  "pages.kyc.form.Management.AddDifferentManagementDetails": "Add {type} Management Details",
  "pages.Account.vatNotification.description": "Receive VAT email notification",
  "pages.Account.vatNotification": "VAT Notification",
  "table.title.vatInUsd": "VAT (USD)",
  "page.MonthlyStatements.vatInUsd": "VAT (USD)",
  "pages.OpenOrderPage.vatInUsd": "VAT (USD)",
  "pages.kyc.new.form.TRNNo": "Value Added Tax Number (UAE)",
  "pages.kycIndividual.requiredDocument.desc5": "• It should be noted that allowed file format for uploading are JPEG and PNG only.",
  "pages.kycIndividual.requiredDocument.desc4": "•  Photo of the ID front and back should be uploaded.",
  "pages.kycIndividual.requiredDocument.desc3": "•  Photo on ID is of original color not black and white or any other color.",
  "pages.kycIndividual.requiredDocument.desc2": "•  Make sure to upload documents that are clearly readable.",
  "pages.kycIndividual.requiredDocument.desc1": "•  The document must be a valid official Government ID.",
  "page.form.kyc.update.secruity.tip": "No withdrawals will be initiated until the update has been approved.",
  "page.form.expiredDate": "Only the days of today and after can be selected.",
  "page.kyc.form.UAE_VISA": "UAE VISA",
  "pages.kyc.form.error_file_format": "Only support the file format of zip, rar, doc, docx, xls, xlsx, ppt, pptx, pdf, png, jpg and jpeg.",
  "page.kyc.form.PEPConfirm": "Are you a Politically Exposed Person (PEP) or have you been a Politically Exposed Person (PEP) in the last 10 years?",
  "page.form.instructions.desc3": "Addressed to Matrix & Date when selfie was taken.",
  "page.form.instructions.desc2": "A person holds a paper and writes his name, Date of Birth.",
  "page.form.instructions.desc1": "Selfie should be in color.",
  "page.form.Instructions": "Instructions",
  "page.form.security.tip1": "Please complete the following verification before updating your account.",
  "page.form.err.EnglishOrNumberOrSpace": "English characters or numbers or space only.",
  "page.form.err.SpecialCharactersForbidden": "Special characters forbidden.",
  "Server.codes.ERROR_KYC_STATUS": "Please do not resubmit.",
  "Server.codes.EXPIRED_NATIONAL_ID": "Expired National ID. ",
  "Server.codes.EXPIRED_PASSPORT": "Expired Passport. ",
  "Server.codes.EXPIRED_DRIVING_LICENSE": "Expired Driving License. ",
  "pages.kyc.AuthorizedPersonType.SeniorManager": "Senior Management",
  "page.kyc.form.ShareholdersEntityDetails": "Shareholders - Company-Details of ownership of 25% or more",
  "page.kyc.form.ShareholdersIndividualDetails": "Shareholders-Individual - Details of ownership of 25% or more",
  "pages.kyc.form.AreYouAPoliticallyExposedPerson": "Are you a Politically Exposed Person (PEP) or have you been a Politically Exposed Person (PEP) in the last 10 years?",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS_label": "Political Party Officials",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS_label": "Government Officials",
  "pages.kyc.server_code.CONNECTION_TIMEOUT": "Upload timeout.",
  "pages.kyc.legalEntityDocumentType.UtilityBillOfThePOA": "Utility bill of the POA",
  "pages.kyc.legalEntityDocumentType.Board_resolution_B": "In case the Authorized Person is different from the POA, a new Board Resolution should be addressed to “Matrix Limited” empowering the Authorized Person to trade and operate the account.",
  "aaa": "aaaaaaaaa",
  "Server.codes.PERMANENT_COUNTRY_IS_NOT_ALLOWED": "Country in Permanent Address is not allowed.",
  "Server.codes.MAIL_COUNTRY_IS_NOT_ALLOWED": "Country in Mailing Address is not allowed.",
  "Server.codes.BANK_COUNTRY_IS_NOT_ALLOWED": "Country in Bank Detail is not allowed.",
  "Server.codes.ACCOUNT_COUNTRY_IS_NOT_ALLOWED": "Country in Bank Account Holder Details is not allowed.",
  "Server.codes.driverValue is repeat": "Driving license number already exists.",
  "Server.codes.IdValue is repeat": "National ID number already exists.",
  "Server.codes.passportValue is repeat": "Passport number already exists.",
  "Server.codes.driverValue or IdValue is repeat": "Driving license number or National ID number already exists.",
  "Server.codes.SYMBOL_TRADE_OFF": "The Symbol does not exist.",
  "Server.codes.AGREEMENT_INVALID_TIME": "Please check your system time.",
  "Server.codes.USER_AGREEMENT_NOT_AGREED": "Please agree to Matrix's Terms of Use.",
  "Server.codes.AGE_AGREEMENT_NOT_AGREED": "Please agree over 21 years old.",
  "menu.dashboard.cancelOrder": "Cancel Order",
  "Server.codes.API_KEY_CAN_NOT_SEE": "Secret Key only displayed once, please keep it in a safe place, if you lose it, you will need to reapply the API.",
  "Server.codes.API_KEY_TRANSFINITE": "API Key exceeds the maximum number of requests. ",
  "test.test": "test",
  "app.containers.RegisterPage.agreeTip": "Please agree to Matrix's Terms of Use.",
  "app.containers.RegisterPage.agree": " I am over 21 years old, and I agree to",
  "app.containers.RegisterPage.userAgreementTip": "Please agree to Matrix's Terms of Use.",
  "app.containers.RegisterPage.userAgreement": " I agree to",
  "Server.codes.RESIDENCY_COUNTRY_IS_NOT_ALLOWED": "Residential country is not allowed.",
  "Server.codes.INCORPORATION_COUNTRY_IS_NOT_ALLOWED": "Country of Incorporation is not allowed.",
  "Server.codes.OPERATIONS_COUNTRY_IS_NOT_ALLOWED": "Country of Operation is not allowed.",
  "test111111": "111",
  "Server.codes.OPERATION_FREQUENT": "Do not send repeated email verification codes in a short period of time.",
  "ga.set.googleLink": "https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en",
  "page.kyc.form.addMoreDocuments": "Add More Documents.",
  "page.crypto.title": "Assets Segment",
  "pages.kyc.form.idCardAndDriverLicense": "ID Card and Driving License",
  "pages.kyc.form.driverLicense": "Driving License",
  "pages.kyc.form.idCard": "ID Card",
  "pages.kycIndividual.CLOSE_ASSOCIATES.title": "Close associates",
  "pages.kycIndividual.CLOSE_ASSOCIATES.desc": "Any individual who has joint beneficial ownership of a company, or a legal arrangement, or close business relationship with a PEP; Any individual who has sole beneficial ownership of a company, or legal arrangement set up for the actual benefit of a PEP.",
  "pages.kycInstitution.form.registeredAddress3": "Registered Address (Country of Incorporation)",
  "pages.kyc.form.countryOfBirth": "Country of Birth",
  "pages.kyc.form.cityOfBirth": "City of Birth",
  "Server.codes.ADDRESS_BIND_FAILED": "The currency does not exist.",
  "pages.asset": "Asset",
  "Server.codes.ACCOUNT_BALANCE_NOT_ENOUGH": "Withdrawal failed, please contact customer service.",
  "fiat.deposit.PendingReview": "Pending Review",
  "fiat.withdraw.SUBMITTED": "Submitted",
  "fiat.withdraw.DENIED": "Denied",
  "fiat.withdraw.WAITING_FOR_APPROVAL": "Waiting for Approval",
  "fiat.withdraw.WAITING_FOR_WALLET": "Waiting for Wallet",
  "fiat.withdraw.CANCELLED": "Cancelled",
  "fiat.withdraw.NEED_CANCELLATION": "Need Cancellation",
  "fiat.withdraw.PENDING": "Pending",
  "fiat.withdraw.FAILED": "Failed",
  "fiat.deposit.SUBMITTED": "Submitted",
  "fiat.deposit.DENIED": "Denied",
  "fiat.deposit.WAITING_FOR_APPROVAL": "Waiting for Approval",
  "fiat.deposit.CANCELLED": "Cancelled",
  "fiat.deposit.NEED_CANCELLATION": "Need Cancellation",
  "fiat.deposit.DEPOSITED": "Deposited",
  "fiat.deposit.PENDING": "Pending",
  "pages.history": "History",
  "pages.status": "Status",
  "pages.withdraw": "Withdraw",
  "pages.deposit": "Deposit",
  "pages.type": "Type",
  "pages.assets": "Assets",
  "pages.RechargePage.tip5": "Please make sure Gas≥34000 when you deposit, otherwise the deposit will not arrive.",
  "page.cryptoAssets.hideZeroDes": "Assets valued less than $1,000 will be hidden.",
  "fiat.message.notice": "You must fill in the above Message number in the bank deposit message.",
  "fiat.history.applicationAmount": "Application Amount",
  "page.HomePage.slogan2": "to Virtual Assets",
  "page.HomePage.slogan1": "Your Trusted Gateway",
  "components.Header.VirtualAssets": "Virtual Assets",
  "fiat.withdraw.SENDING_FOR_PUT_DONE": "In Process",
  "fiat.withdraw.SENDING_FOR_PUT": "In Process",
  "fiat.withdraw.SENDING_FOR_POST_DONE": "In Process",
  "fiat.withdraw.SENDING_FOR_POST": "In Process",
  "fiat.withdraw.SENDING_TRANSFER_DONE": "In Process",
  "fiat.withdraw.SENDING_TRANSFER": "In Process",
  "fiat.withdraw.WAITING_FOR_BANK_CONFIRM": "In Process",
  "fiat.withdraw.APPROVED": "In Process",
  "page.kyc.uploadTip": "Only support uploading single file, please merge multiple files into one. (File size can't exceed 10M)",
  "pages.kycIndividual.CountryCode": "Country Code",
  "pages.kycIndividual.PersonalInformation": "Personal Information",
  "input.placeholder.ValidateNumCode": "6 numbers only.",
  "input.error.numberisNull": "Numbers only.\n",
  "input.error.numberLengthlimit": "6 characters only.",
  "Server.codes.CURRENCY_ERROR": "The currency can not be found.",
  "Server.codes.PARAMS_ERROR": "PARAMS ERROR",
  "pages.kycIndividual.englishfieldError2": "Letters or Numbers only.",
  "test-test": "test - test",
  "pages.kyc.AuthorizedPersonType.AuthorsiedTrader2": "Authorized Person (Trader, if different from POA)",
  "components.Footer.SubmitRequest": "Submit request",
  "components.Footer.CustomerService": "Customer Service: support@Matrix.co",
  "components.Footer.MarketingMedia": "Marketing: media@Matrix.co",
  "components.Footer.Contact": "Contact",
  "components.Footer.Resources": "Resources",
  "components.Footer.PrivacyPolicy": "Privacy Policy",
  "components.Footer.UserAgreement": "User Agreement",
  "components.Footer.AMLCompliancePolicy": "AML & Compliance Policy",
  "components.Footer.RiskStatement": "Risk Statement",
  "components.Footer.LegalAgreementStatement": "Legal Agreement / Statement",
  "components.Footer.FAQ": "FAQ",
  "components.Header.PressRelease": "Press Release",
  "components.Header.ManagementTeam": "Management Team",
  "components.Header.Contact": "Contact Us",
  "components.Header.Security": "Security",
  "components.Header.Fees": "Fees",
  "components.Footer.footerExplain3": "The information on this site is not directed at residents of the United States, or use by any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.",
  "components.Footer.footerExplain2": "Matrix is a company registered at 3519 Al Maqam Tower, Abu Dhabi Global Market Square, Al Maryah Island, Abu Dhabi, UAE, and is authorised and regulated (Registered Number: 000001876) by the Financial Services Regulatory Authority under the laws of Abu Dhabi Global Market.",
  "components.Footer.footerExplain1": "Virtual Assets can be volatile and may not be suitable for everyone. Please ensure you fully understand the risks involved. Matrix does not issue advice, make recommendations or opinion in relation to acquiring, holding or disposing of Virtual Assets. Matrix is not a financial advisor and all services are provided on an execution only basis.",
  "pages.Kyc.DeclarationForEntity3": "The information and documents provided are true,exact and complete,and agrees to notify Matrix immediately of any modification to any of the information provided.",
  "pages.kycInstitution.form.Expiration2": "Driving License Expiry Date",
  "pages.kyc.MultipleOptionsV3.no": "We, confirm that the Company is a US Person.",
  "pages.kyc.MultipleOptionsV3.yes": "We, confirm that the Company is NOT a US Person.",
  "pages.kycInstitution.form.PEP.desc": "Means a Natural Person (and includes, where relevant, a family member or close associate) who is or has been entrusted with a prominent public function, including but not limited to, a head of state or of government, senior officials and functionaries of an international or supranational organization, senior politician, senior government, judicial or military official, ambassador, senior executive of a state owned corporation, or an important political party official, but not middle ranking or more junior individuals in these categories.",
  "page.HomePage.3step3Content": "Buy, sell and transfer leading Virtual Assets.",
  "page.HomePage.3step2Content": "Deposit USD or Virtual Assets.",
  "page.HomePage.3step1Content": "Choose the right account for you and verify your identity.",
  "page.HomePage.topItemTitle4": "Regulated",
  "page.HomePage.topItemContent4": "Matrix is licensed by the Financial Services Regulatory Authority (FSRA) in Abu Dhabi Global Market (ADGM).",
  "page.HomePage.1step": "World Class Trading Platform",
  "pages.kyc.SourceOfWealth.SaleRevenue": "Sales Revenue",
  "pages.kyc.SourceOfFunds2": "Source of Funds (Virtual Assets / Fiat)",
  "pages.Account.idl.approved_account": "Approved",
  "pages.Account.idl.rejected_account": "Rejected",
  "pages.kycIndividual.credentialsDocument2": "National ID Document",
  "pages.kycIndividual.SelfieDocument2": "Instructions",
  "pages.Institution.individualTypeOptions.Other": "Other",
  "pages.kycInstitution.form.CompanyListed2": "Company listed on an exchange? (Authorized trader of the account)",
  "pages.kycInstitution.form.CompanyWebsite2": "Official Website (Authorized trader of the account)",
  "pages.kycInstitution.form.OfficialEmail2": "Official Email ID (Authorized Trader of the Account)",
  "pages.kycInstitution.form.CountryOfOperations2": "Country of Operation (Authorized trader of the account)",
  "pages.kycInstitution.form.principalBusinessAddress2": "Business Address (Authorized trader of the account)",
  "pages.kycInstitution.form.registeredAddress2": "Registered Address (Authorized trader of the account)",
  "pages.Account.kycType.PendingReview": "Pending Review",
  "account_history_CHANGE_FUND_PASSWORD": "Change Fund Password",
  "account_history_BIND_FUND_PASSWORD": "Set Fund Password",
  "account_history_OPEN_FUND_PASSWORD": "Open Fund Password",
  "account_history_CLOSE_FUND_PASSWORD": "Close Fund Password",
  "pages.kycIndividual.POSITIONS": "Positions",
  "pages.kycIndividual.POLITICALLY_EXPOSED_PERSON.desc": "Have you been a Politically Exposed Person for the last 10 years?",
  "pages.kycIndividual.FAMILY_MEMBERS.desc": "An immediate family member of a government or political official, or senior executive – meaning spouses, parents, siblings, children, and spouses’ parents and siblings. ",
  "pages.kycIndividual.FAMILY_MEMBERS.title": "Family Members",
  "pages.kycIndividual.SENIOR_EXECUTIVES.desc": "Individuals serve as senior executive roles, such as directors or board members in government-owned commercial enterprises or international organizations-that is corporations, businesses or other entities formed by or for the benefit of any such individuals. ",
  "pages.kycIndividual.SENIOR_EXECUTIVES.title": "Senior Executives",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS.desc": "Senior officials appointed to roles in major political parties at home or in foreign countries.",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS.title": "Political Party Officials",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS.desc": "Current or former officials appointed to domestic government positions, or positions in a foreign government. This may include heads of state or individuals working in executive, legislative, administrative, military or judicial branches in elected and unelected roles.",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS.title": "Government Officials",
  "pages.kyc.form.legalEntityTypeOptions.OtherPosition": "Position",
  "pages.kyc.form.ManagementDetails.SeniorManagementDetails": "Senior Management Details",
  "pages.kyc.form.ManagementDetails.BoardOfDirectors": "Board of Directors",
  "pages.Kyc.DeclarationForEntity2": "Declaration-We confirm that the bank account details provided are accurate and complete. The provided bank account details of the Company will be used for all Fiat deposits and withdrawals.",
  "pages.Kyc.DeclarationForEntity1": "Declaration-I confirm that I am acting on behalf of this Company as the authorized person and not on behalf of any second party.",
  "pages.kyc.SourceOfWealth.ExternalInvestment": "External Investment",
  "pages.kyc.SourceOfWealth.ParentOrSisterCompany": "Parent/Sister Company",
  "pages.kyc.SourceOfWealth.ShareholderInvestment": "Shareholders' Investment",
  "pages.kyc.SourceOfWealth.SaleOfShares": "Sales of Shares",
  "pages.kyc.SourceOfWealth.CompanyProfit": "Company Profit",
  "pages.kycInstitution.form.IncorporationDate": "Incorporation Date",
  "pages.kycIndividual.AccountDetails": "Bank Account Holder Details",
  "pages.kyc.form.Definition.source_of_funds": "Virtual Assets / Fiat Assets",
  "account_history_CHANGE_LOGIN_PASSWORD": "Change Login Password",
  "label.repeatNewPassword": "Confirm New Password",
  "label.newpassword": "New Password",
  "label.oldpassword": "Old Password",
  "pages.kycIndividual.form.error.source_of_funds": "Please select the source of funds.",
  "pages.kycIndividual.Business Activity": "Business Activity",
  "pages.kycIndividual.SourceOfIncome.CapitalGains": "Capital Gains",
  "pages.kycIndividual.SourceOfIncome.RetirementFunding": "Retirement Funding",
  "pages.kycIndividual.SourceOfIncome.Pensions": "Pensions",
  "pages.kycIndividual.SourceOfIncome.Inheritance": "Inheritance",
  "pages.kycIndividual.SourceOfIncome.Royalty": "Royalty",
  "pages.kycIndividual.SourceOfIncome.Dividends": "Dividends",
  "pages.kycIndividual.SourceOfIncome.Rentals": "Rentals",
  "pages.kycIndividual.SourceOfIncome.Interests": "Interest",
  "pages.kycIndividual.SourceOfIncome.Savings": "Savings",
  "pages.kycIndividual.SourceOfIncome.Business": "Business",
  "pages.kycIndividual.SourceOfIncome.Salary": "Salary",
  "pages.kycIndividual.SourceOfIncome": "Source of Income",
  "pages.kycIndividual.DriverLicenseDocument": "Driving License Document",
  "pages.kycIndividual.NationIDDocument": "National ID Document",
  "pages.kycIndividual.credentialsDocument.Back": "Back Side",
  "pages.kycIndividual.credentialsDocument.Front": "Front Side",
  "pages.kyc.new.form.PassportProvince": "Place of Issue",
  "pages.kycIndividual.AccountHolderResidentialAddress": "Bank Account Holder Residential Address",
  "pages.kycIndividual.bankIBAN": "IBAN",
  "pages.kycIndividual.bankSwiftCode": "Swift Code",
  "pages.kyc.form.bank.zipCode": "Zip Code / Postal Code",
  "fiat.bankDetails": "Bank Details",
  "pages.kyc.SourceOfFundsOptions.Loan": "Loan",
  "pages.kyc.SourceOfFundsOptions.Inheritance": "Inheritance",
  "pages.kyc.SourceOfFundsOptions.Investment": "Investment",
  "pages.kyc.SourceOfFundsOptions.FamilyMembers": "Family Members",
  "pages.kyc.SourceOfFundsOptions.MyOwnBusiness": "My Own Business",
  "pages.kyc.SourceOfFundsOptions.Savings": "Savings",
  "pages.kyc.SourceOfFundsOptions.SalaryAndEmployment": "Salary, Employment",
  "pages.kyc.SourceOfFundsOptions": "Source of Funds",
  "pages.kycIndividual.TRADING": "Trading",
  "pages.kycIndividual.HEDGING": "Hedging",
  "pages.kycIndividual.LIQUIDITY_MANAGEMENT": "Liquidity Management",
  "pages.kycIndividual.type7": "Hedging",
  "pages.kycIndividual.type6": "Liquidity Management",
  "pages.kyc.individual.extra.name": "As Per Passport",
  "pages.kyc.MultipleOptionsV2.no": "I, confirm I am a US Person.",
  "pages.kyc.MultipleOptionsV2.yes": "I, confirm I am NOT a US Person.",
  "pages.kyc.form.Definition.SourceOfWealth": "Origin of the Wealth / Activities which have generated or contributed to an accumulation of wealth.",
  "pages.kyc.form.Definition.registered": "Where the company is registered / incorporated to operate.",
  "app.containers.LoginPage.signUp": "Sign Up",
  "app.containers.LoginPage.CreateAnAccount": "Create an Account",
  "pages.kyc.form.Definition.source_of_wealth": "Origin of the Wealth / Activities which have generated or contributed to an accumulation of wealth.",
  "pages.kyc.form.Definition.estimated_annual_income": "Total amount of income / Money earned during the year.",
  "pages.kyc.form.Definition.estimated_total_net_worth": "The total wealth of a person excluding any debt held.",
  "pages.kyc.form.Definition.pep": "Politically Exposed Person (PEP) by FSRA\nMeans a Natural Person (and includes, where relevant, a family member or close associate) who is or has been entrusted with a prominent public function, including but not limited to, a head of state or of government, senior officials and functionaries of an international or supranational  organization, senior politician, senior government, judicial or military official, ambassador, senior executive of a state-owned corporation or an important political party official, but not middle ranking or more junior individuals in these categories.",
  "pages.kyc.form.Definition.usPerson4": "US corporations, US partnerships, US estates and US trusts.",
  "pages.kyc.form.Definition.usPerson3": "Certain persons who spend a significant number of days in the US each year.",
  "pages.kyc.form.Definition.usPerson2": "A person residing in the US, including US green card holders.",
  "pages.kyc.form.Definition.usPerson1": "A citizen of the US, including an individual born in the US but reside in another country or territory (who has not given up their US citizenship).",
  "pages.kyc.form.Definition.Permanent": "Place of permanent or fixed address.",
  "pages.kyc.form.Definition.Mailing": "Address that you receive your postal mail.",
  "pages.kyc.form.Definition.Residential": "Where you are currently residing or staying.",
  "pages.kyc.form.Definition.taxResidency": "Where you are liable or obliged to pay taxes.",
  "Server.codes.BANK_ACCOUNT_HAS_ALREADY_REGISTERED": "Bank account has already been registered.",
  "pages.kyc.form.notRequired": "{max} characters only.",
  "pages.kycInstitution.form.sameAsResidential": "Same as the residential address",
  "pages.kycInstitution.form.sametoregistere": "Same as registered address",
  "pages.Institution.institutionTypeOptions.Other": "Other Company",
  "input.error.5_15.number": "5 - 50 characters only.",
  "input.error.percent.number": "1-100 whole numbers only.",
  "pages.kycIndividual.changeTips": "Please enable Two - Factor Authentication.",
  "pages.kyc.form.nationalityNames.same.err": "Repeated nationality.",
  "Server.codes.FILE_AMOUNT_ERROR": "Please upload two address certification documents.",
  "page.kyc.individual.ApprovedTitle.Assessed": "An Assessed professional client is :",
  "page.kyc.individual.ApprovedTitle.i": " (B) the individual appears, on reasonable grounds, to have sufficient experience and understanding of relevant financial markets, products or transactions and any associated risks.",
  "page.kyc.individual.ApprovedTitle.h": "either: \n(A) the individual is, or has been, in the previous twelve months, or two years in the case of the Promotion of a Passport Fund, an Employee in a professional position of an Authorised Person, a Recognised Body or Remote Body; or ",
  "page.kyc.individual.ApprovedTitle.g": "(C) any benefits (in the form of pensions or otherwise) which are payable on the termination of that person's service or on death or retirement and to which that person or that person's dependents are, or may be, entitled; either: ",
  "page.kyc.individual.ApprovedTitle.f": "(B) any rights of that person under a qualifying Contract of Insurance within the meaning of FSMR; or ",
  "page.kyc.individual.ApprovedTitle.e": "(A) property which is that person's primary residence or any loan secured on that residence; ",
  "page.kyc.individual.ApprovedTitle.d": "(i) the individual has net assets of at least $500,000 or $1,000,000 in the case of the Promotion of a Passport Fund (including any assets held directly or indirectly by that person), the calculation of which must exclude: ",
  "page.kyc.individual.ApprovedTitle.c": "(a) An individual may be treated as an \"assessed\" Professional Client (instead of a Retail Client) if: ",
  "page.kyc.individual.ApprovedTitle.b": "Individuals ",
  "page.kyc.individual.ApprovedTitle.a": "\"Assessed\" Professional Clients",
  "page.kyc.individual.Approved.Assessed": "An Assessed professional client is :",
  "Server.codes.PASSPORT_NUMBER_REQUIRED_AT_LEAST_FIVE_CHARACTER": "Passport number should be at least 5 numbers.",
  "Server.codes.IDENTIFICATION_NUMBER_REQUIRED_AT_LEAST_FIVE_CHARACTER": "Identification number should be at least 5 numbers.",
  "Server.codes.PASSPORT_HAS_ALREADY_REGISTERED": "Passport number has already been registered.",
  "Server.codes.BUSINESS_IS_NOT_ALLOWED": "KYC verification is not allowed for the business you engage in.",
  "Server.codes.COUNTRY_IS_NOT_ALLOWED": "KYC verification is not allowed in your country of residence.",
  "pages.kyc.form.Management.Add": "Add Management Details",
  "pages.individual.document.Declaration": "I declare that the information and documents provided are true, exact and complete, and I agree to notify Matrix immediately of any modification to any of the information provided.",
  "pages.institution.document.Declaration": "declares that the information and documents provided are true, exact , accurate and complete. We agree to update our profile  with Matrix immediately and as appropriate.",
  "pages.kyc.return": "Back",
  "input.error.num_alphabet": "Numbers or English characters only.",
  "pages.kycIndividual.UAEresident": "UAE resident ID",
  "pages.Account.kycType.INDIVIDUAL": "Individual",
  "pages.kyc.proofAddressTypes.Name_Lives": "Latest confirmation from an Electoral Register Search that a Person of Such a Name Lives at that Address.",
  "pages.kyc.proofAddressTypes.Valid_Tenancy_Agreement": "Valid Tenancy Agreement for current year.",
  "pages.kyc.proofAddressTypes.Bank_account": "Bank account or Post Office savings bank account statement / passbook (not less than 3 months).",
  "pages.kyc.proofAddressTypes.tax_returns": "Local authority tax returns (e.g. council tax) valid for the current year.",
  "pages.kyc.proofAddressTypes.Household_utility": "Household utility bill (e.g. gas, electric, water or fixed line telephone, internet but not a mobile phone bill). The bill must be within the latest three months and show your name and current address.",
  "pages.kyc.form.tip.validateUploadList": "At least two different proof of addresses need to be uploaded.\n",
  "pages.kyc.form.EntityChooseOther": "Other Company Type",
  "pages.kyc.form.legalEntityTypeOptions.Others": "Other",
  "pages.kyc.form.legalEntityTypeOptions.Government_Agency": "Government Agency, Central Bank, National Monetary Authority",
  "pages.kyc.form.legalEntityTypeOptions.Financial_Institution": "Financial Institution",
  "pages.kyc.form.legalEntityTypeOptions.Sole_Proprietorship": "Sole Proprietorship",
  "pages.kyc.form.legalEntityTypeOptions.Limited_Liability_Company": "Limited Liability Company (LLC) ",
  "pages.kyc.form.legalEntityTypeOptions.General_Partnership": "General Partnership",
  "pages.kyc.form.legalEntityTypeOptions.Corporation": "Corporation",
  "pages.kyc.form.EntityType": "Company Type",
  "pages.kycInstitution.form.purposes": "Purpose of Account",
  "pages.kyc.form.nameOfTheRegularlyTradedCorporation": "If you are a Related Company of a regularly traded corporation, please provide the name of the regularly traded corporation. ",
  "pages.kyc.form.nameOfTheEstablishedSecuritiesMarket": "The name of the established securities market on which the corporation is regularly traded.",
  "pages.KycInstitution.Declaration": "I confirm that I am acting on behalf of the company as the authorized person and not on behalf of any second party.",
  "pages.Kyc.Declaration.err": "Please provide your consent in order to proceed.",
  "pages.Kyc.Declaration": "Declaration - I  confirm that the bank details provided are mine which will be used for all Fiat deposits and withdrawals.",
  "pages.kyc.form.postalCode.err": "{max} characters only.",
  "pages.kyc.form.Controlling_Persons_Details.Add": "Add Controlling Person Details  ",
  "app.dev.RecaptchaSitekey": "6Le33OEUAAAAAHHzu460MoCwVCSrHb4CnxGajqXg",
  "app.production.RecaptchaSitekey": "6Le33OEUAAAAAHHzu460MoCwVCSrHb4CnxGajqXg",
  "pages.kycIndividual.requiredDocumentTitle": "ID and Identity Verification",
  "pages.kycIndividual.requiredDocument.Note1": "We need to verify your identity, please choose your valid Government ID type and follow the below instructions:",
  "pages.kycInstitution.requiredDocument.Note1": "We need to verify your institution’s identity before we can approve your account. Please provide the Following Documents.",
  "pages.kyc.SourceOfWealth.Royalty": "Royalty",
  "pages.kyc.SourceOfWealth.Inheritance": "Inheritance",
  "pages.kyc.SourceOfWealth.Parents": "Parents",
  "pages.kyc.SourceOfWealth.Investment": "Investment",
  "pages.kyc.SourceOfWealth.Business": "My Own Business",
  "pages.kyc.SourceOfWealth.Salary": "Salary, Employment",
  "pages.kyc.form.legalEntityType.NFE": "2. Non - Financial Entity (NFE)",
  "pages.kyc.form.legalEntityType.Fl": "1. Financial Institution (FI) - Investment Entity",
  "input.error.number": "Numbers only.",
  "page.kyc.individual.Approved.submit": "Submit",
  "page.kyc.individual.Approved.Disagree": "Disagree",
  "page.kyc.individual.Approved.Agree": "Agree",
  "page.kyc.individual.Approved.chooseTitle": "Agree as a Retail Client / Disagree as a Retail Client.",
  "page.kyc.Institution.Approved.DisagreeContext": "By opting up to Retail Client, you are eligible for the highest protection level oﬀered to Retail Clients.",
  "page.kyc.individual.Approved.DisagreeContext": "By opting up to Professional Client, I as a client will waive off the highest protection level oﬀered to Retail Clients.",
  "Server.codes.CAN_NOT_CHANGE_BANK": "The bank account cannot be changed temporarily.",
  "page.kyc.Institution.Approved.submit": "Submit",
  "page.kyc.Institution.Approved.chooseTitle": "Agree as a Professional Client / Disagree as a Professional Client",
  "page.kyc.Institution.Approved.Disagree": "Disagree",
  "page.kyc.Institution.Approved.Agree": "Agree",
  "page.kyc.Institution.Approved.header": "You are classified as a Professional Client of Matrix. However, you can selectively request to  OPT UP or OPT Down as a retail client. By opting up to Retail Client, you are eligible for highest level of protection for retail client. Please Agree / Disagree with the classification within 4 days from this message. In absence of any response from your end, you will be deemed to be classified as Professional Clients.",
  "account_history_CHANGE_BANK": "Change Bank Account",
  "Server.codes.DOES_NOT_MATCH_THE_REGISTERED_EMAIL": "The email that you filled in on this page is different from which you filled in during registration.",
  "pages.kyc.form.MANAGEMENT.Other": "Other",
  "pages.kyc.form.MANAGEMENT.COO": "COO",
  "pages.kyc.form.MANAGEMENT.CRO": "CRO",
  "pages.kyc.form.MANAGEMENT.CFO": "CFO",
  "pages.kyc.form.MANAGEMENT.CEO": "CEO",
  "pages.kycInstitution.form.BusinessActivity": "Business Activity",
  "pages.kyc.BusinessActivityOptions.54": "Casinos, Online Casinos, Online Gambling, Gaming",
  "pages.kyc.BusinessActivityOptions.53": "Charity, Association, Trust",
  "pages.kyc.BusinessActivityOptions.52": "Vehicle Leasing, Trading, Parts",
  "pages.kyc.BusinessActivityOptions.51": "Scrap Metals, Metal Manufacturing and / or Trading",
  "pages.kyc.BusinessActivityOptions.50": "Politician, Minister, Parliament Member, Political Organization",
  "pages.kyc.BusinessActivityOptions.49": "Oil, Gas, Chemicals, Petrochemicals",
  "pages.kyc.BusinessActivityOptions.48": "Non - Profit Organization (NPO), Non - Governmental Organization (NGO)",
  "pages.kyc.BusinessActivityOptions.47": "Money Service Business (MSB)",
  "pages.kyc.BusinessActivityOptions.46": "Mining",
  "pages.kyc.BusinessActivityOptions.45": "Judge",
  "pages.kyc.BusinessActivityOptions.44": "Jewelry, Precious Metals or Stones, Luxury Goods",
  "pages.kyc.BusinessActivityOptions.43": "International Affairs",
  "pages.kyc.BusinessActivityOptions.42": "Insurance Activity",
  "pages.kyc.BusinessActivityOptions.41": "Governmental Institution, Governmental Body, Army",
  "pages.kyc.BusinessActivityOptions.40": "Forest Cutting, Timber, Paper Business",
  "pages.kyc.BusinessActivityOptions.39": "Event Management",
  "pages.kyc.BusinessActivityOptions.38": "Virtual Assets & Block Chain",
  "pages.kyc.BusinessActivityOptions.37": "Artist, Art Trading, Antique",
  "pages.kyc.BusinessActivityOptions.36": "Alternative Medicine",
  "pages.kyc.BusinessActivityOptions.35": "Travel & Tourism",
  "pages.kyc.BusinessActivityOptions.34": "Retail Store",
  "pages.kyc.BusinessActivityOptions.33": "Real Estate Development and / or Trading ",
  "pages.kyc.BusinessActivityOptions.55": "Military Equipment, Weapon Trading",
  "pages.kyc.BusinessActivityOptions.32": "Import & Export, International Trade, Freight, Post Services, Maritime Services",
  "pages.kyc.BusinessActivityOptions.31": "Gas Station",
  "pages.kyc.BusinessActivityOptions.30": "Fashion Design, Beauty Services",
  "pages.kyc.BusinessActivityOptions.29": "Construction Activities, Construction Material and Equipment",
  "pages.kyc.BusinessActivityOptions.28": "Accounting, Auditing, Consultancy",
  "pages.kyc.BusinessActivityOptions.27": "Textiles Manufacturing and / or Trading",
  "pages.kyc.BusinessActivityOptions.26": "Telecommunication, Networking",
  "pages.kyc.BusinessActivityOptions.25": "Sports",
  "pages.kyc.BusinessActivityOptions.24": "Restaurant, Hotel, Entertainment Business, Leisure Services",
  "pages.kyc.BusinessActivityOptions.23": "Medical, Health Services",
  "pages.kyc.BusinessActivityOptions.22": "Media, Advertising, Publishing",
  "pages.kyc.BusinessActivityOptions.21": "Legal Services, Lawyers, Notary Services",
  "pages.kyc.BusinessActivityOptions.20": "Engineering, Architecture, Interior Design",
  "pages.kyc.BusinessActivityOptions.19": "Computer Hardware or Software",
  "pages.kyc.BusinessActivityOptions.18": "Banking, Financial Institution, Financial Services",
  "pages.kyc.BusinessActivityOptions.17": "Agriculture, Livestock, Food Production, Trading",
  "pages.kyc.BusinessActivityOptions.16": "Veterinary",
  "pages.kyc.BusinessActivityOptions.15": "Transportation Services",
  "pages.kyc.BusinessActivityOptions.14": "Renewable Energy, Environmental Services",
  "pages.kyc.BusinessActivityOptions.13": "Plastic Manufacturing",
  "pages.kyc.BusinessActivityOptions.12": "Pharmaceutical, Medical Equipment",
  "pages.kyc.BusinessActivityOptions.11": "Personal Services (Babysitting, Cleaning, House Maintenance... )",
  "pages.kyc.BusinessActivityOptions.10": "Machinery Manufacturing or Trading",
  "pages.kyc.BusinessActivityOptions.9": "Human Resources, Recruitment, Coaching, Personal Development",
  "pages.kyc.BusinessActivityOptions.8": "Hardware Equipment, Furniture",
  "pages.kyc.BusinessActivityOptions.7": "Graphic Design, Photography, Printing",
  "pages.kyc.BusinessActivityOptions.6": "Electric, Electronic Manufacturing and / or Trading",
  "pages.kyc.BusinessActivityOptions.5": "Data Provider",
  "pages.kyc.BusinessActivityOptions.4": "Airline, Aviation, Aerospace",
  "pages.kyc.BusinessActivityOptions.3": "Vehicle Manufacturers",
  "pages.kyc.BusinessActivityOptions.2": "Teacher, Instructor, Educational Institution",
  "pages.kyc.BusinessActivityOptions.1": "Library, Book Store, Stationary Store",
  "pages.kycInstitution.form.usPerson": "Is the company a US person",
  "pages.kycInstitution.Entityinformation": "Company Information",
  "pages.kyc.form.NameAndDetailsOfTheAlgorithm": "Name and details of the algorithm",
  "pages.kyc.form.NameOfPersonResponsibleForTheAlgorithm": "Name of the person responsible for the algorithm",
  "pages.kycInstitution.form.algorithmTrader": "Are you an algorithm trader?",
  "pages.Institution.institutionTypeOptions.Financial_Institution": "Financial Institution",
  "pages.Institution.institutionTypeOptions.Sole_Proprietorship": "Sole Proprietorship",
  "pages.Institution.institutionTypeOptions.Limited_Liability_Company": "Limited Liability Company",
  "pages.Institution.institutionTypeOptions.General_Partnership": "General Partnership",
  "pages.Institution.institutionTypeOptions.Corporation": "Corporation",
  "pages.Institution.institutionTypeOptions.Asset_Management": "Asset Management",
  "pages.Institution.institutionTypeOptions.Broker": "Broker",
  "pages.kyc.form.DetailsOfAuthorizedTraderInfos": "Details of Authorized Trader of the Account",
  "pages.Institution.typeOfCustomerChoosesOptions.Entity": "Company",
  "pages.Institution.typeOfCustomerChoosesOptions.Individual": "Individual",
  "pages.kyc.form.typeOfCustomerChooses": "Type of Authorized Trader",
  "pages.kycInstitution.form.PEP": "Are you a Politically Exposed Person (PEP)?",
  "pages.kycInstitution.form.marketIdentifierCode": "Market Identifier Code (MIC) ",
  "pages.kycInstitution.form.legalEntityIdentifierNumber": "Company Identifier (LEI)",
  "Server.codes.INVALID_MULTIPLE_NATIONALITY": "Please select different countries. ",
  "Server.codes.USERNAME_NOT_FOUND": "Incorrect email address or password.",
  "Server.codes. USERNAME_NOT_FOUND": "Wrong  Mail",
  "Server.codes.USER_EMAIL_OR_PASSWORD_INVALID.num": "Incorrect Email address or Password: you can try {max} more times.",
  "app.form.ChineseCharacters": "Chinese characters forbidden.",
  "pages.Account.StepThree": "Fund Account",
  "pages.Account.StepTwo": "Secure Account",
  "pages.Account.StepOne": "Verify Account",
  "pages.kyc.legalEntityDocumentType.Financial_statement": "Last 3 years Financial statement – If applicable",
  "pages.kyc.legalEntityDocumentType.Company_bank_statement": "Company bank statement with account number, account name i.e. under name of company (not less than 3 months) which will be used for fund transfer. The document to be in English.",
  "pages.kyc.legalEntityDocumentType.Board_resolution_from_the_company": "Board resolution  from the company to the authorized person who will be trading on and behalf of the company addressed to Matrix. If the POA is same as trader the above would suffice. The resolution should state the limits (Fiat and Virtual Assets) of operating the account. The board resolution to specify the validity (duration to trade the account)The letter to be signed by the board committee.",
  "pages.kyc.legalEntityDocumentType.Official_document": "Official document evidencing the current business / office address. Example: Tax Return or Valid Tenancy Agreement, etc.",
  "pages.kyc.legalEntityDocumentType.Board_resolution": "Board Resolution addressed to “Matrix Limited” authorizing the POA to open and operate the account.",
  "pages.kyc.legalEntityDocumentType.Authorized_signatories": "Authorized signatories list",
  "pages.kyc.legalEntityDocumentType.Details_of_ownership": "Details of ownership and structure (Organization chart)",
  "pages.kyc.legalEntityDocumentType.Memorandum": "Memorandum and Articles of Association",
  "pages.kyc.legalEntityDocumentType.Certificate_of_incorporation": "Certificate of incorporation or Company Registration Document",
  "pages.kyc.form.legalEntityDocumentType": "Company Documentation",
  "pages.kyc.AuthorizedPersonType.AuthorsiedTrader": "Authorized Trader",
  "pages.kyc.AuthorizedPersonType.POA": "POA",
  "pages.kyc.AuthorizedPersonType.Shareholders": "Shareholders",
  "pages.kyc.AuthorizedPersonType.Directors": "Board of Directors",
  "pages.kycInstitution.form.AuthorizedPersonType": "Document Uploads of",
  "pages.kyc.entitySourceOfWealth.Others": "Others",
  "pages.kyc.entitySourceOfWealth.SaleOfShares": "Sale Of Shares",
  "pages.kyc.entitySourceOfWealth.CompanyProfit": "Company Profit",
  "input.error.username": "English alphanumeric characters and underscores only.",
  "input.error.empty.required": "Required field and {max} characters only.",
  "label.lastName": "Last Name",
  "label.firstName": "First Name",
  "label.companyName": "Company Name",
  "pages.kyc.form.onBehalfOfTheLegalEntity": "As a POA of the Trading Account , will you be trading for and on behalf of the company? ",
  "pages.kycInstitution.form.Expiration": "National ID Expiry Date",
  "pages.kycInstitution.form.identification": "Identification",
  "pages.Account.kycType.LEGAL_ENTITY": "Company",
  "pages.kycInstitution.form.CountryOfPrincipalBusiness": "Country",
  "pages.kycInstitution.form.CountryOfRegistered": "Country",
  "pages.kyc.new.form.street": "House / Apartment / Street Number",
  "pages.kyc.new.form.Province": "City / Town / Province",
  "pages.kyc.new.form.PostalCode": "Zip Code / Postal Code",
  "pages.kyc.form.authorizedPersonInfosrmation": "Details of Power of Attorney Holder of the Trading Account",
  "pages.kyc.form.percentageOfOwnership": "Percentage of Ownership",
  "pages.Institution.CompanyManagementDetails.Shareholders_Entity": "Shareholders - Company - Details of ownership of 25% or more",
  "pages.Institution.CompanyManagementDetails.Shareholders_Individual": "Shareholders - Individual - Details of ownership of 25% or more",
  "pages.kyc.form.managementPosition": "Senior Management Position",
  "pages.kycInstitution.form.PersonEmail": "Email ID",
  "pages.kyc.form.AuthorizedPersonInformation": "Power of Attorney holder of the trading account",
  "pages.kyc.form.taxIdentification.Add": "Add Tax Residency Status",
  "pages.kyc.form.taxIdentificationReasons": "Reason",
  "pages.kyc.form.taxIdentificationStatus": "Tax ID Available",
  "pages.kycIndividual.dateOfBirthError": "Must be older than 21 years.",
  "Server.codes.USERNAME_INVALID": "English characters only.",
  "input.register.BUSINESS": "COMPANY",
  "input.register.INDIVIDUAL": "INDIVIDUAL",
  "label.username": "User Name",
  "pages.kycInstitution.form.CompanyWebsite": "Official Website",
  "pages.kycInstitution.form.OfficialEmail": "Official Email ID",
  "pages.kycInstitution.form.CountryOfOperations": "Country of Operation(s)",
  "pages.kycInstitution.form.IncorporationNumber": "Incorporation Number ",
  "pages.kycInstitution.form.CountryOfIncorporation": "Country of Incorporation ",
  "pages.kycInstitution.form.TelephoneNumber": "Telephone Number",
  "pages.kycInstitution.form.TelephoneCode": "Country Code",
  "pages.kycInstitution.form.Tel": "Telephone Number",
  "pages.kyc.form.ManagementDetails": "Management Details",
  "pages.Institution.CompanyManagementDetails.Senior_Management": "Senior Management – CEO , CFO , COO",
  "pages.Institution.CompanyManagementDetails.Board_of_Directors": "Board of Directors",
  "pages.Institution.CompanyManagementDetails.Shareholders": "Shareholders - Details of ownership of  25% or more. Limit of adding 5 records details",
  "pages.kyc.form.CompanyManagementDetails": "Ownership Structure and Management Details",
  "pages.Institution.CONTROLLING_OPTIONS.m": "Controlling Person of a legal arrangement other than a trust-other (equivalent)",
  "pages.Institution.CONTROLLING_OPTIONS.l": "Controlling Person of a legal arrangement other than a trust-beneficiary (equivalent)",
  "pages.Institution.CONTROLLING_OPTIONS.k": "Controlling Person of a legal arrangement other than a trust-protector of the trustee (equivalent)",
  "pages.Institution.CONTROLLING_OPTIONS.j": "Controlling Person of a legal arrangement other than a trust-trustee (equivalent)",
  "pages.Institution.CONTROLLING_OPTIONS.i": "Controlling Person of a legal arrangement other than a trust-settlor (equivalent)",
  "pages.Institution.CONTROLLING_OPTIONS.h": "Controlling Person of a trust–other",
  "pages.Institution.CONTROLLING_OPTIONS.g": "Controlling Person of a trust–beneficiary",
  "pages.Institution.CONTROLLING_OPTIONS.f": "Controlling Person of a trust–protector of the trustee",
  "pages.Institution.CONTROLLING_OPTIONS.e": "Controlling Person of a trust–trustee",
  "pages.Institution.CONTROLLING_OPTIONS.d": "Controlling Person of a trust–settlor",
  "pages.Institution.CONTROLLING_OPTIONS.c": "Controlling Person of a legal person – senior managing official",
  "pages.Institution.CONTROLLING_OPTIONS.b": "Controlling Person of a legal person – control by other means",
  "pages.Institution.CONTROLLING_OPTIONS.a": "Controlling Person of a legal person – control by ownership",
  "pages.kycInstitution.form.Type_of_Controlling_person": "Type of Controlling person ",
  "pages.kyc.form.Controlling_Persons_Details": "Controlling Person Details  ",
  "pages.kycInstitution.form.Legal_Entity_Type_classification": "Company Type Classification - Common Reporting Standard Classification (CRS)",
  "pages.Institution.NFE_E": "e.Passive NFE.",
  "pages.Institution.NFE_D": "d.Active NFE - other than (a), (b) or (c) options (for example a start-up NFE or a non-profit NFE).",
  "pages.Institution.NFE_C": "c.Active NFE – an International Organisation.",
  "pages.Institution.NFE_B": "b.Active NFE – a Government Entity or Central Bank.",
  "pages.Institution.NFE_A": "a.Active NFE - a corporation the stock of which is regularly traded on an established securities market or a corporation which is a related entity of such a corporation.",
  "pages.Institution.FI_B": "b.An Investment Entity located in a Non-Participating Jurisdiction and managed by another Financial Institution.",
  "pages.Institution.FI_A": "a.Financial Institution (Depository Institution, Custodial Institution, Specified Insurance Company or Investment Entity in a CRS Participating Jurisdiction).\n",
  "fiat.change.account": "Change Account?",
  "pages.kyc.form.CountryOfResidence": "Country of Tax Residence",
  "pages.kyc.form.taxIdentificationNumber": "Tax Identification Number",
  "pages.kyc.form.taxResidencyStatus": "Tax Residency Status",
  "pages.kyc.form.EmploymentStatus": "Employment Status",
  "pages.kyc.form.EmployerName": "Employer Name",
  "pages.kyc.EmploymentStatus.OTHER": "Shareholder, Board of Director, Senior Management",
  "pages.kyc.EmploymentStatus.NON_EMPLOYED": "Non-Employed-Student, Retired, Homemaker",
  "pages.kyc.EmploymentStatus.SELF_EMPLOYED": "Self-employed\n",
  "pages.kyc.EmploymentStatus.EMPLOYED": "Employed",
  "pages.kycIndividual.EmploymentStatus": "Employment Status",
  "pages.kycIndividual.governmentStakeHolder": "Government StakeHolder",
  "pages.kycIndividual.FAMILY_MEMBERS_AND_CLOSE_ASSOCIATES": "Family Members and Close Associates: An immediate family member of a government or political official, or senior executive – meaning spouses, parents, siblings, children, and spouses’ parents and siblings. ",
  "pages.kycIndividual.SENIOR_EXECUTIVES": "Senior Executives: Individuals serve in senior executive roles, such as directors or board members, in government-owned commercial enterprises or international organizations - that is corporations, businesses or other entities formed by or for the benefit of any such individuals.",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS": "Senior officials appointed to roles in major political parties at home or in foreign countries.",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS": "Government Officials: Current or former officials appointed to domestic government positions, or positions in a foreign government. This may include heads of state or individuals working in executive, legislative, administrative, military, or judicial branches, in elected and unelected roles.",
  "pages.kycIndividual.USPersonError": "Due to internal policy we cannot onboard you as a customer of Matrix.",
  "pages.kycIndividual.usPerson": "US Person Definition ",
  "pages.kyc.form.PreviousStep": "Back",
  "pages.kyc.form.nextStep": "Next",
  "pages.kycInstitution.form.PassportTitle": " ID Document Type: Passport",
  "pages.kycInstitution.form.PassportExpiration": "Passport Expiry Date",
  "pages.kycIndividual.PassportNumber": "ID Document Number",
  "pages.kycInstitution.requiredDocument.Authorized.context2": "2.Board of Directors , Shareholders Valid passport copy (mandatory), EID (in case of UAE resident), National ID, Driving License  as per the country of residence - a drop down list on the top stating what documentations is accepted) -Any 2 IDS as applicable",
  "pages.kycInstitution.requiredDocument.Authorized.context1": "1.Valid passport copy (mandatory), EID( in case of UAE resident), National ID , Driving License  as per the country of residence - a drop down list on the top stating what documentations is accepted). Any 2 IDS as applicable. ",
  "pages.kycInstitution.requiredDocument.Authorized.title": "Authorized person , Shareholders, Board of Director documentation",
  "pages.kycInstitution.requiredDocument.Note2": "Note: All documents have to be  (Clear, Readable and in English). It should be noted that allowed file formats for uploading are JPEG, PNG and PDF only.\n",
  "pages.kycInstitution.requiredDocument.title": "We need to verify your institution’s identity before we can approve your account. Please Provide the Following Documents.",
  "pages.kycInstitution.form.SourceOfWealth": "Source of Wealth ",
  "pages.kyc.EstimatedAnnualTurnover.above": "$250 million and above",
  "pages.kyc.EstimatedAnnualTurnover.250Mln": "$100 million to less than $250 million",
  "pages.kyc.EstimatedAnnualTurnover.100Mln": "$50 million to less than $100 million",
  "pages.kyc.EstimatedAnnualTurnover.50Mln": "$10 million to less than $50 million",
  "pages.kyc.EstimatedAnnualTurnover.10Mln": "$1 million to less than $10 million",
  "pages.kyc.EstimatedAnnualTurnover.1Mln": "$500,000 to less than $1 million",
  "pages.kyc.EstimatedAnnualTurnover.5000000": "$250,000 to less than $500,000",
  "pages.kyc.EstimatedAnnualTurnover.250000": "$100,000 to less than $250,000",
  "pages.kyc.EstimatedAnnualTurnover.100000": "$0 to less than  $100,000",
  "pages.kyc.FinancialStatement.above": "$500 million and above",
  "pages.kyc.FinancialStatement.500Mln": "$250 million to less than $500 million",
  "pages.kyc.FinancialStatement.250Mln": "$100 million to less than $250 million",
  "pages.kyc.FinancialStatement.100Mln": "$50 million to less than  $100 million",
  "pages.kyc.FinancialStatement.50Mln": "$10 million to less than $50 million",
  "pages.kyc.FinancialStatement.10Mln": "$1 million to less than $10 million",
  "pages.kyc.FinancialStatement.1Mln": "$500,000 to less than $1 million",
  "pages.kyc.FinancialStatement.500000": "$250,000 to less than $500,000",
  "pages.kyc.FinancialStatement.250000": "$0 to less than  $250,000",
  "pages.kycInstitution.EstimatedAnnualTurnover": "Estimated Annual Turnover (USD)",
  "pages.kycInstitution.FinancialStatement": "Total Assets (USD) based on last Financial Statement",
  "pages.kycInstitution.form.TickerCode": "Ticker Code",
  "pages.kycInstitution.form.WhichExchange": "Exchange Name",
  "pages.kycInstitution.form.No": "No",
  "pages.kycInstitution.form.Yes": "Yes",
  "pages.kyc.form.CompanyListedRequired": "Required",
  "pages.kycInstitution.form.CompanyListed": "Company listed on an exchange ?",
  "ga.change.step3": "Change Two-Factor Authorization",
  "pages.kycIndividual.ResidentialAddress": "Residential Address",
  "pages.kycIndividual.CountryOfResidential": "Country",
  "pages.kycIndividual.CountryOfMailing": "Country",
  "pages.kycIndividual.MailingAddress": "Mailing Address",
  "pages.kycIndividual.CountryOfPermanent": "Country of Permanent",
  "pages.account.changeGASuccess": "Success.",
  "pages.kyc.totalNetWorthOption.above": "$1 million and above ",
  "pages.kyc.totalNetWorthOption.1min": "$250,000 to less than $1 million",
  "pages.kyc.totalNetWorthOption.250000": "$100,000 to less than $250,000",
  "pages.kyc.totalNetWorthOption.100000": "$50,000 to less than $100,000",
  "pages.kyc.totalNetWorthOption.50000": "$20,000 to less than $50,000",
  "pages.kyc.totalNetWorthOption.20000": "$0 to less than  $20,000",
  "pages.kyc.annualIncomeOption.above": "$500,000 and above",
  "pages.kyc.annualIncomeOption.500000": "$250,000  to less than $500,000",
  "pages.kyc.annualIncomeOption.250000": "$100,000 to less than $250,000",
  "pages.kyc.annualIncomeOption.100000": "$50,000 to less than $100,000",
  "pages.kyc.annualIncomeOption.50000": "$20,000 to less than $50,000",
  "pages.kyc.annualIncomeOption.20000": "$0 to less than $20,000",
  "pages.kyc.SourceIncomeOption.CapitalGains": "Capital Gains",
  "pages.kyc.SourceIncomeOption.Retirementfunding": "Retirement Funding",
  "pages.kyc.SourceIncomeOption.Pensions": "Pensions",
  "pages.kyc.SourceIncomeOption.Inheritance": "Inheritance",
  "pages.kyc.SourceIncomeOption.Royalty": "Royalty",
  "pages.kyc.SourceIncomeOption.Dividends": "Dividends",
  "pages.kyc.SourceIncomeOption.Rentals": "Rentals ",
  "pages.kyc.SourceIncomeOption.Interests": "Interests",
  "pages.kyc.SourceIncomeOption.Savings": "Savings",
  "pages.kyc.SourceIncomeOption.Business": "Business",
  "pages.kyc.SourceIncomeOption.Salary": "Salary",
  "pages.kycIndividual.AccountStatus": "Account Status",
  "pages.kycIndividual.CityOfBirth": "City",
  "pages.kycIndividual.CountryOfBirth": "Country of Birth",
  "fiat.page.Interceptor.message1": "You must verify KYC to proceed.",
  "pages.kycIndividual.Gender": "Gender",
  "pages.kyc.MultipleOptions.Female": "Female",
  "pages.kyc.MultipleOptions.Male": "Male",
  "pages.kycIndividual.multipleNationality": "Dual/Multiple Nationality ",
  "pages.kyc.MultipleOptions.no": "No",
  "pages.kyc.MultipleOptions.yes": "Yes",
  "pages.kycIndividual.middleName": "Middle Name",
  "fiat.history.more": "Show",
  "label.vcode": "Email Verification Code",
  "Server.codes.WITHDRAW_AMOUNT_TOO_SMALL": "The withdrawal amount is too small.",
  "Server.codes.INSUFFICIENT_AVAILABLE_BALANCE": "Insufficient balance. Please enter a smaller withdrawal amount.",
  "fiat.history.comment": "User Comment",
  "fiat.know": "I Know",
  "fiatWithdraw.successDetail": "Your request has been successfully submitted,  please check the progress in USD account history.",
  "fiatWithdraw.success": "Success",
  "Server.codes.SIGNATURE_ERROR": "Account is abnormal, please contact customer service.",
  "Server.codes.FUND_PASSWORD_NOT_NULL": "Please set the fund password first.",
  "account_history_AGREE_EXEMPTION": "Agree exemption",
  "pages.kycIndividual.englishfieldError": "English characters only.",
  "iText.noData": "No Data",
  "fiat.searchForm.withdraw.option": "Withdraw",
  "fiat.searchForm.deposit.option": "Deposit",
  "fiatWithdraw.description.text1": "International withdrawals will be charged with {feeRate}% fee, minimum fee is {minimumFee} {currency} on Matrix's end and may incur additional international bank fees. Minimum amount for international withdrawal is {minimumAmount} {currency}.",
  "fiatWithdraw.description.text6": "Please enter a bank account which does not require FFC (For Further Credit) instructions. FFC payments will not be processed.",
  "fiatWithdraw.description.text5": "Third party withdrawals are defined as those withdrawals which are meant to be transferred to account or financial institution that is titled in a name that is different from the registered Matrix account.",
  "fiatWithdraw.description.text4": "THIRD PARTY WITHDRAWALS ARE NOT ALLOWED!",
  "fiatWithdraw.description.text3": "Third party withdrawal requests will be rejected.",
  "fiatWithdraw.description.text2": "Withdrawals are processed only from main account.",
  "fiatWithdraw.WithdrawFiatFunds": "Withdraw Fiat Funds",
  "label.fiat.comment": "Comments",
  "label.fiat.fundPassword": "Fund Password",
  "fiat.withdraw.warning": "Please verify your account information below, If your deposit information is not the same as your KYC provided, the deposit will be refunded.",
  "fiat.search.label.status": "Status",
  "fiat.search.label.type": "Type",
  "fiat.history.method": "Method",
  "fiat.history.message": "Message",
  "page.fiatAssets.history": "History",
  "fiat.page.link.title": "Fiat",
  "components.Header.menuFiat": "Fiat",
  "fiat.withdraw.FAILURE": "Failure",
  "fiat.withdraw.FREEZE": "Freeze",
  "fiat.withdraw.FINISHED": "Completed",
  "fiat.withdraw.PROCESSING": "Pending Review",
  "fiat.deposit.CONFIRMING": "Pending Review",
  "fiat.deposit.FINISHED": "Completed",
  "fiat.deposit.FREEZE": "Freeze",
  "fiat.deposit.FAILURE": "Failure",
  "fiat.deposit.WAITING_FOR_REFUND": "Waiting for Refund",
  "fiat.deposit.WAITING_FOR_RISK": "Waiting for Risk",
  "fiat.deposit.WAITING_FOR_COMPLIANCE": "Waiting for Compliance",
  "fiat.deposit.WAITING_FOR_OPERATION": "Waiting for Operation",
  "fiat.deposit.all": "All",
  "fiat.history.bankAccountName": "Bank Account Name",
  "fiat.history.status": "Status",
  "fiat.history.amount": "Amount",
  "fiat.history.type": "Type",
  "fiat.history.currency": "Currency",
  "fiat.history.date": "Date",
  "fiat.history": "History",
  "fiat.page.title": "Fiat",
  "fiat.available": "Maximum Withdrawal Amount",
  "fiatDeposit.title2": "TRANSFER THE FUNDS TO Matrix ACCOUNT",
  "fiat.warning": "Reminder",
  "fiat.agree": "I Agree",
  "fiat.understand": "I understand",
  "fiat.important": "Important",
  "fiat.disClaimer": "Disclaimer",
  "fiat.accountNumberUSD": "Account Number (USD)",
  "fiat.address": "Address",
  "fiat.accountOwner": "Account Owner",
  "fiat.city": "City",
  "fiat.bankAccountNumber": "Bank Account Number",
  "fiat.country": "Country",
  "fiat.message": "Message",
  "fiat.bankAddress": "Bank Address",
  "fiat.bankName": "Bank Name",
  "fiat.accountName": "Account Name",
  "fiatDeposit.title": "SEND FUNDS TO MATRIX",
  "fiatDeposit.description": "We use single bank to process USD deposits currently. Make sure you carefully check the bank account details on our deposit page every time you send funds from your bank.The bank details may change! International bank transfer deposits carry 0 deposit fee on our side and take 3-5 business days to credit. USD deposits credited to your USD balance won’t be converted.All other currencies will be converted to the selected balance currency, free of charge. Third party deposits are prohibited. Please contact our support before making a transaction. What is a third party deposit? Third party deposits are defined as those deposits which originate from or are drawn upon an account at financial institution which is titled in a name other than that of the receiving Matrix account holder(s). If you have any questions please contact our support staff.",
  "fiatDeposit.agreement4": "If you make a deposit from an account not registered with Matrix or from a third party account (account not held on your name), the deposit request will be rejected by Matrix. Such rejected deposit will be returned back to the account from which transaction was originated. Please note this may incur additional charges by receiving or intermediary bank.",
  "fiatDeposit.agreement3": "Do not deposit from a corporate bank account to your personal Matrix account, and vice versa.",
  "fiatDeposit.agreement2": "If you make a deposit from an account not registered with Matrix or from a third party account (account not held on your name), the deposit request will be rejected by Matrix. Such rejected deposit will be returned back to the account from which transaction was originated. Please note this may incur additional charges by receiving or intermediary bank.",
  "fiatDeposit.agreement1": "Make sure you complete the KYC verification on our page before sending funds from your bank.",
  "pages.kyc.form.bank.BankAccountrequired": "Required field and {max} characters only.",
  "pages.kyc.form.bank.BankNamerequired": "Required field and {max} characters only.",
  "pages.kyc.form.bank.BankAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.bank.accountNameRequired": "Required field and {max} characters only.",
  "pages.kycIndividual.bankStatement": "Bank Statement – last 3 months",
  "pages.kycIndividual.BankAccount": "Bank Account",
  "pages.kycIndividual.BankName": "Bank Name",
  "pages.kycIndividual.BankAddress": "Bank Address",
  "pages.kycIndividual.BankCountry": "Bank Country",
  "pages.kycIndividual.AccountName": "Bank Account Holder Name",
  "pages.kyc.tip.individualPanel.bankinfo": "Bank Information",
  "Server.codes.CURRENT_DESCRIPTION_IS_TOO_LONG": "50 characters at most.",
  "pages.kycIndividual.SelfieDocument": "Selfie",
  "pages.kycIndividual.PassportDocument": "Passport Document",
  "pages.kycIndividual.PassportCountry": "ID Document Issuing Country",
  "Server.codes.UNAUTHORIZED_IP": "Registration Coming Soon!",
  "pages.kycIndividual.credentialsSelfieDocumentButtonTitle": "Selfie",
  "test": "test1",
  "Server.codes.TRIGGERON_SCALE_ERROR": "The decimal place limit exceeded.",
  "Server.codes.AMOUNT_SCALE_ERROR": "The decimal place limit exceeded.",
  "Server.codes.PRICE_SCALE_ERROR": "The decimal place limit exceeded.",
  "Server.codes.TRIGGERON_NOT_LESS_THAN_PRICE": "It cannot be less than the limit price.",
  "Server.codes.TRIGGERON_NOT_GREATER_THAN_PRICE": "It cannot be greater than the limit price.",
  "Server.codes.CAN_NOT_REGISTER_WITH_THIS_EMAIL": "Please change another email for registration.",
  "pages.kyc.form.credentialsTypeRequired": "Required",
  "pages.kycIndividual.credentialsPassportDocumentButtonTitle": "Passport",
  "account_history_CREATE_ANTI_PHISHING": "Set Anti-Phishing code",
  "Server.codes.TRIGGERON_NEED_GREATER_ZERO": "The trigger price must be greater than zero.",
  "Server.codes.PRICE_NEED_GREATER_ZERO": "Price must be greater than zero.",
  "account_history_EDIT_ANTI_PHISHING": "Edit Anti-Phishing",
  "account_history_ORDER_NOTIFICATION_ON": "Order Notification ON",
  "account_history_WITHDRAW_NOTIFICATION_ON": "Withdraw Notification ON",
  "account_history_DEPOSIT_NOTIFICATION_ON": "Deposit Notification ON",
  "account_history_ORDER_CONFIRM_ON": "Two Steps Trading ON",
  "account_history_ORDER_CONFIRM_OFF": "Two Steps Trading OFF",
  "account_history_DEPOSIT_NOTIFICATION_OFF": "Deposit Notification OFF",
  "account_history_WITHDRAW_NOTIFICATION_OFF": "Withdraw Notification OFF",
  "account_history_ORDER_NOTIFICATION_OFF": "Order Notification OFF",
  "Server.codes.JUMIO_SWITCH_STATUS_ON": "jumio authentication upgrading",
  "Server.codes.JUMIO_INDENTITY_TIMES_OUT": "Running out of the allowed number of attempts to verify your KYC. Please try again 24 hours later.",
  "Server.codes.CONNECTION_TIMEOUT": "Connection timed out.",
  "pages.kyc.errors.credentials_has_already_registered": "The certificate number has been registered.",
  "pages.kyc.form.credentialsNumberMin": "5 characters at least.",
  "pages.kyc.form.credentialsNumberMax": "50 characters at most.",
  "Server.codes.PASSWORD_ERROR_UPPER_LIMIT": "Your operation is too frequent, please try again in 5 minutes.",
  "Server.codes.CREDENTIALS_REQUIRED_AT_LEAST_FIVE_CHAR": "5 characters at least.",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_FUND_PASSWORD_CHANGE": "For security purposes, no withdrawals are permitted within 24 hours after resetting the fund password.",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_AUTH_CODE_CHANGE": "For security purposes, no withdrawals are permitted within 24 hours after resetting the Two-Factor Authentication.",
  "app.home.tutorial.SignUpSrc": "https://cdn.matrix.co/videos/register_en.mp4",
  "app.home.tutorial.WithdrawSrc": "https://cdn.matrix.co/videos/withdraw_en.mp4",
  "app.home.tutorial.DepositSrc": "https://cdn.matrix.co/videos/Deposit_en.mp4",
  "app.home.tutorial.Withdraw": "Withdraw",
  "app.home.tutorial.Deposit": "Deposit",
  "app.home.tutorial.DepositWithdraw": "Deposit / Withdraw",
  "app.home.tutorial.SignUp": "Sign Up",
  "app.home.tutorial.title": "Tutorial",
  "pages.Account.switchTitle.OFF": "OFF",
  "pages.Account.switchTitle.ON": "ON",
  "pages.Account.orderNotification.description": "Receive email notification through every order lifecycle.",
  "pages.Account.withdrawNotification.description": "Receive email notification for every withdraw.",
  "pages.Account.depositNotification.description": "Receive email notification for every deposit.",
  "pages.Account.orderNotification": "Order Notification",
  "pages.Account.withdrawNotification": "Withdraw Notification",
  "pages.Account.depositNotification": "Deposit Notification",
  "pages.Account.EmailNotification": "Email Notification",
  "Server.codes.NO_SATISFIED_MATRIX_ACCOUNT": "No corresponding bank account information was found.",
  "Server.codes.MUST_BE_VIRTUAL_CURRENCY": "It must be Virtual Assets.",
  "Server.codes.WITHDRAW_REQUEST_NOT_FOUND": "The withdrawal request is not found.",
  "Server.codes.WITHDRAW_ADDRESS_NOT_FOUND": "The withdrawal address is not found.",
  "Server.codes.CURRENCY_NOT_FIND": "The currency is not found.",
  "Server.codes.AMOUNT_CAN_NOT_LESS_ZERO": "The amount must be greater than zero.",
  "Server.codes.CONFIRMS_NEED_GREATER_ZERO": "The confirmation must be greater than zero.",
  "Server.codes.REPORT_MONTH_IS_NOT_NULL": "The month is required in the report.",
  "Server.codes.NOT_FOUND_USER_ADDRESS_OF_THE_CURRENCY": "The deposit address is not found through User Id and Currency.",
  "Server.codes.USER_LEVEL_IS_WRONG": "Incorrect user level.",
  "Server.codes.DEPOSIT_RECORD_NOT_EXIST": "The deposit record doesn't exist.",
  "Server.codes.MATCH_DETAIL_MISSING_SYMBOL": "The pair is missing in the detail.",
  "Server.codes.AUDIT_COUNT_NOT_CONFIGURED": "The audit account is not configured.",
  "Server.codes.THIS_MANAGER_AUDITED": "It has been audited by this manager.",
  "Server.codes.ERROR_STATUS": "Error Status.",
  "Server.codes.BENEFICIARY_ACCOUNT_NOT_EXISTED": "Beneficiary account doesn't exist..",
  "Server.codes.ADDRESS_NOT_EXIST": "Address not existed.",
  "Server.codes.BENEFICIARY_ACCOUNT_NOT_MATCH": "Beneficiary account is not matched.",
  "Server.codes.PASSWORD_CANNOT_SAME_AS_RECENTLY": "New password cannot be the same as your old password.",
  "Server.codes.KYC_REGISTRATIONDATE_ERROR": "Incorrect registration date.",
  "Server.codes.KYC_BIRTHDATE_ERROR": "Incorrect birthdate format.",
  "Server.codes.KYC_STATUS_TRANSFER_NOT_ALLOWED": "The transfer is not allowed for your current KYC status.",
  "Server.codes.KYC_TYPE_INVALID": "KYC type is invalid.",
  "Server.codes.KYC_FOUNDATION_NOT_SET": "The KYC foundation is not set.",
  "Server.codes.KYC_DOC_STORAGE_ERROR": "KYC document storage error.",
  "Server.codes.OPERATION_NOT_ALLOWED_FOR_CURRENT_KYC_STATUS": "To proceed this operation, you need to complete the corresponding KYC level certification.",
  "Server.codes.OLD_AUTH_CODE_WRONG": "Incorrect old verification code.",
  "Server.codes.NEW_AUTH_CODE_WRONG": "Incorrect new verification code.",
  "Server.codes.AUTH_CODE_BIND_REQUEST_EXPIRED": "Your verification binding has expired, please try it again.",
  "Server.codes.AUTH_CODE_AUTH_EXPIRED": "Expired GA Code.",
  "Server.codes.AUTH_CODE_CODE_WRONG": "GA Code Error.",
  "Server.codes.AUTH_CODE_NOT_BOUND": "Verification not bound yet.",
  "Server.codes.AUTH_CODE_ALREADY_BOUND": "Verification already bound.",
  "Server.codes.AUTH_CODE_NOT_OPEN": "Verication not enabled yet.",
  "Server.codes.AUTH_CODE_NOT_NULL": "Verification code required.",
  "Server.codes.AUTH_CODE_INVALID": "Incorrect verification code.",
  "Server.codes.FUND_PASSWORD_STATUS_SAME": "Please do not set the fund password with the same status.",
  "Server.codes.FUND_PASSWORD_INVALID": "Fund Password Invalid.",
  "Server.codes.FUND_PASSWORD_MISSING": "Fund Password Lost.",
  "Server.codes.FUND_PASSWORD_WRONG": "Wrong Fund Password.",
  "Server.codes.FUND_PASSWORD_NOT_SET": "Fund Password Not Set.",
  "Server.codes.SYMBOL_TRADE_TIME_OVER": "The trading for this pair has already closed.",
  "Server.codes.SYMBOL_TRADE_NOT_START": "The trading for this pair has not started.",
  "Server.codes.SYMBOL_NOT_FIND": "This pair is not found.",
  "Server.codes.SYMBOL_CAN_NOT_NULL": "The pair is required.",
  "Server.codes.MARKET_ORDER_CANNOT_CANCEL": "The market is not able to be canceled.",
  "Server.codes.TABLE_NAME_INVALID": "Incorrect table name.",
  "Server.codes.CANCELORDER_CANNOT_CREATE": "Please do not cancel the order again.",
  "Server.codes.AMOUNT_NEED_GREATER_ZERO": "Amount must be greater than zero.",
  "Server.codes.ORDER_TYPE_UNSUPPORTED": "The order type is not supported.",
  "Server.codes.IP_INVALID_OR_NUMBER_TOO_MAX": " IP Invalid or IP length exceeded.",
  "Server.codes.TRIGGERON_IS_DISABLED": "Trigger on is disabled.",
  "Server.codes.MINING_IS_DISABLED": "Mining is disabled.",
  "Server.codes.SOURCE_INVALID": "Source is invalid.",
  "Server.codes.TRAILINGSTOP_IS_DISABLED": "Trailing stop is disabled.",
  "Server.codes.HIDDEN_IS_DISABLED": "Hidden is disabled.",
  "Server.codes.POSTONLY_IS_DISABLED": "Post only is disabled.",
  "Server.codes.IMMEDIATEORCANCEL_IS_DISABLED": "Immediate or Cancel is disabled.",
  "Server.codes.FILLORKILL_IS_DISABLED": "Fill or Kill is disabled.",
  "Server.codes.IP_LENGTH_ERROR": "IP length exceeded.",
  "Server.codes.TYPE_CAN_NOT_NULL": "Type required.",
  "Server.codes.IP_CAN_NOT_NULL": "IP address required.",
  "Server.codes.STATUS_CAN_NOT_NULL": "Status required.",
  "Server.codes.PASSWORD_ERROR": "Incorrect password.",
  "Server.codes.USER_NOT_EXIST": "User does not exist.",
  "Server.codes.USERID_CAN_NOT_NULL": "User ID required.",
  "Server.codes.UPLOAD_FREQUENT": "The file uploading exceeds the limit, please upload again 24 hours later.",
  "Server.codes.TOTAL_FILE_SIZE_IS_TOO_LARGE": "The total size of files exceeds limit when uploading.",
  "pages.kyc.server_code.SINGLE_FILE_SIZE_IS_TOO_LARGE": "Do not put any upload size limit .",
  "Server.codes.UNSUPPORTED_FILE_TYPE": "Please upload the correct file type.",
  "Server.codes.UNSUPPORTED_VALUE": "Unsupported Value.",
  "Server.codes.UNSUPPORTED_TYPE": "Unsupported Type.",
  "Server.codes.ORIGIN_ANTI_PHISHING_CODE_INVALID": "Incorrect old Anti-Phishing code.",
  "Server.codes.ANTI_PHISHING_CODE_INVALID": "Incorrect Anti-Phishing code.",
  "Server.codes.ANTI_PHISHING_CODE_MISSING": "Anti-Phishing code missing.",
  "Server.codes.API_SERVICE_RESTRICT": "Your API service is restricted temporarily.",
  "Server.codes.CREDENTIALS_HAS_ALREADY_REGISTERED": "Driver license number has already registered.",
  "Server.codes.BEYOND_DAILY_LIMIT": "This account has exceeded the limit, please confirm!",
  "Server.codes.BEYOND_MONTH_LIMIT": "This account has exceeded the limit, please confirm!",
  "Server.codes.CURRENCY_CAN_NOT_NULL": "Currency is required.",
  "Server.codes.MISSING_VCODE": "Vcode missing.",
  "Server.codes.DB_OPERATION_FAILED": "DB_OPERATION_FAILED.",
  "Server.codes.VCODE_WRONG": "Please enter the correct email verification code.",
  "Server.codes.VCODE_EXPIRED": "The email verification code you entered has expired, please get it again.",
  "Server.codes.CAN_NOT_ADD_INTERNAL_ADDRESS": "Not able to add internal address.",
  "Server.codes.CAN_NOT_SIGN_IN": "Kindly contact the Matrix Support Team. Thank you!",
  "Server.codes.RECORD_NOT_FOUND": "This record is not found.",
  "Server.codes.REFERRER_ID_INVALID": "Referrer ID does not exist.",
  "Server.codes.EMAIL_INVALID": "Invalid email address.",
  "Server.codes.RULE_NOT_FOUND": "RULE_NOT_FOUND",
  "Server.codes.VCODE_SENDING_RATE_LIMITED": "Your operation is too frequent, please try again in 5 minutes.",
  "Server.codes.MAN_MACHINE_CHECK_ERROR": "The man machine check failed.",
  "Server.codes.TRANSACTION_REFERENCE_NOT_EXISTED": "The transaction reference does not exist.",
  "Server.codes.AUTHORITY_LIMITED": "Authority limited.",
  "Server.codes.FIND_BACK_PASSWORD_EXPIRED_CODE": "Verification code expired.",
  "Server.codes.FIND_BACK_PASSWORD_WRONG_CODE": "Verification failed, please re-acquire new verification code and enter correctly.",
  "Server.codes.FIND_BACK_PASSWORD_NOT_EXIST": "Verification code does not exist.",
  "Server.codes.FIND_BACK_PASSWORD_NOT_EXPIRED": "The password you find back has expired.",
  "Server.codes.MISSING_PARAMETER": "MISSING_PARAMETER",
  "Server.codes.MISSING_REGISTRATION_REQUEST": "MISSING_REGISTRATION_REQUEST",
  "Server.codes.REGISTRATION_ACTIVATION_INVALID": "Email verification code error or expired.",
  "Server.codes.INTERNAL_SERVER_ERROR": "Internal server error.",
  "Server.codes.SYSTEM_MAINTAIN": "System Maintenance.",
  "Server.codes.REQUEST_BODY_TOO_LARGE": "Request Failed.",
  "Server.codes.OPERATION_FAILED": "Operation Failed.",
  "Server.codes.OPERATION_CANNOT_RETRY": "Please do not retry it.",
  "Server.codes.DECRYPT_FAILED": "Decrypt Failed.",
  "Server.codes.ENCRYPT_FAILED": "Encrypt Failed.",
  "Server.codes.WITHDRAW_INVALID_STATUS": "Kindly contact the Matrix Support Team. Thank You!",
  "Server.codes.WITHDRAW_DISABLED": "Kindly contact the Matrix Support Team. Thank You!",
  "Server.codes.ADDRESS_MAXIMUM": "It can not be submitted because the maximum number of currency withdrawal addresses have been reached.",
  "Server.codes.ADDRESS_NOT_ALLOWED": "The address is not found.",
  "Server.codes.ADDRESS_CHECK_FAILED": "Address Check Failed.",
  "Server.codes.ADDRESS_DUPLICATED": "Withdrawal Address already exists.\n",
  "Server.codes.ADDRESS_INVALID": "Address Check Failed.",
  "Server.codes.RETRY_LATER": "Please try it again later.",
  "Server.codes.DEPOSIT_CANCEL": "Cancel Deposit.",
  "Server.codes.DEPOSIT_FAILED": "Deposit Failed",
  "Server.codes.ORDER_CANNOT_CANCEL_WITH_STATUS_FULLY_CANCELLED": "Don‘t click frequently.",
  "Server.codes.ORDER_CANNOT_CANCEL_WITH_STATUS_PARTIAL_CANCELLED": "",
  "Server.codes.ORDER_CANNOT_CANCEL": "Fail to cancel the order.",
  "Server.codes.ORDER_NOT_FOUND": "This order was not found.",
  "Server.codes.USER_EMAIL_OR_PASSWORD_INVALID": "Incorrect Email / Username or Password. ",
  "Server.codes.USER_EMAIL_EXIST": "An account already exists with this email address.",
  "Server.codes.USER_CANNOT_DEPOSIT": "You are not able to deposit fund temporarily.",
  "Server.codes.CURRENT_ADDRESS_CANNOT_WITHDRAW": "It cannot be submitted because the withdrawal address entered already exists in the trading platform.",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_LOGIN_PWD_CHANGE": "No withdrawal is allowed within 24 hours after resetting password.",
  "Server.codes.USER_CANNOT_WITHDRAW": "Kindly contact the Matrix Support Team. Thank You!",
  "Server.codes.USER_CANNOT_TRADE": "Kindly contact the Matrix Support Team. Thank You!",
  "Server.codes.USER_CANNOT_SIGNIN": "You are temporarily unable to log in.",
  "Server.codes.USER_NOT_FOUND": "The account has not registered yet.",
  "Server.codes.ACCOUNT_UNLOCK_FAILED": "Your account has been unlocked.",
  "Server.codes.ACCOUNT_LOCK_FAILED": "Your account has been locked.",
  "Server.codes.ACCOUNT_UNFREEZE_FAILED": "Fail to unfreeze your account .",
  "Server.codes.ACCOUNT_FREEZE_FAILED": "Funds not sufficient . Please review that you are taking fees into account.",
  "Server.codes.AUTH_SIGNIN_FAILED": "The account has not registered yet.",
  "Server.codes.AUTH_SIGNIN_REQUIRED": "AUTH_SIGNIN_REQUIRED",
  "Server.codes.AUTH_USER_NOT_ACTIVE": "Your account has not been activated.",
  "Server.codes.AUTH_USER_FORBIDDEN": "You are temporarily not authorized to access this account.",
  "Server.codes.OLD_PASSWORD_ERROR": "Incorrect old password. ",
  "Server.codes.AUTH_SIGNATURE_INVALID": "Your signature is invalid.",
  "Server.codes.AUTH_IP_FORBIDDEN": "Your IP has been blocked due to suspicious operation.",
  "Server.codes.AUTH_APIKEY_DISABLED": "Your API key has been disabled, please contact our Customer Support team.",
  "Server.codes.AUTH_APIKEY_INVALID": "Your API key is invalid, please check whether you have copied it correctly.",
  "Server.codes.AUTH_AUTHORIZATION_EXPIRED": "Your login is invalid. Please try again.",
  "Server.codes.AUTH_AUTHORIZATION_INVALID": "Invalid Authorization.",
  "Server.codes.HEADER_INVALID": "Invalid Header.",
  "Server.codes.PARAMETER_INVALID": "Parameter amount must be equal or less than 10000000.",
  "ga.change.page.waring.message": "For security purposes, no withdrawals are permitted within 24 hours after resetting the Two-Factor Authorization code.",
  "page.resetPwd.FoundPwd": "Find login password",
  "ga.change.step.description2": "Please enter the 6-number code and email verification code.",
  "ga.change.step.description1": "After installation, please open Two-Factor Authenticator and scan the QR code below or enter the key to get a 6-number code.",
  "ga.global.prev": "Previous step",
  "ga.global.next": "Next step",
  "ga.change.page.title": "Change Two-Factor Authorization",
  "ga.qrSection.notice": "Please keep your private key secure to avoid login problem in case you change or lose your phone.",
  "ga.set.androidLink": "https://android.myapp.com/myapp/detail.htm?apkName=com.google.android.apps.authenticator2",
  "ga.set.googlePlayLink": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en",
  "ga.set.appStoreLink": " https://apps.apple.com/us/app/google-authenticator/id388497605",
  "ga.set.step.description3": "Enable your Two-Factor Authenticator",
  "ga.set.step.description2": "Open Two-Factor Authenticator and scan the QR code below or enter the key to add a token.",
  "ga.set.step.description1": "Download and install the Google Authenticator app",
  "ga.label.stepName": "Step",
  "ga.set.page.title": "Enable Two-Factor Authorization",
  "ga.set.step3": "Enable Two-Factor Authorization",
  "ga.set.step2": "Scan QR Code",
  "ga.set.step1": "Download App",
  "page.homepage.ipaUrl": "https://finance.yahoo.com/news/matrix-exchange-receives-principle-approval-033000707.html",
  "page.homepage.ipaText": "Licensed by the Financial Services Regulatory Authority (FSRA) in Abu Dhabi Global Market.",
  "page.HomePage.3step3": "Trade",
  "page.HomePage.3step2": "Fund your account ",
  "page.HomePage.3step1": "Register",
  "page.HomePage.3step": "3 Steps to Trade Virtual Assets",
  "page.HomePage.partners": "Partners",
  "page.HomePage.servicesItemContent3": "Best-in-class cold storage system and risk controls.",
  "page.HomePage.servicesItemContent2": "Open an account and settle trades with USD.",
  "page.HomePage.servicesItemContent1": "Support trading of top Virtual Assets paired with USD.",
  "page.HomePage.servicesItemTitle3": "Secure Custody",
  "page.HomePage.servicesItemTitle2": "Fiat On-Boarding and Settlement",
  "page.HomePage.servicesItemTitle1": "Robust Trading",
  "page.HomePage.servicesTitle": "Our Services",
  "pages.Account.orderConfirmFlag.server.success": "Success",
  "pages.Account.orderConfirmFlag.server.failure": "Operation failed",
  "pages.trade.orderConfirmWin.marketConfirm": "Are you sure to spend {total} {baseCurrency} to {type} {currency} at market price?",
  "page.Withdraw.unReceive": "Didn't Receive Email?",
  "page.Withdraw.more": "Still Not Received",
  "page.Withdraw.helplink": "https://support.matrix.co/hc/en-us/articles/360025533474",
  "page.Withdraw.unReceiveDes": "If you have not received an email relating to withdrawal, please first check your email client's spam or junk mail folder.",
  "page.deposit.unReceive": "Didn't Deposit Arrive?",
  "page.deposit.more": "More Reference",
  "page.deposit.helplink": "https://support.matrix.co/hc/en-us/articles/360039971374",
  "pages.trade.orderConfirmWin.securityCenter": "Trading Tips",
  "pages.trade.orderConfirmWin.popTip": "To show this pop up again, please open {link}",
  "pages.trade.orderConfirmWin.noAlertTip": "Not show again",
  "pages.trade.orderConfirmWin.confirm": "Confirm to {type} {amount} {currency} ?",
  "page.deposit.unReceiveDes": "In some cases, depositing assets can take a few hours. If your deposit has not arrived after a short time, it may be due to a congested blockchain or incomplete transaction. Read more",
  "pages.trade.orderConfirmWin.confirmBtn": "Confirm",
  "pages.trade.orderConfirmWin.cancelBtn": "Cancel",
  "pages.trade.orderConfirmWin.tradeMethod.sell": "sell",
  "pages.trade.orderConfirmWin.tradeMethod.buy": "buy",
  "pages.Account.orderConfirmFlagDesc": "Pop up for double check when placing every order.",
  "pages.Account.orderConfirmFlagTitle": "Two Steps of Trading",
  "pages.Account.orderConfirmFlag.switchTitle.OFF": "OFF",
  "pages.Account.orderConfirmFlag.switchTitle.ON": "ON",
  "pages.OrderBook.tip.need_kyc_verify": "Please verify {link} first",
  "pages.api.errors.INVALID_IP_OR_NUMBER_TOO_MAX": "Invalid IP or the number of IPS is greater than the maximum.",
  "pages.api.canCreateTip": "Remaining API key to add: {remain}",
  "page.withdrawAddress.balance": "Remaining address to add: {balance}",
  "pages.kyc.form.tip.proofAddressSelectFile": "Only support uploading single file, please merge multiple files into one. (File size can't exceed 10M)",
  "app.containers.RegisterPage.registerDisabledMessage": "Registration Coming Soon!",
  "pages.api.unauthBeforeSettingTip.ga": "For your account safety, please enable {GALink} before setting up the API.",
  "pages.api.unauthBeforeSettingTip.kyc": "For your account safety, please complete {KYCLink} before setting up the API.",
  "pages.api.unauthBeforeSettingTip.all": "For your account safety, please complete {KYCLink} and enable {GALink} before setting up the API.",
  "cc.label.ZW": "Zimbabwe",
  "cc.label.ZM": "Zambia",
  "cc.label.YE": "Yemen",
  "cc.label.EH": "Western Sahara",
  "cc.label.WF": "Wallis and Futuna",
  "cc.label.VN": "Vietnam",
  "cc.label.VE": "Venezuela",
  "cc.label.VA": "Vatican",
  "cc.label.VU": "Vanuatu",
  "cc.label.UZ": "Uzbekistan",
  "cc.label.UY": "Uruguay",
  "cc.label.US": "United States",
  "cc.label.GB": "United Kingdom",
  "cc.label.AE": "United Arab Emirates",
  "cc.label.UA": "Ukraine",
  "cc.label.UG": "Uganda",
  "cc.label.VI": "U.S. Virgin Islands",
  "cc.label.TV": "Tuvalu",
  "cc.label.TC": "Turks and Caicos Islands",
  "cc.label.TM": "Turkmenistan",
  "cc.label.TR": "Turkey",
  "cc.label.TN": "Tunisia",
  "cc.label.TT": "Trinidad and Tobago",
  "cc.label.TO": "Tonga",
  "cc.label.TK": "Tokelau",
  "cc.label.TG": "Togo",
  "cc.label.TH": "Thailand",
  "cc.label.TZ": "Tanzania",
  "cc.label.TJ": "Tajikistan",
  "cc.label.TW": "Taiwan",
  "cc.label.SY": "Syria",
  "cc.label.CH": "Switzerland",
  "cc.label.SE": "Sweden",
  "cc.label.SZ": "Swaziland",
  "cc.label.SJ": "Svalbard and Jan Mayen",
  "cc.label.SR": "Suriname",
  "cc.label.SD": "Sudan",
  "cc.label.LK": "Sri Lanka",
  "cc.label.ES": "Spain",
  "cc.label.SS": "South Sudan",
  "cc.label.KR": "South Korea",
  "cc.label.ZA": "South Africa",
  "cc.label.SO": "Somalia",
  "cc.label.SB": "Solomon Islands",
  "cc.label.SI": "Slovenia",
  "cc.label.SK": "Slovakia",
  "cc.label.SX": "Sint Maarten",
  "cc.label.SG": "Singapore",
  "cc.label.SL": "Sierra Leone",
  "cc.label.SC": "Seychelles",
  "cc.label.RS": "Serbia",
  "cc.label.SN": "Senegal",
  "cc.label.SA": "Saudi Arabia",
  "cc.label.ST": "Sao Tome and Principe",
  "cc.label.SM": "San Marino",
  "cc.label.WS": "Samoa",
  "cc.label.VC": "Saint Vincent and the Grenadines",
  "cc.label.PM": "Saint Pierre and Miquelon",
  "cc.label.MF": "Saint Martin",
  "cc.label.LC": "Saint Lucia",
  "cc.label.KN": "Saint Kitts and Nevis",
  "cc.label.SH": "Saint Helena",
  "cc.label.BL": "Saint Barthelemy",
  "cc.label.RW": "Rwanda",
  "cc.label.RU": "Russia",
  "cc.label.RO": "Romania",
  "cc.label.RE": "Reunion",
  "cc.label.CG": "Republic of the Congo",
  "cc.label.QA": "Qatar",
  "cc.label.PR": "Puerto Rico",
  "cc.label.PT": "Portugal",
  "cc.label.PL": "Poland",
  "cc.label.PN": "Pitcairn",
  "cc.label.PH": "Philippines",
  "cc.label.PE": "Peru",
  "cc.label.PY": "Paraguay",
  "cc.label.PG": "Papua New Guinea",
  "cc.label.PA": "Panama",
  "cc.label.PS": "Palestine",
  "cc.label.PW": "Palau",
  "cc.label.PK": "Pakistan",
  "cc.label.OM": "Oman",
  "cc.label.NO": "Norway",
  "cc.label.MP": "Northern Mariana Islands",
  "cc.label.KP": "North Korea",
  "cc.label.NU": "Niue",
  "cc.label.NG": "Nigeria",
  "cc.label.NE": "Niger",
  "cc.label.NI": "Nicaragua",
  "cc.label.NZ": "New Zealand",
  "cc.label.NC": "New Caledonia",
  "cc.label.AN": "Netherlands Antilles",
  "cc.label.NL": "Netherlands",
  "cc.label.NP": "Nepal",
  "cc.label.NR": "Nauru",
  "cc.label.NA": "Namibia",
  "cc.label.MM": "Myanmar",
  "cc.label.MZ": "Mozambique",
  "cc.label.MA": "Morocco",
  "cc.label.MS": "Montserrat",
  "cc.label.ME": "Montenegro",
  "cc.label.MN": "Mongolia",
  "cc.label.MC": "Monaco",
  "cc.label.MD": "Moldova",
  "cc.label.FM": "Micronesia",
  "cc.label.MX": "Mexico",
  "cc.label.YT": "Mayotte",
  "cc.label.MU": "Mauritius",
  "cc.label.MR": "Mauritania",
  "cc.label.MH": "Marshall Islands",
  "cc.label.MT": "Malta",
  "cc.label.ML": "Mali",
  "cc.label.MV": "Maldives",
  "cc.label.MY": "Malaysia",
  "cc.label.MW": "Malawi",
  "cc.label.MG": "Madagascar",
  "cc.label.MK": "Macedonia",
  "cc.label.MO": "Macau",
  "cc.label.LU": "Luxembourg",
  "cc.label.LT": "Lithuania",
  "cc.label.LI": "Liechtenstein",
  "cc.label.LY": "Libya",
  "cc.label.LR": "Liberia",
  "cc.label.LS": "Lesotho",
  "cc.label.LB": "Lebanon",
  "cc.label.LV": "Latvia",
  "cc.label.LA": "Laos",
  "cc.label.KG": "Kyrgyzstan",
  "cc.label.KW": "Kuwait",
  "cc.label.XK": "Kosovo",
  "cc.label.KI": "Kiribati",
  "cc.label.KE": "Kenya",
  "cc.label.KZ": "Kazakhstan",
  "cc.label.JO": "Jordan",
  "cc.label.JE": "Jersey",
  "cc.label.JP": "Japan",
  "cc.label.JM": "Jamaica",
  "cc.label.CI": "Ivory Coast",
  "cc.label.IT": "Italy",
  "cc.label.IL": "Israel",
  "cc.label.IM": "Isle of Man",
  "cc.label.IE": "Ireland",
  "cc.label.IQ": "Iraq",
  "cc.label.IR": "Iran",
  "cc.label.ID": "Indonesia",
  "cc.label.IN": "India",
  "cc.label.IS": "Iceland",
  "cc.label.HU": "Hungary",
  "cc.label.HK": "Hong Kong",
  "cc.label.HN": "Honduras",
  "cc.label.HT": "Haiti",
  "cc.label.GY": "Guyana",
  "cc.label.GW": "Guinea-Bissau",
  "cc.label.GN": "Guinea",
  "cc.label.GG": "Guernsey",
  "cc.label.GT": "Guatemala",
  "cc.label.GU": "Guam",
  "cc.label.GD": "Grenada",
  "cc.label.GL": "Greenland",
  "cc.label.GR": "Greece",
  "cc.label.GI": "Gibraltar",
  "cc.label.GH": "Ghana",
  "cc.label.DE": "Germany",
  "cc.label.GE": "Georgia",
  "cc.label.GM": "Gambia",
  "cc.label.GA": "Gabon",
  "cc.label.PF": "French Polynesia",
  "cc.label.FR": "France",
  "cc.label.FI": "Finland",
  "cc.label.FJ": "Fiji",
  "cc.label.FO": "Faroe Islands",
  "cc.label.FK": "Falkland Islands",
  "cc.label.ET": "Ethiopia",
  "cc.label.EE": "Estonia",
  "cc.label.ER": "Eritrea",
  "cc.label.GQ": "Equatorial Guinea",
  "cc.label.SV": "El Salvador",
  "cc.label.EG": "Egypt",
  "cc.label.EC": "Ecuador",
  "cc.label.TL": "East Timor",
  "cc.label.DO": "Dominican Republic",
  "cc.label.DM": "Dominica",
  "cc.label.DJ": "Djibouti",
  "cc.label.DK": "Denmark",
  "cc.label.CD": "Democratic Republic of the Congo",
  "cc.label.CZ": "Czech Republic",
  "cc.label.CY": "Cyprus",
  "cc.label.CW": "Curacao",
  "cc.label.CU": "Cuba",
  "cc.label.HR": "Croatia",
  "cc.label.CR": "Costa Rica",
  "cc.label.CK": "Cook Islands",
  "cc.label.KM": "Comoros",
  "cc.label.CO": "Colombia",
  "cc.label.CC": "Cocos Islands",
  "cc.label.CX": "Christmas Island",
  "cc.label.CN": "China",
  "cc.label.CL": "Chile",
  "cc.label.TD": "Chad",
  "cc.label.CF": "Central African Republic",
  "cc.label.KY": "Cayman Islands",
  "cc.label.CV": "Cape Verde",
  "cc.label.CA": "Canada",
  "cc.label.CM": "Cameroon",
  "cc.label.KH": "Cambodia",
  "cc.label.BI": "Burundi",
  "cc.label.BF": "Burkina Faso",
  "cc.label.BG": "Bulgaria",
  "cc.label.BN": "Brunei",
  "cc.label.VG": "British Virgin Islands",
  "cc.label.IO": "British Indian Ocean Territory",
  "cc.label.BR": "Brazil",
  "cc.label.BW": "Botswana",
  "cc.label.BA": "Bosnia and Herzegovina",
  "cc.label.BO": "Bolivia",
  "cc.label.BT": "Bhutan",
  "cc.label.BM": "Bermuda",
  "cc.label.BJ": "Benin",
  "cc.label.BZ": "Belize",
  "cc.label.BE": "Belgium",
  "cc.label.BY": "Belarus",
  "cc.label.BB": "Barbados",
  "cc.label.BD": "Bangladesh",
  "cc.label.BH": "Bahrain",
  "cc.label.BS": "Bahamas",
  "cc.label.AZ": "Azerbaijan",
  "cc.label.AT": "Austria",
  "cc.label.AU": "Australia",
  "cc.label.AW": "Aruba",
  "cc.label.AM": "Armenia",
  "cc.label.AR": "Argentina",
  "cc.label.AG": "Antigua and Barbuda",
  "cc.label.AQ": "Antarctica",
  "cc.label.AI": "Anguilla",
  "cc.label.AO": "Angola",
  "cc.label.AD": "Andorra",
  "cc.label.AS": "American Samoa",
  "cc.label.DZ": "Algeria",
  "cc.label.AL": "Albania",
  "cc.label.AF": "Afghanistan",
  "account_history_RESET_OR_CHANGE_PASSWORD": "Reset Password",
  "page.HomePage.slogan": "Your Trusted Gateway to Virtual Assets",
  "account.operate.GA_INVALID": "Two-Factor Authorization code error.",
  "page.fundPassword.openButton": "Enable",
  "page.fundPassword.closeButton": "Disable",
  "page.FundPassword.close": "Close Fund Password",
  "pages.kyc.codes.JUMIO_SWITCH_STATUS_ON": "KYC upgrading...",
  "pages.api.errorMsg.repeatedIP": "Please don't add repeated IP.",
  "pages.monthlyStatments.status.get_report_fail": "Account is abnormal, please contact customer service.",
  "page.deposit.Refunded": "Refunded",
  "page.deposit.WaitingForRefund": "Waiting for Refund",
  "page.deposit.Done": "Done",
  "page.deposit.Failure": "Failure",
  "auth.token.tip.refreshed_success": "Token Refreshed Success.",
  "pages.Account.kycType.null": "Unverified",
  "bindGAFailure.GA_BIND_REQUEST_EXPIRED": "Two-Factor Authentication code error.",
  "public.code.error.GA_CODE_WRONG": "Two-Factor Authentication code error.",
  "page.FundPassword.requireRule": "6-20 numbers or lowercase letters only.",
  "antiFishing.requireRule": "6-20 numbers or English characters only.",
  "antiFishing.set.error": "Operation failure",
  "antiFishing.update.error": "Change failed.",
  "antiFishing.update.success": "Success.",
  "antiFishing.set.success": "Success",
  "antiFishing.canNotSame": "Don't set the same Anti-Phishing code.",
  "antiFishing.input.placeholder.new": "Please enter your Anti-Phishing code",
  "antiFishing.input.label.new": "New Anti-Phishing code",
  "antiFishing.input.placeholder.old": "Please enter the set Anti-Phishing code",
  "antiFishing.input.label.old": "Old Anti-Phishing code",
  "antiFishing.input.placeholder": "Please enter Anti-Phishing code",
  "antiFishing.input.label": "Set Anti-Phishing code",
  "antiFishing.title.update": "Update Anti-Phishing code",
  "antiFishing.title.set": "Set Anti-Phishing code",
  "account.antiFishing.label.title": "The email sent to you by Matrix will contain the Anti-Phishing code to distinct from fake mails.",
  "page.FundPassword.antiFishing": "Anti-Phishing Code",
  "label.can.not.beEmpty": "Can not be empty",
  "account.operate.error": "Operation Failed",
  "account.operate.successfully": "Success.",
  "pages.kyc.errors.no_pane_error": "no error",
  "pages.kyc.tip.collapsed": "collapse",
  "pages.kyc.tip.expanded": "expand",
  "pages.kyc.tip.uploaderFormatTip": "Only support 1 file with {fileFormatTotal} format less than {fileMByteTotal}M.",
  "pages.Account.idl.enhanced_kyc.description4.prefix": "Withdrawal amount: ",
  "pages.Account.idl.enhanced_kyc.description3.prefix": "Deposit amount:",
  "pages.kyc.tip.counterpartyPanel.base": "Base information",
  "pages.kyc.tip.individualEnhancedPanel.additionalInformationPane": "Other information",
  "pages.kyc.EstimatedDailyTransactionsOptions.not_greater_than_10": "≤10 USD",
  "pages.kyc.EstimatedDailyTransactionsOptions.10_and_100": "10-100 USD",
  "pages.kyc.EstimatedDailyTransactionsOptions.100_and_500": "100-500 USD",
  "pages.kyc.EstimatedDailyTransactionsOptions.not_less_than_500": "≥ 500 USD",
  "pages.api.unauthBindToKYC": "KYC",
  "request.errors.status.504": "The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server specified by the URI or some other auxiliary server it needed to access in attempting to complete the request.",
  "request.errors.status.503": "The server is currently unable to handle the request due to a temporary overloading or maintenance of the server. ",
  "request.errors.status.502": "The server, while acting as a gateway or proxy, received an invalid response from the upstream server it accessed in attempting to fulfill the request.",
  "request.errors.status.500": "The server encountered an unexpected condition which prevented it from fulfilling the request.",
  "request.errors.status.422": "The server understands the content type of the request company , and the syntax of the request company is correct but was unable to process the contained instructions.",
  "request.errors.status.410": "The requested resource is no longer available at the server and no forwarding address is known. ",
  "request.errors.status.406": "The resource identified by the request is only capable of generating response entities which have content characteristics not acceptable according to the accept headers sent in the request.",
  "request.errors.status.404": "The server has not found anything matching the Request-URI. No indication is given of whether the condition is temporary or permanent. ",
  "request.errors.status.403": "The server understood the request, but was refusing to fulfill it. ",
  "request.errors.status.401": "The request requires user authentication.",
  "request.errors.status.400": "The request could not be understood by the server due to malformed syntax. ",
  "request.errors.status.204": "The server has fulfilled the request but does not need to return an entity-body, and might want to return updated metainformation. ",
  "request.errors.status.202": "The request has been accepted for processing, but the processing has not been completed.",
  "request.errors.status.201": "The request has been fulfilled and resulted in a new resource being created. ",
  "request.errors.status.200": "The request has succeeded. ",
  "request.errors.message.network_error": "Network error",
  "components.Header.menuAnnouncement": "Announcement",
  "pages.kyc.errors.jumio_try_times_limit": "Each person is limited to 3 verification per day. If exceeded, the verification will be banned, so do not verify at will.",
  "pages.kycIndividual.credentialsDocument": "Identification Document",
  "pages.kycIndividual.idCardNumber": " ID Document Number",
  "account.kyc.Verification": "Verify Your Account",
  "pages.kyc.careers.OTHER": "Other",
  "pages.kyc.careers.CIVIL_SERVANT_GOVERNMENT_STAFF": "Civil Servant / Government Staff",
  "pages.kyc.careers.SERVICE_INDUSTRY": "Service Industry",
  "pages.kyc.careers.CONSULTING_LEGAL_EDUCATION_RESEARCH_TRANSLATION": "Consulting / Legal / Education / Research / Translation",
  "pages.kyc.careers.PERSONNEL_ADMINISTRATION_SENIOR_MANAGEMENT": "Personnel / Administration / Senior Management",
  "pages.kyc.careers.CONSTRUCTION_REAL_ESTATE": "Construction / Real Estate",
  "pages.kyc.careers.ADVERTISING_MARKETING_MEDIA_ART": "Advertising / Marketing / Media / Art",
  "pages.kyc.careers.BIOLOGICAL_PHARMACEUTICAL_MEDICAL_NURSING": "Biological / Pharmaceutical / Medical / Nursing",
  "pages.kyc.careers.PRODUCTION_OPERATIONS_PURCHASING_LOGISTICS": "Production / Operations / Purchasing / Logistics",
  "pages.kyc.careers.FINANCE_INSURANCE_BANKING": "Finance / Insurance / Banking",
  "pages.kyc.careers.SALES_CUSTOMER_SERVICE_AFTER_SALES_SUPPORT_TECHNICAL_SUPPORT": "Sales / Customer Service / After Sales Support / Technical Support",
  "pages.kyc.careers.ACCOUNTING_CASHIER_FINANCIAL_RELATED": "Accounting / Cashier / Finance Related",
  "pages.kyc.careers.COMPUTER_INTERNET_COMMUNICATION_ELECTRONICS": "Computer / Internet / Communication / Electronics",
  "page.MonthlyStatements.none_month_list": "No Asset Report",
  "pages.kyc.form.error_image_file_format": "Only support image format of {format}.",
  "account_history_CHANGE_API": "Change API",
  "account_history_DELETE_API": "Delete API",
  "account_history_CREATE_API": "Create API",
  "account_history_CHANGE_GA": "Change GA",
  "account_history_BIND_GA": "Enable GA",
  "account_history_BIND_OR_CHANGE_FUND_PASSWORD": "Fund password modified",
  "account_history_CHANGE_STATUS_FUND_PASSWORD": "Fund password modified",
  "account_history_LOGIN": "Login",
  "components.Header.menuExchange": "Tradeview",
  "ga.set.notice": "Please keep your private key secure to avoid login problem in case you change or lose your phone.",
  "ga.set.final.step": "Please enter the 6-number code and email code to complete the settings.",
  "page.GA.Set.stepTitle.extra": "After installation,open Google Authenticator and scan the QR code below or enter the key to get a 6-number code.",
  "google.play.store": "Google Play Store",
  "app.store": "APP Store",
  "ga.copySuccess": "Copy Successfully.",
  "ga.copyKey": "Copy Key",
  "page.resetPwd.modifyPwd": "Change login password",
  "page.account.title": "Account",
  "page.deposit.verify": "Verify",
  "pages.kyc.form.credentialsNumberRequired": "Required",
  "pages.kyc.label.how_long_crypto_investment_experience": "How long have you been investing in Virtual Assets?",
  "pages.kycIndividual.career": "Occupation",
  "page.deposit. Done": "Done",
  "page.deposit. Refunded": "Refunded",
  "page.deposit. WaitingForRefund": "Awaiting Refund",
  "page.deposit. Failure": "Failed",
  "page.deposit.PendingReview": "Pending Review",
  "pages.kyc.tip.individualEnhancedPanel.account_activity": "Account Activities",
  "pages.kyc.tip.individualEnhancedPanel.company_information_and_business_activity": "Company information and business activity",
  "pages.kyc.errors.wellUploadFileTip": "(A single file is required with format limited to zip, rar, doc, docx, xls, xlsx, ppt, pptx, pdf, png, jpg, jpeg and the size should be less than 5M) ",
  "pages.kyc.tip.institutionPanel.base": "Basic Information",
  "pages.kyc.label.estimate_daily_crypto_withdrawal_volume": "Expected monthly withdrawal amount of Virtual Assets",
  "pages.kyc.label.estimate_daily_crypto_withdrawal_frequence": "Expected monthly withdrawal frequency of Virtual Assets",
  "pages.kyc.label.estimate_daily_crypto_deposit_volume": "Expected monthly deposit amount of Virtual Assets",
  "pages.kyc.label.estimate_daily_crypto_deposit_frequence": "Expected monthly deposit frequency of Virtual Assets",
  "pages.kyc.label.estimate_daily_fiat_withdrawal_volume": "Expected monthly withdrawal amount of Fiat currency",
  "pages.kyc.label.estimate_daily_fiat_withdrawal_frequence": "Expected monthly withdrawal frequency of Fiat currency",
  "pages.kyc.label.estimate_daily_fiat_deposit_volume": "Expected monthly deposit amount of Fiat currency",
  "pages.kyc.label.estimate_daily_fiat_deposit_frequence": "Expected monthly deposit frequency of Fiat currency",
  "pages.RechargePage.WAITING_FOR_REFUND": "Awaiting refund",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.6": "News items from a reputable source and other similar evidence",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.5": "Audited accounts and financial statements",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.4": "Probate documents",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.3": "Bank or brokerage account statements",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.2": "Publicly-available registration of ownership.",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.1": "Share certificates",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.5": "Proof of all Transactions which gave rise to the payments into the account",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.4": "Loan documentation",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.3": "Salary / bonus certificates",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.2": "Bank Statements",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.1": "The proof of dividend payments connected to a shareholding",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.DRIVER_LICENSE": "Driving License (Both Sides)",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.PASSPORT": "Passport (Double Pages)",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.ID_CARD": "ID Card (Both Sides)",
  "pages.kyc.tip.individualEnhancedPanel.additional_information": "Other",
  "pages.kyc.tip.individualEnhancedPanel.beneficial_owner_verification_documents": "Beneficial Owner Verification Documents",
  "IPAddress": "IP Address",
  "CountryOrLocation": "Country / Region",
  "Source": "Source",
  "Activity": "Activity",
  "account.certification.first": "Please first complete your identity verification",
  "pages.kyc.label.error_unit": "Item errors.",
  "account.more.certification": "Complete Identity Verification",
  "pages.kyc.form.error_uploading_files": "Uploading…",
  "pages.Account.idl.normal_certification.header": "LV1",
  "pages.kycIndividual.driverLicenseNumber": " ID Document Number",
  "pages.kycIndividual.driverLicense": "Driving License",
  "pages.kycIndividual.idCard": "National ID",
  "pages.kycIndividual.credentialsType": "ID Document Type",
  "account.modal.ga.first": "Please first enable Two-Factor Authentication.",
  "account.email.label.title": "Enabled for login, withdrawals, security settings and API management.",
  "account.LoginPassword.label.title": "Enabled for login.",
  "account.ga.label.title": "Enabled for login, withdrawal, security setting and API management.",
  "account.FundPassword.label.title": "Enabled for withdrawal.",
  "account.validateTime.each": "Every time of trading",
  "account.validateTime.everyday": "Every day of trading",
  "account.validateTime.twoHours": "Every two hours of trading",
  "account.validateTime.placeholder": "Please select the time slot of authentications.",
  "url.footer.menuSupportCenter": "https://support.matrix.co/hc/en-us",
  "url.footer.menuPLegalPrivacy": "https://support.matrix.co/hc/en-us/articles/360036544773-Legal-Statement",
  "url.footer.menuFeeSchedule": "https://support.martix.co/hc/en-us/articles/360025624973-Fee-Details",
  "url.footer.menuContactUS": "https://support.matrix.co/hc/en-us/articles/360036041894-Contact-information",
  "url.footer.menuOurCompany": "https://support.martix.co/hc/en-us/articles/360036544473-Company-Profile",
  "url.footer.menuReferal": "https://support.martix.co/hc/en-us/articles/360036039794-Invite-friends-to-return-commission",
  "url.footer.menuAnnouncement": "https://support.martix.co/hc/en-us/sections/360004684874-Latest-News",
  "iText.hash": "Tx Hash",
  "iText.view": "View",
  "page.deposit.refundAddress": "Refund Details",
  "page.deposit.comments": "Failed Reason",
  "page.deposit.addressTip": "Address",
  "pages.kyc.tip.individualPanel.beneficial": "Beneficial Owner",
  "pages.kyc.tip.individualPanel.estimate": "Asset and Investment Information",
  "pages.kyc.tip.individualPanel.base": "Personal Information",
  "pages.kycIndividual.credentialsDocumentUploading": "Uploading",
  "pages.kycIndividual.credentialsBackDocumentButtonTitle": "Back",
  "pages.kycIndividual.credentialsFrontDocumentButtonTitle": "Front",
  "pages.kycIndividual.credentialsDocumentUploaded": "Uploaded",
  "link.edit": "Edit",
  "page.withdraw.actualAmount": "Actual Receiving",
  "page.withdraw.limitTip2": "You can enjoy the following withdrawal limit for your current KYC certification. You may increase the limit upon completion of enhanced KYC certification.",
  "page.withdraw.limitTip1": "You have passed max level of KYC certification and you can enjoy the highest withdrawal limit for (Individual / Institution / Counterparty).",
  "page.withdraw.limitTitle": "Withdrawal Limit Rules",
  "pages.assets.authTip": "Please complete the following verification before deposit or withdrawal.",
  "page.deposit.goVerify": "Verify Now",
  "page.deposit.limitTip2": "You can enjoy the following deposit limit for your current KYC certification. You may increase the limit upon completion of enhanced KYC certification.",
  "page.deposit.limitTip1": "You have passed max level of KYC certification and you can enjoy the highest deposit limit for (Individual / Institution / Counterparty)",
  "page.deposit.limitHelp": "Affected by the financial regulation of Abu Dhabi, all registered users will be required to meet the limits set by the regulatory policy for their withdrawals. Applications beyond the limits will be rejected.",
  "page.deposit.limitTitle": "Deposit Limit Rules",
  "iText.currency": "Currency",
  "page.deposit.tip4": "Deposit less than minimum deposit amount will not be credited or refunded.",
  "page.deposit.tip3": "The minimum deposit amount is",
  "iText.qrCode": "QR Code",
  "iText.copy": "Copy",
  "pages.TradeHistoryPage.empty": "You have no trade history",
  "pages.OpenOrderPage.dateRange": "Date",
  "pages.OpenOrderPage.disFee": "Fee",
  "page.loginGa.ga": "Please enter the 6-number verification code.",
  "page.loginGa.title": "Security Authentication(GA Code)",
  "label.repeatPassword": "Confirm Password",
  "pages.api.errorMsg.invalidIPCount": "No more than {maxIPCount}",
  "pages.api.unauthBeforeSettingTip": "For your account safety, please complete {KYCLink} and enable {GALink} before setting up the API.",
  "page.GA.qr.empty": "No QR Data",
  "page.FundPassword.submit.reset": "Confirm",
  "page.FundPassword.submit.set": "Set Fund Password",
  "pages.account.setFundPasswordSuccess": "Success.",
  "page.withdraw.setAssetPwd": "Set Asset Password",
  "page.withdraw.assetPwdTip": "You have not set an asset password yet.",
  "page.deposit.needKy": "Need to finish KYC",
  "table.title.blockHash": "block Hash",
  "page.FundPassword.messageConfirmFundPassword": "Please enter confirmed fund password.",
  "page.FundPassword.confirmFundPassword": "Confirm Fund Password",
  "page.FundPassword.messageFundPassword": "Please enter fund password",
  "page.FundPassword.fundPassword": "Fund Password",
  "page.FundPassword.messageGACode": "Please enter your GA code",
  "page.FundPassword.gaCode": "GA Code",
  "page.FundPassword.update": "Update Fund Password",
  "page.FundPassword.set": "Set Fund Password",
  "page.FundPassword.account": "Account",
  "page.FundPassword.same": "Fund password inconsistency.",
  "pages.WithdrawPage.largerthan": "Not less than",
  "pages.account.setFundPasswordFail": "Two-Factor Authentication code error.",
  "pages.account.resetFundPasswordSuccess": "Success.",
  "pages.account.resetFundPasswordFail": "Two-Factor Authentication code error.",
  "pages.404.back_home": "Back Home",
  "pages.404.tip.message": "Sorry, the page you visited does not exist.",
  "page.deposit.copy": "Copy",
  "pages.Account.retrivecode": "Be Used to retrieve password and modify security settings.",
  "page.Withdraw.Set.sendCode": "Send Email",
  "pages.Account.openGA": "Open GA",
  "pages.Account.closeGA": "Close GA",
  "pages.account.toggleGAFailure": "toggle ga failed",
  "pages.account.toggleGASuccess": "toggle ga successfully",
  "pages.Account.assets_password_notice": "Please set Two-Factor Authorization.",
  "page.withdraw.authTitle": "Security Authentication",
  "label.assetsPassword": "Fund Password",
  "pages.kyc.kyc_review_reject_message": "Your account is in an abnormal status, please contact customer service for processing.",
  "pages.kyc.kyc_review_reject": "Verification failed",
  "label.emailVerifyCode": "Email Verification Code (6 numbers)",
  "page.deposit.gaEnableLink": "enable GA",
  "pages.kyc.form.customizedActivityRequired": "Required field and {max} characters only.",
  "pages.kyc.form.fundsAcquireWayRequired": "Required field and {max} characters only.",
  "page.withdraw.beyondAvailable": "Insufficient balance. Please enter a smaller withdrawal amount.",
  "pages.kyc.form.customizeActivityRequired": "Required field and {max} characters only.",
  "pages.kyc.form.accountPurposeDescribeRequired": "Required field and {max} characters only.",
  "pages.kyc.codes.JUMIO_INDENTITY_TIMES_OUT": "The number of authentication attempts has exceeded the limit. Please try again 24 hours later.",
  "pages.kyc.form.accountPurposeDescibeRequired": "Required field and {max} characters only.",
  "pages.kyc.form.bankAccountInfoRequired": "Required field and {max} characters only.",
  "pages.kyc.form.industryServiceRequired": "Required field and {max} characters only.",
  "pages.kyc.form.websiteAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.customerReachWayRequired": "Required field and {max} characters only.",
  "page.withdraw.beyond": "Beyond the",
  "page.withdraw.monthlyLimit": "Monthly Withdrawal Limit",
  "pages.kyc.form.placeOfRegistrationRequired": "Required field and {max} characters only.",
  "pages.kyc.form.principalBusinessAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.registeredAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.tradingNameRequired": "Required field and {max} characters only.",
  "pages.kyc.form.companyNameRequired": "Required field and {max} characters only.",
  "pages.kyc.form.beneficialOwnerNameRequired": "Required field and {max} characters only.",
  "pages.RechargePage.PROCESSING": "Processing",
  "pages.kyc.form.annualIncomeRequired": "Required field and {max} characters only.",
  "pages.kyc.form.sourceOfFundsRequired": "Required field and {max} characters only.",
  "pages.kyc.form.sourceOfWealthRequired": "Required field and {max} characters only.",
  "pages.kyc.form.totalNetWorthRequired": "Required field and {max} characters only.",
  "pages.kyc.form.form.beneficialOwnerPermanentAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.form.beneficialOwnerMobileRequired": "Required field and {max} characters only.",
  "pages.kyc.form.permanentAddressRequired": "Required field and {max} characters only.",
  "pages.kyc.form.domicileRequired": "Required field and {max} characters only.",
  "pages.kyc.form.emailRequired": "Required field and {max} characters only.",
  "pages.kyc.form.mobileRequired": "Required field and {max} characters only.",
  "pages.kyc.form.required": "Required",
  "pages.kyc.form.placeOfBirthRequired": "Required field and {max} characters only.",
  "link.view": "view",
  "page.withdraw.available": "Available Balance",
  "page.withdraw.minimum": "Minimum",
  "button.addressBook": "Address Book",
  "input.error.select": "Please select",
  "label.address": "Address",
  "label.fee": "Fee",
  "label.amount": "Amount",
  "table.title.date": "Date",
  "table.title.status": "Status",
  "pages.kyc.form.error_file_size": "File size should be no more than {total_mbytes}M.",
  "pages.kyc.card.header": "ACCOUNT VERIFICATION",
  "input.placeholder.select": "Please select",
  "Please complete": "Only support the file format of zip, rar, doc, docx, xls, xlsx, ppt, pptx, pdf, png, jpg, jpeg.",
  "page.withdraw.tip2": "After making a withdrawal, you can track the progress at \"Withdraw History\".",
  "page.withdraw.tip1": "Do not withdraw to ICO address.",
  "page.withdraw.noAuthTip": "After completing {kyc} and {ga}, the withdrawal function will be available.",
  "page.withdraw.authTip": "Do not withdraw to other Matrix accounts,only support for sending external chain addresses.",
  "page.withdraw.dailyLimit": "Daily Withdrawal Limit",
  "page.withdraw.historyTitle": "Withdrawal History",
  "table.title.fee": "Fee",
  "page.withdraw.title": "Withdrawal",
  "page.withdraw.addressEmpty": "No Address",
  "page.withdrawAddress.add": "Add Address",
  "page.withdrawAddress.title": "Address Book",
  "table.title.address": "Address",
  "table.title.note": "Note",
  "button.confirm": "Confirm",
  "button.cancel": "Cancel",
  "table.title.action": "Action",
  "message.success": "Success",
  "link.delete": "Delete",
  "pages.kyc.VolumeOptions.not_greater_than_5000": "≤5000 USD Equivalent",
  "pages.kyc.VolumeOptions.5001_and_10000": "$5,001-$10,000 Equivalent",
  "pages.kyc.VolumeOptions.100000_and_1000000": "$100,000-$500,000 Equivalent",
  "pages.kyc.VolumeOptions.500000_and_1000000": "$500,000-$1,000,000 Equivalent",
  "pages.kyc.VolumeOptions.not_less_than_1000000": "≥1,000,000 USD Equivalent",
  "button.submit": "Submit",
  "button.getCode": "Send Email",
  "button.addNow": "Add Now",
  "label.withdrawNote": "Withdrawal Note",
  "label.withdrawAddress": "Withdrawal Address",
  "pages.kyc.InvestedInCryptoOptions.no_experience": "No experience",
  "pages.kyc.InvestedInCryptoOptions.0_and_1_years": "＜1 year",
  "label.token": "Token",
  "pages.kyc.InvestedInCryptoOptions.1_and_3_years": "1-3 years",
  "page.deposit.needIncrease": "Need to Increase",
  "page.deposit.needKyc": "Need to finish KYC",
  "page.deposit.monthlyLimit": "Monthly Deposit Limit",
  "pages.kyc.InvestedInCryptoOptions.not_less_than_3_years": "More than 3 years",
  "page.deposit.orderLimit": "Deposit Limition per Order",
  "page.deposit.tableEmpty": "No Data",
  "page.deposit.historyTitle": "Deposit History",
  "table.title.confirmations": "Confirmations",
  "table.title.amount": "Amount",
  "table.title.orderId": "Order ID",
  "page.deposit.tip2": "Deposit to {currency} address requires confirmation.",
  "page.deposit.tip1": "{currency} address only accepts {currency} deposit. Any other assets deposited to {currency} address will be irretrievable.",
  "page.deposit.noAuthTip": "After completing {kyc} and {ga}, the deposit function will be available.",
  "page.deposit.copied": "Deposit address copy success.",
  "page.deposit.address": "Address",
  "page.deposit.gaBindLink": "Two-Factor Authentication",
  "page.deposit.kycLink": "KYC",
  "pages.Account.kycType.COUNTERPARTY": "Counterparty",
  "pages.Account.kycType.INSTITUTION": "Institution",
  "pages.Account.kycType.RETAIL": "Individual",
  "pages.Account.idl.enhanced_kyc.linkText": "LV2",
  "pages.kycInstitutionEnhanced.AccountActivity.header2": "Please describe the account purpose and intended account activity / usage.",
  "pages.kyc.proofAddressTypes.local_authority_tax_bill": "Local Authority Tax Bill",
  "pages.kyc.proofAddressTypes.utility_bill": "Utility Bill (electricity, water, internet, etc.)",
  "pages.kyc.proofAddressTypes.tenancy_agreement": "Tenancy Agreement",
  "pages.kyc.proofAddressTypes.electoral_register_search": "Confirmation from an Electoral Register Search That a Person of Such a Name Lives at That Address",
  "pages.kyc.proofAddressTypes.record_of_home_visit": "Record of Home Visit",
  "pages.kyc.form.error.select_upload_file": "Please select and upload a file!",
  "pages.kyc.form.error.select_one": "Please select one.",
  "pages.kycIndividual.form.error.activities": "Required",
  "pages.kycIndividual.form.error.estimated_daily_transactions": "Please select the trading purpose.",
  "components.CountrySelect.placeholder": "Country Issuing",
  "pages.Account.assets_password_set": "Set",
  "pages.Account.assets_password_reset": "Reset",
  "pages.Account.assets_password": "Fund Password",
  "pages.Account.assets_password_description": "Required to complete the order placing, you can choose the security level at your preference.",
  "pages.Account.idl.levelName": "Account Verification",
  "page.kycInstitution.please_upload_file": "Please upload the document required.",
  "page.kycInstitution.please_attach_file": "Please attach a file.",
  "pages.Account.idl.enhanced_kyc.description4": "{withdrawDaily} USD / Daily, {withdrawMonth} USD / Monthly",
  "pages.Account.idl.enhanced_kyc.description3": "{depositOrder} USD / Order, {depositMonth} USD / Monthly",
  "pages.Account.idl.enhanced_kyc.description2": "You have reached the highest KYC level.",
  "pages.Account.idl.enhanced_kyc.description1": "If you need more amount limit, please upgrade your KYC level. ",
  "pages.Account.idl.limited_account.description": "Your information is in an abnormal status and cannot be verified. If you have any questions, please contact our customer service.",
  "pages.Account.idl.limited_account.header": "Limited Account",
  "pages.Account.idl.no_certification.description2": "Please enhance your KYC level to get a higher line. ",
  "pages.Account.idl.no_certification.description1": "After your account is registered, we will need to verify it so that you can make deposits, withdrawals and start trading on our platform. For your account to be verified, please click on Verify Your Account to complete the process.",
  "pages.Account.idl.no_certification.header": "Unverified",
  "pages.Account.limited_account": "Limited Account",
  "pages.Account.verification": "Verify Your Account",
  "pages.kyc.kyc_verify_again_btn": "Verify Again",
  "pages.kyc.kyc_review_failure": "KYC review failure",
  "pages.kyc.status.CounterpartyPendingReviewTip": "It is expected to be completed in 3 business days.",
  "pages.kyc.status.CounterpartyPendingReviewTitle": "Submitted Successfully",
  "pages.kycCounterparty.title": "Counterparty",
  "pages.kyc.card.counterparty.description": "Are you a market counterparty? Then click \"Counterparty\"  to verify.",
  "pages.kyc.card.counterparty.title": "Counterparty",
  "pages.kyc.card.institution.description": "Are you representing a company or company? Then click \"Company\" to verify.",
  "pages.kyc.card.institution.title": "Company",
  "pages.kyc.card.individual.description": "Are you an individual? Then click \"Individual\" to verify.",
  "pages.kyc.card.individual.title": "Individual",
  "pages.kycIndividualEnhanced.intendedVolumesFrequency.fiat": "Fiat",
  "pages.kycIndividualEnhanced.intendedVolumesFrequency.crypto": "Virtual Assets",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyWithdrawalVolAndFreq": "Withdrawal volume (USD) and frequency.",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyDepositVolAndFreq": "Deposit volume (USD) and frequency.",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyHeader": "Intended volumes and frequency (monthly)",
  "pages.kycIndividualEnhanced.accountActivityIntend.no": "No",
  "pages.kycIndividualEnhanced.accountActivityIntend.yes": "Yes",
  "pages.kycIndividualEnhanced.accountActivityDetailTitle": "Please describe the account purpose and intended account activity / usage.",
  "pages.kycIndividualEnhanced.accountActivityIntendTitle": "Do you intend to act on behalf of others?",
  "pages.kycIndividualEnhanced.accountActivityHeader": "Account activity",
  "pages.kycIndividualEnhanced.additionalInformationHeader": "Additional information",
  "pages.kycIndividualEnhanced.verificationDocument.uploadTip": "Only support {fileFormatTotal} format, with the total size less than {fileMByteTotal}M, and amount less than {fileNumberTotal}",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description": "Share certificates, publicly-available registration of ownership, bank or brokerage account statements, probate documents, audited accounts and financial statements, news items from a reputable source and other similar evidence.",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.title": "Verification of All Beneficial Owner's Source of Wealth",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description": "The proof of dividend payments connected to a shareholding, bank statements, salary/bonus certificates, loan documentation and proof of all transactions which gave rise to the payments into the account.",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.title": "Verification of All Beneficial Owner's Source of Funds",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description": "Acceptable Document Types, ID Card (Both Sides), Passport (Double Pages), Driving License (Both Sides).",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.title": "ID Identification of All Beneficial Owner",
  "pages.kycIndividualEnhanced.verificationDocumentHeader": "Verification Documents",
  "pages.kycIndividualEnhanced.title": "Individual Enhanced  KYC",
  "pages.kycIndividualEnhanced.pass": "Verified",
  "pages.kycIndividualEnhanced.ongoing": "Verifying",
  "pages.kycIndividualEnhanced.yourmsg": "Basic information",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequency.fiat": "Fiat",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequency.crypto": "Virtual Assets",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyWithdrawalVolAndFreq": "Withdrawal volume (USD) and frequency",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyDepositVolAndFreq": "Deposit volume (USD) and frequency",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyHeader": "Intended volumes and frequency (monthly)",
  "pages.kycInstitutionEnhanced.AccountActivity.header1": "Do you intend to act on behalf of others?",
  "pages.kycInstitutionEnhanced.AccountActivity.title": "Account activity",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.title": "Company information and business activity",
  "pages.kycInstitutionEnhanced.option.no": "No",
  "pages.kycInstitutionEnhanced.option.yes": "Yes",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header7": "Bank account information (if you do not yet have a bank account, please state the jurisdiction in which you will be opening an account).",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header6": "Do you have an AML policy? If so, please provide a copy.",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header5": "Is your business AML regulated? Does the company have any financial licenses?",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header4": "Please specify your company's industry and describe your company's services.",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header3": "How were the funds (Fiat / Virtual Assets) that you intend to deposit at Matrix acquired?",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header2": "What is your company website address?",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header1": "How do your customers typically reach you?",
  "pages.kycInstitutionEnhanced.title": "Institution Enhanced  KYC",
  "pages.kycIndividual.estimated_daily_transactions": "Estimated Daily Transactions",
  "pages.kycIndividual.withdrawal_frequency_and_volume": "Withdrawal frequency and volume",
  "pages.kycIndividual.deposit_frequency_and_volume": "Deposit frequency and volume",
  "pages.kycIndividual.intended_volumes_and_frequency": "Intended volumes and frequency (Daily)",
  "pages.kycIndividual.financial_information": "Financial Information",
  "pages.kycIndividual.how_long_invested_crypto": "How long have you invested in Virtual Assets?",
  "pages.kycIndividual.info_about_beneficial_owner": "Information about Beneficial (Actual) Owner",
  "pages.kycIndividual.same_as_personal_information": "Same as Basic Information",
  "pages.kycIndividual.estimated_annual_income": "Estimated Annual Income (USD)",
  "pages.kycIndividual.source_of_funds_deposited_into_account": "Source of funds deposited into account",
  "pages.kycIndividual.source_of_wealth": "Source of Wealth",
  "pages.kycIndividual.estimated_total_net_worth": "Estimated Total Net Worth (USD)",
  "pages.kyc.institutionType.INSTITUTION_ACCOUNT_VERIFICATION_Enhanced_CDD": "Institution account verifiction enhanced CDD",
  "pages.kyc.institutionType.TRUST_OR_OTHER_SIMILAR_LEGAL_ARRANGEMENT": "Trust or Other Similar Legal Arrangement",
  "pages.kyc.institutionType.FOUNDATION_OR_OTHER_SIMILAR_LEGAL_ARRANGEMENT": "Foundation or Other Similar Legal Arrangement",
  "pages.kyc.institutionType.CORPORATION": "Institution",
  "pages.kyc.institutionType.REGULATED_FINANCIAL_INSTITUTION": "Regulated Financial Institution",
  "pages.kyc.activity.CUSTOM_ACTIVITY": "Any other business activity (Specify)",
  "pages.kyc.activity.MANAGING_FUNDS_OF_OTHER_INDIVIDUALS": "Managing funds of other individuals",
  "pages.kyc.activity.DEPOSITING_OR_WITHDRAWING_FUNDS_TO_BANK": "Depositing or withdrawing funds to business bank accounts",
  "pages.kyc.activity.ACCEPTING_OR_CONVERTING_PAYMENTS_FROM_CUSTOMERS": "Accepting or converting payments from customers for services rendered or goods sold",
  "pages.kyc.label.fiat": "Fiat",
  "pages.kyc.label.crypto": "Virtual Assets",
  "pages.kyc.form.error_only_one_file": "Documents can't be submitted multiple times in a short period time.",
  "pages.kyc.form.error_upload_files_firstly": "Please upload files first.",
  "pages.kyc.form.error_files_total_number": "The total number of files should be no more than {total_number}.",
  "pages.kyc.form.error_files_total_size": "The total size of files should be no more than {total_mbytes}M.",
  "pages.kyc.form.error_select_and_upload_files": "Please select and upload files.",
  "pages.kyc.form.invalid_email": "Invalid email address.",
  "pages.kyc.form.nameRequired": "Required field and {max} characters only.",
  "pages.kyc.tip.start_upload": "Start upload",
  "pages.kyc.tip.uploading": "Uploading...",
  "pages.kyc.tip.select_file": "Select File",
  "pages.kyc.tip.upload_failure": "Upload Failed.",
  "pages.kyc.tip.upload_successfully": "Uploaded Successfully.",
  "pages.kyc.tip.form_invalid_message": "The form has some error.",
  "pages.kyc.tip.submit_failure": "Submit Failed",
  "pages.kyc.tip.submit_successfully": "Submitted Successfully.",
  "pages.kyc.submitBtn": "Submit",
  "pages.kyc.tip.sending": "Loading...",
  "pages.kyc.tip.loading": "loading...",
  "pages.OrderBook.form.marketPriceLabel": "Market Price",
  "pages.api.enterIPs.placehold": "If entering multiple IPs, separate using commas.",
  "pages.OpenOrderPage.status.SEQUENCED": "Sequenced ",
  "component.priceOrder.dealTime": "UTC Time",
  "page.message.empty": "No Message",
  "xiao.test": "test",
  "page.Account.retrivecode": "It is used to retrieve password and modify security settings.",
  "components.Footer.menuReferal": "Referral",
  "components.Footer.menuAPIDocumentation": "API Documentation",
  "components.Footer.menuServices": "Services",
  "components.Footer.menuOurCompany": "Our Company",
  "components.Footer.menuSupportCenter": "Support Center",
  "components.Footer.menuPLegalPrivacy": "Legal Privacy",
  "components.Footer.menuFeeSchedule": "Fee Schedule",
  "components.Footer.menuCustomerSupport": "Customer Support",
  "pages.WithdrawPage.addressNames": "From address",
  "pages.Account.nameLabel": "Name",
  "pages.RechargePage.SUBMITTED": "Submitted",
  "pages.RechargePage.WAITING_FOR_WALLET": "Processing",
  "pages.RechargePage.APPROVED": "Approved",
  "pages.RechargePage.DONE": "DONE",
  "pages.RechargePage.FAILED": "FAILED",
  "page.GA.Set.bindauth": "Enable Two-Factor Authentication",
  "pages.WithdrawPage.todelete": "Are you sure you want to delete it?",
  "pages.account.bindGAFailure": "Two-Factor Authentication code error.",
  "pages.account.bindGASuccess": "Success.",
  "pages.account.bindGAEmalCodeFailure": "Your operation is too frequent, please try again later.",
  "pages.account.bindGAEmalCodeSuccess": "Email code sent successfully.",
  "pages.WithdrawPage.downloadexcel": "Download Excel ",
  "page.Account.enhancedKyc": "LV2",
  "page.Account.higherLimit": "Higher Limit",
  "page.Account.basicKyc": "Basic KYC",
  "pages.WithdrawPage.selectaddress": "Please select a withdrawal address",
  "pages.RechargePage.PENDING": "Pending",
  "pages.RechargePage.DEPOSITED": "Completed",
  "pages.RechargePage.NEED_CANCEL": "Pending",
  "pages.RechargePage.CANCELLED": "Cancelled",
  "pages.RechargePage.WAITING_FOR_APPROVAL": "Processing",
  "pages.RechargePage.DENIED": "Rejected",
  "button.refresh": "Refresh",
  "message.warning.tokenExpire": "Your session is Stationary. Please click \"refresh\" to continue.",
  "message.title.tip": "Notice",
  "pages.WithdrawPage.withdralInputReminder": "Please input address",
  "page.MonthlyStatements.FundsReportTitle": "Assets Report",
  "page.MonthlyStatements.AccountLabel": "Account",
  "page.MonthlyStatements.BaseCurrencyLabel": "Base Currency",
  "page.MonthlyStatements.AccountTypeLabel": "Account Type",
  "page.MonthlyStatements.nameLabel": "Name",
  "page.GA.Change.sendCode": "Send Email",
  "page.GA.Change.codeNotice": "Please enter code",
  "page.GA.Change.vcodeNotice": "Please enter email code",
  "page.GA.Change.ncodeNotice": "Please enter old GA code",
  "page.GA.Set.sendCode": "Send Email",
  "page.GA.Set.vcodeNotice": "Please enter verification code",
  "page.GA.Set.codeNotice": "Please enter code",
  "pages.RechargePage.downloadExcel": "Download Excel",
  "pages.RechargePage.copy": "Copy",
  "pages.RechargePage.address": "address",
  "pages.OpenOrderPage.side.SELL": "SELL",
  "pages.OrderHistoryPage.empty": "You have no order history",
  "button.ok": "Confirm",
  "asdas": "asdasda",
  "pages.WithdrawPage.value": "value",
  "pages.WithdrawPage.trade": "Trade",
  "pages.WithdrawPage.tipGA": "After Two-Factor Authentication, the withdrawal address can be added.",
  "pages.WithdrawPage.tip2": "After making a withdrawal, you can track its progress on the history page.",
  "pages.WithdrawPage.tip1": "Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.",
  "pages.WithdrawPage.tip": "Please note",
  "pages.WithdrawPage.time": "Time",
  "pages.WithdrawPage.submit": "Submit",
  "pages.WithdrawPage.status": "Status",
  "pages.WithdrawPage.pointError": "more than",
  "pages.WithdrawPage.number": "Amount",
  "pages.WithdrawPage.noMsg": "You have no withdrawal history.",
  "pages.WithdrawPage.moreError": "Beyond the maximum withdrawal limit",
  "pages.WithdrawPage.more": "View All",
  "pages.WithdrawPage.limit": "Withdrawal Limit",
  "pages.WithdrawPage.lessError": "Beyond the minimum withdrawal limit",
  "pages.WithdrawPage.inputga": "Please enter Two-Factor Authentication code.",
  "pages.WithdrawPage.important4": "Maximum withdrawal",
  "pages.WithdrawPage.important3": "Do not withdraw directly to a crowdfund or ICO address",
  "pages.WithdrawPage.important2": "SELL",
  "pages.WithdrawPage.important1": "Minimum withdrawal",
  "pages.WithdrawPage.important": "Important",
  "pages.WithdrawPage.history": "History",
  "pages.WithdrawPage.header": "Withdrawals",
  "pages.WithdrawPage.get": "You Will Get",
  "pages.WithdrawPage.gaCode": "GA code",
  "pages.WithdrawPage.fee": "Transaction Fee",
  "pages.WithdrawPage.emptyError": "Required field.",
  "pages.WithdrawPage.delete": "Delete",
  "pages.WithdrawPage.currency": "Currency",
  "pages.WithdrawPage.cannotempty": "Required field.",
  "pages.WithdrawPage.amount": "Amount",
  "pages.WithdrawPage.addressName": "From Address",
  "pages.WithdrawPage.address": "Withdrawal Address",
  "pages.WithdrawPage.addmsg": "Add the address",
  "pages.WithdrawPage.add": "Add",
  "pages.WithdrawPage. trade": "Go to Trade",
  "pages.WithdrawPage. pointError": "Amount decimal places must be no more than ",
  "pages.WithdrawPage. important1": "Minimum withdrawal",
  "pages.TradeHistoryPage.tradehistory": "Trade History",
  "pages.TradeHistoryPage.searchBtn": "Search",
  "pages.TradeHistoryPage.downloadExcelBtn": "Download",
  "pages.TradeHistoryPage.colKeyType": "Type",
  "pages.TradeHistoryPage.colKeyTotal": "Total",
  "pages.TradeHistoryPage.colKeySymbol": "Symbol",
  "pages.TradeHistoryPage.colKeySide": "Side",
  "pages.TradeHistoryPage.colKeyReceive": "Receive",
  "pages.TradeHistoryPage.colKeyPrice": "Price",
  "pages.TradeHistoryPage.colKeyFee": "Fee",
  "pages.TradeHistoryPage.colKeyDate": "colKeyDate",
  "pages.TradeHistoryPage.colKeyAverage": "Average",
  "pages.TradeHistoryPage.colKeyAmount": "Amount",
  "pages.TradeHistoryPage.cancelConfirmTitle": "Confirm",
  "pages.TradeHistoryPage.cancelConfirmContent": "Are you sure to cancel it ?",
  "pages.RechargePage.value": "Value",
  "pages.RechargePage.trade": "Go to Trade",
  "pages.RechargePage.tip4": "history page",
  "pages.RechargePage.tip3": "After making a deposit, you can track its progress....",
  "pages.RechargePage.tip2": "more network confirmations.",
  "pages.RechargePage.tip1": "Coins will be deposited immediately after",
  "pages.RechargePage.tip": "Please note",
  "pages.RechargePage.time": "Time",
  "pages.RechargePage.status": "Status",
  "pages.RechargePage.qrcode": "Show QR Code",
  "pages.RechargePage.number": "Amount",
  "pages.RechargePage.noMsg": "You have no deposit history.",
  "pages.RechargePage.more": "More",
  "pages.RechargePage.history": "History",
  "pages.RechargePage.header": "Deposit",
  "pages.RechargePage.filled": "Confirm",
  "pages.RechargePage.currency": "Currency",
  "pages.RechargePage.cpoy": "Copy Address",
  "pages.RechargePage.attentionText2": "to this deposit address. Sending any other coin or token to this address may result in the loss of your deposit.",
  "pages.RechargePage.attentionText1": "Send only",
  "pages.RechargePage.attention": "Important",
  "pages.RechargePage.addressName": "From Address",
  "pages.Orders.type.market": "MARKET",
  "pages.Orders.type.limit": "LIMIT",
  "pages.Orders.symbol.all": "All",
  "pages.Orders.status.done": "Done",
  "pages.Orders.status.canceled": "Canceled",
  "pages.Orders.status.cancel": "Canceled",
  "pages.Orders.status.all": "All",
  "pages.Orders.side.sell": "Sell",
  "pages.Orders.side.buy": "Buy",
  "pages.Orders.side.all": "All",
  "pages.Orders.action.cancelTitle": "Cancel",
  "pages.OrderHistoryPage.searchBtn": "Search",
  "pages.OrderHistoryPage.orderhistory": "Orders History",
  "pages.OrderHistoryPage.downloadExcelBtn": "Download",
  "pages.OrderHistoryPage.colKeyType": "Type",
  "pages.OrderHistoryPage.colKeyTrigger": "Trigger",
  "pages.OrderHistoryPage.colKeySymbol": "Symbol",
  "pages.OrderHistoryPage.colKeyStatus": "Status",
  "pages.OrderHistoryPage.colKeySide": "Side",
  "pages.OrderHistoryPage.colKeyPrice": "Price",
  "pages.OrderHistoryPage.colKeyFinished": "Finished",
  "pages.OrderHistoryPage.colKeyFilled": "Filled",
  "pages.OrderHistoryPage.colKeyDate": "colKeyDate",
  "pages.OrderHistoryPage.colKeyAverage": "Average",
  "pages.OrderHistoryPage.colKeyAmount": "Amount",
  "pages.OrderHistoryPage.cancelConfirmTitle": "Confirm",
  "pages.OrderHistoryPage.cancelConfirmContent": "Are you sure to cancel it ?",
  "pages.OrderBox.openOrder": "Open Order",
  "pages.OrderBox.more": "More",
  "pages.OrderBox.historyOrder": "Order History",
  "pages.OrderBook.tabStopLimitTip": "A Stop-Limit order is an order to buy / sell Virtual Assets once the latest price reaches the pre-set trigger price.",
  "pages.OrderBook.tabStopLimit": "Stop-Limit",
  "pages.OrderBook.tabMarket": "Market",
  "pages.OrderBook.tabLimit": "Limit",
  "pages.OrderBook.successMessage": "Success",
  "pages.OrderBook.sell": "Sell",
  "pages.OrderBook.form.total": "Total",
  "pages.OrderBook.form.stop": "Stop",
  "pages.OrderBook.form.price": "Price",
  "pages.OrderBook.form.limit": "Limit",
  "pages.OrderBook.form.amount": "Amount",
  "pages.OrderBook.errors.StopNotMoreThanLimit": "{Limit} should be no less than 90% of {Stop}",
  "pages.OrderBook.errors.StopNotLessThanLimit": "{Limit} should be no more than 110% of {Stop}",
  "pages.OrderBook.errors.SmallOrEqualTo": "Insufficient account balance",
  "pages.OrderBook.errors.Required": "Required",
  "pages.OrderBook.errors.normalNumber": "Must be numbers greater than zero.",
  "pages.OrderBook.errors.GreaterOrEqualTo": "Must be greater than or equal to {min}",
  "pages.OrderBook.errors.DecimalPoint": "Decimal point length error(max: {scale})",
  "pages.OrderBook.buy": "Buy",
  "pages.OpenOrderPage.type.MARKET": "MARKET",
  "pages.OpenOrderPage.type.LIMIT": "LIMIT",
  "pages.OpenOrderPage.type": "Type",
  "pages.OpenOrderPage.triggerOn": "Trigger Conditions ",
  "pages.OpenOrderPage.tradehistory": "Trade History",
  "pages.OpenOrderPage.total": "Total",
  "pages.OpenOrderPage.symbol": "Pair",
  "pages.OpenOrderPage.status.MARKET": "Market",
  "pages.OpenOrderPage.status.LIMIT": "Limit",
  "pages.OpenOrderPage.status.DONE": "Done",
  "pages.OpenOrderPage.status.CANCELED": "Canceled",
  "pages.OpenOrderPage.status.BUY": "Buy",
  "pages.OpenOrderPage.status": "Status",
  "pages.OpenOrderPage.side.BUY": "BUY",
  "pages.OpenOrderPage.side": "Side",
  "pages.OpenOrderPage.searchBtn": "Search",
  "pages.OpenOrderPage.receive": "Receive",
  "pages.OpenOrderPage.price2": "Value (Market)",
  "pages.OpenOrderPage.price1": "Price (Limit)",
  "pages.OpenOrderPage.price": "Price",
  "pages.OpenOrderPage.pari": "Market",
  "pages.OpenOrderPage.orderhistory": "Order History",
  "pages.OpenOrderPage.operation": "Operation",
  "pages.OpenOrderPage.openorder": "Open Orders",
  "pages.OpenOrderPage.loadMore": "Load more",
  "pages.OpenOrderPage.finished": "Finished",
  "pages.OpenOrderPage.filled": "Filled",
  "pages.OpenOrderPage.fee": "Fee",
  "pages.OpenOrderPage.empty": "You have no open orders",
  "pages.OpenOrderPage.downloadExcelBtn": "Download",
  "pages.OpenOrderPage.date": "Date",
  "pages.OpenOrderPage.confirm.title": "Confirm",
  "pages.OpenOrderPage.confirm.okBtn": "Yes",
  "pages.OpenOrderPage.confirm.content": "Do you want to cancel it ?",
  "pages.OpenOrderPage.confirm.cancelBtn": "No",
  "pages.OpenOrderPage.confirm": "Are you sure to cancel it ?",
  "pages.OpenOrderPage.cancel": "cancel",
  "pages.OpenOrderPage.averagePrice": "Average",
  "pages.OpenOrderPage.average": "Average",
  "pages.OpenOrderPage.amount": "Amount",
  "pages.OpenOrderPage.action": "Action",
  "pages.models.orders.orderCannotCancel": "Order Canceled",
  "pages.kycInstitution.title": "Company",
  "pages.kycInstitution.requiredDocumentTitle": "Company Account Verification",
  "pages.kycInstitution.requiredDocuments": "Upload Documents",
  "pages.kycInstitution.requiredDocumentDownloadTxt": "Download document requirements for",
  "pages.kycInstitution.requiredDocumentDescription": "Thank you for your interest in opening an institution account at Matrix. In order to continue with your institution account verification, please provide the company-related documents and information listed below. It should be noted that we may ask for additional information after you submit the initial request.",
  "pages.kycInstitution.pendingReview": "Pending Review",
  "pages.kycInstitution.form.tradingName": "Trading Name",
  "pages.kycInstitution.form.registrationDate": " Incorporation Date",
  "pages.kycInstitution.form.registeredAddress": "Registered Address",
  "pages.kycInstitution.form.principalBusinessAddress": "Business Address",
  "pages.kycInstitution.form.placeOfRegistration": "Place of Registration",
  "pages.kycInstitution.form.institutionTitle": "Company Type",
  "pages.kycInstitution.form.generalInformationTitle": "General Information",
  "pages.kycInstitution.form.companyName": "Full Name of Company",
  "pages.kycInstitution.form.activityTitle": "What Is the Main Purpose of Your Institution Account？",
  "pages.kycInstitution.basicInfoSubmitBtn": "Submit",
  "pages.kycInstitution.basicInfo": "Basic Information",
  "pages.kycInstitution.approved": "KYC Approved",
  "pages.kycIndividual.yourmsg": "Basic Information",
  "pages.kycIndividual.type5": "Online purchase / payment",
  "pages.kycIndividual.type4": "Buying / selling goods or services",
  "pages.kycIndividual.type3": "Reselling (Broker / Dealer) related activities",
  "pages.kycIndividual.type2": "Investing",
  "pages.kycIndividual.type1": "Arbitrage",
  "pages.kycIndividual.title": "Individual",
  "pages.kycIndividual.successTitle": "Passed",
  "pages.kycIndividual.successTip": "You can make deposits, withdrawals, trades and other operations.",
  "pages.kycIndividual.proofAddress": "Proof of Address",
  "pages.kycIndividual.postcode": "Post Code",
  "pages.kycIndividual.phone": "Mobile Number",
  "pages.kycIndividual.permanentAddress": "Domicile Address",
  "pages.kycIndividual.pass": "KYC Approved",
  "pages.kycIndividual.other": "Purpose of Account",
  "pages.kycIndividual.ongoingTitle": "Pending Review",
  "pages.kycIndividual.ongoingTip": "It is expected to be completed in 3 business days.",
  "pages.kycIndividual.ongoing": "Pending Review",
  "pages.kycIndividual.occupation": "Occupation",
  "pages.kycIndividual.lastName": "Last Name",
  "pages.kycIndividual.information": "Basic Information",
  "pages.kycIndividual.idmsg": "Identity Verification",
  "pages.kycIndividual.header": "Individual",
  "pages.kycIndividual.firstName": "First Name",
  "pages.kycIndividual.failureTitle": "Failed to pass",
  "pages.kycIndividual.failureTip": "Your submission did not pass the audit, please do the verification again ",
  "pages.kycIndividual.emptyError": "This field is required.",
  "pages.kycIndividual.emailError": "Please enter the email with correct format.",
  "pages.kycIndividual.email": "Email ID",
  "pages.kycIndividual.deal": "Trading Purposes",
  "pages.kycIndividual.currentAddress": "Mailing Address",
  "pages.kycIndividual.country": "Nationality",
  "pages.kycIndividual.contactWay": "Contact Information",
  "pages.kycIndividual.button": "Submit",
  "pages.kycIndividual.birthplace": "Place of Birth",
  "pages.kycIndividual.birthday": "Date of Birth",
  "pages.kycIndividual.address": "Address Information",
  "pages.kycEnhanced.title": "Account Verification",
  "pages.kycEnhanced.description": "Thank you for your interest in opening an account at Matrix. In order to continue with your account verification, please download the PDF below and provide the required documents and information, then upload them. It should be noted that we may ask for additional information.",
  "pages.kyc.status.PendingReviewTitle": "Your account verification is under review",
  "pages.kyc.status.PendingReviewTip": "Verification will complete in 4 working days.Thank you for your patience.",
  "pages.kyc.status.ApprovedTitle": "Welcome to Matrix",
  "pages.kyc.status.ApprovedTip": "You can make deposits, withdrawals, trades and other operations.",
  "pages.HomePage.tryme.header": "Try me!",
  "pages.HomePage.tryme.atPrefix": "@",
  "pages.HomePage.start_project.message": "A highly scalable, offline-first foundation with the best DX and a focus on performance and best practices.",
  "pages.HomePage.start_project.header": "Start your next react project in seconds",
  "pages.HomePage.features_sectionTop_item3.title": "Fast Trading",
  "pages.HomePage.features_sectionTop_item3.description": "Advanced technology enables the matching engine to perform stably and quickly; our support for Fiat money deposit and withdrawal makes users trade effortlessly.",
  "pages.HomePage.features_sectionTop_item2.title": "System Stability",
  "pages.HomePage.features_sectionTop_item2.description": "The main system has passed the reliability test many times. Multi-node fault tolerance and multi-node switching are implemented to ensure stability and reliability of the trading system.",
  "pages.HomePage.features_sectionTop_item1.title": "Compliance and Security",
  "pages.HomePage.features_sectionTop_item1.description": "We provide AML / KYC compliance, regulatory oversight and offline storage to secure our platform and users.",
  "pages.HomePage.features_sectionBottom_text.title2": "and Active Traders",
  "pages.HomePage.features_sectionBottom_text.title1": "Tools for Virtual Assets Beginners",
  "pages.HomePage.features_sectionBottom_text.content": "Whether you are an experienced trader or just getting started, Matrix offers you the tools to harness the future of money.",
  "pages.HomePage.banner.subTitle": "Matrix Is Coming Soon...",
  "pages.api.unauthPlease": "Please",
  "pages.api.unauthBindToGA": "Two-Factor Authentication",
  "pages.api.unauthBeforeSetting": "Before Setting API",
  "pages.api.Title.copy": "Copy",
  "pages.api.Title.copied": "Copied",
  "pages.api.title": "API Management",
  "pages.api.submiting": "Submitting...",
  "pages.api.options.canWithdrawal": "Enable Withdrawals",
  "pages.api.options.canTrade": "Enable Trading",
  "pages.api.options.canRead": "Readable Info",
  "pages.api.loading": "Loading...",
  "pages.api.ipOptions.only": "Grant access to trusted IPs only (Recommended)",
  "pages.api.ipOptions.all": "Unrestricted (Low Security)",
  "pages.api.form.submitBtn": "Submit",
  "pages.api.form.secretKeyLabel": "Secret Key",
  "pages.api.form.optionLabel": "Option",
  "pages.api.form.ipAccessRestrictionLabel": "IP Access Restriction",
  "pages.api.form.gaLabel": "GA Code",
  "pages.api.form.descriptionLabel": "API Key label",
  "pages.api.form.accessKeyLabel": "Access Key",
  "pages.api.errors.invalidGA": "Two-Factor Authentication code error.",
  "pages.api.errorMsg.required": "Required",
  "pages.api.errorMsg.invalidIPs": "Invalid IPs (multiple IPs, using commas to separate)",
  "pages.api.editKeyBtn": "Edit",
  "pages.api.editForm.editTitle": "Edit Key",
  "pages.api.editForm.createTitle": "Create New Key",
  "pages.api.deleteKeyBtn": "Delete",
  "pages.api.deleteConfirm.title": "Delete Confirmation",
  "pages.api.deleteConfirm.succResult": "Success.",
  "pages.api.deleteConfirm.okBtn": "Yes",
  "pages.api.deleteConfirm.content": "Do you want to delete this API Key?",
  "pages.api.deleteConfirm.cancelBtn": "No",
  "pages.api.createNewKeyBtn": "Create new key",
  "pages.api.createdResult.warnTip": "The secret key only displayed once, and cannot be retrieved after lost. Please keep it in a safe place.",
  "pages.api.createdResult.tipTitle": "Tip",
  "pages.api.createdResult.tip2": "If you have forgotten the secret key, please retract the key pair and apply for a new key pair.",
  "pages.api.createdResult.tip1": "To avoid property loss, please do not give your access key or secret key to others.",
  "pages.api.createdResult.modalTitle": "Create Successfully",
  "pages.api.createdResult.enterBtn": "Already Saved",
  "pages.api.apiDocTitle": "API documentation",
  "pages.api.apiDescriptionView": "View",
  "pages.api.apiDescription": "Creating an API private key to provide access to markets and real-time trading services on Matrix via a third-party site or application.",
  "page.MonthlyStatements.WithdrawalFee": "Withdrawal Fee",
  "page.MonthlyStatements.Withdrawal": "Withdraw",
  "page.MonthlyStatements.USDMarket": "USD Market Value Estimate",
  "page.MonthlyStatements.TradingFee": "Trading Fee",
  "page.MonthlyStatements.Symbol": "Symbol",
  "page.MonthlyStatements.Starting": "Starting",
  "page.MonthlyStatements.selectMonth": "Please Select Month First",
  "page.MonthlyStatements.sectionTitle2": "2. The time is displayed in the form of UTC+0 time zone.",
  "page.MonthlyStatements.sectionTitle1": "Legal Notes",
  "page.MonthlyStatements.sectionContent2": "3.The starting & ending prices on this Statement may come from Matrix platform & third party sources.",
  "page.MonthlyStatements.sectionContent1": "1. Please promptly report any inaccuracy or discrepancy in this Statement, or in your account to our Customer Service Department by using the form available on the Matrix website.",
  "page.MonthlyStatements.Quantity": "Quantity",
  "page.MonthlyStatements.pageTitle": "Monthly Account Statement",
  "page.MonthlyStatements.net": "Net Asset Value",
  "page.MonthlyStatements.Interest": "Interest",
  "page.MonthlyStatements.Funds": "Asset Type",
  "page.MonthlyStatements.ExchangeRate": "Exchange Rate",
  "page.MonthlyStatements.Ending": "Ending",
  "page.MonthlyStatements.Download": "Download PDF",
  "page.MonthlyStatements.Deposit": "Deposit",
  "page.MonthlyStatements.Amount": "Amount",
  "page.MonthlyStatements.account": "Account Information",
  "page.message.title": "Message",
  "page.HomePage.topItemTitle3": "High Performance",
  "page.HomePage.topItemTitle2": "Secure",
  "page.HomePage.topItemTitle1": "Global Trading Platform",
  "page.HomePage.topItemContent3": "Robust core matching engine, able to handle high volume of orders and execution.",
  "page.HomePage.topItemContent2": "A safe and secure trading environment, and insured custody services. Multi-node disaster tolerance, remote disaster recovery, and multi-server switching to ensure resilience.",
  "page.HomePage.topItemContent1": "Access for retail and institutional customers from around the globe.",
  "page.HomePage.startTrading": "Start Trading",
  "page.HomePage.prodTextMore": "Learn More",
  "page.HomePage.prodText": "Matrix has obtained an in-principle approval (IPA) from Abu Dhabi Global Markets (ADGM).",
  "page.HomePage.Pair": "Pair",
  "page.HomePage.messageTitle": "World Class Trading Platform",
  "page.HomePage.message2": "history, and simple order process so you can trade from day one",
  "page.HomePage.message1": "Matrix offers an intuitive interface with real time orderbooks, charting tools, trade",
  "page.HomePage.LastPrice": "Last Price",
  "page.HomePage.empoverTitle": "You Are Empowered",
  "page.HomePage.empoverItemTitle3": "Our Commitment to Your Security",
  "page.HomePage.empoverItemTitle2": "Built for Both Beginners and Experts",
  "page.HomePage.empoverItemTitle1": "Trade with Virtual Assets & Fiat",
  "page.HomePage.empoverItemContent3": "Matrix is committed to ensuring the security for our customers. We obey all the rules and regulations of Abu Dhabi Financial Services Regulatory Authority.",
  "page.HomePage.empoverItemContent2": "Matrix trading platform is designed to be user-friendly. Simple clicks can help beginners and experts easily operate the whole trading process. Users are in total control.",
  "page.HomePage.empoverItemContent1": "Matrix enables users to choose to deposit and withdraw either Virtual Assets or Fiat currency as they like.",
  "page.HomePage.Markets": "Markets",
  "page.HomePage.bottomText3": "Whether you're an experienced trader or just getting started, Matrix offers you the tools to harness the future of money.",
  "page.HomePage.bottomText2": "and Active Traders",
  "page.HomePage.bottomText1": "Tools for Virtual Assets Beginners",
  "page.HomePage.24Volume": "24h Volume",
  "page.HomePage.24Change": "24h Change",
  "page.GA.Set.stepTitle2": "Please enter the 6-number code and email code to complete the settings.",
  "page.GA.Set.stepTitle": "Search for “Google Authenticator” at Apple Store / Google Play Store",
  "page.GA.Set.PleaseEnterGoogleCode": "Please enter GA code",
  "page.GA.Set.PleaseEnterEmailCode": "Please enter email code",
  "page.GA.Set.notice2": "Please keep your private key secure to avoid login problem in case you change or lose your phone.",
  "page.GA.Set.notice1": "After installation, open Google Authenticator and scan the QR code below or enter the key to get a 6-number code.",
  "page.GA.Set.GoogleAuthentication": "GA Code",
  "page.GA.Set.Emailcode": "Email Code",
  "page.GA.Set.Confirm": "Confirm",
  "page.GA.Set.account": "Account",
  "page.GA.NewGoogleAuthentication": "New GA code",
  "page.GA.Change.stepTitle": "Please enter the 6-number code and email verification code to complete the settings.",
  "page.GA.Change.PleaseEnterNewGoogleCode": "Please enter new GA code",
  "page.GA.Change.PleaseEnterGoogleCode": "Please enter old GA code",
  "page.GA.Change.PleaseEnterEmailCode": "Please enter email verification code ",
  "page.GA.Change.notice2": "Please keep your private key secure to avoid login problem in case you change or lose your phone.",
  "page.GA.Change.notice1": "After installation, open Two-Factor Authenticator and scan the QR code below or enter the key to get a 6-number code.",
  "page.GA.Change.NewGoogleAuthentication": "New GA Code",
  "page.GA.Change.GoogleAuthentication": "Old GA Code",
  "page.GA.Change.Emailcode": "Email Code",
  "page.GA.Change.Confirm": "Confirm",
  "page.GA.Change.ChangeGA": "Change Two-Factor Authorization",
  "page.GA.Change.account": "Account",
  "page.cryptoAssets.totalEstimate": "Total Estimate",
  "page.cryptoAssets.title": "Virtual Assets",
  "page.cryptoAssets.table.total": "Total",
  "page.cryptoAssets.table.frozen": "Frozen",
  "page.cryptoAssets.table.coin": "Coin",
  "page.cryptoAssets.table.btcEstimate": "BTC Estimate",
  "page.cryptoAssets.table.balance": "Available",
  "page.cryptoAssets.table.action": "Action",
  "page.cryptoAssets.hideZero": "Hide small balances",
  "pages.Account.WithdrawalOrDeposit": "Withdraw or Deposit",
  "pages.Account.Usedto": "It is used to retrieve password & modify security settings.",
  "pages.Account.Unverified": "Unverified",
  "pages.Account.Set": "Set",
  "pages.Account.Security": "Security",
  "pages.Account.ReferralLink": "Referral Link",
  "pages.Account.ReferralFriends": "Refer Your Friends",
  "pages.Account.ReferralContent": "Use the following link to recommend Matrix to your friends. When they sign up by using your referral code, they will get 10% off trading fees for 12 months.",
  "pages.Account.Referral": "Referral",
  "pages.Account.perOrder": "Per Order",
  "pages.Account.perMonth": "Per Month",
  "pages.Account.pageWarning": "Security Tip: For your account safety, please check carefully the domain you are visiting, enable Two-Factor Authentication, and do not disclose your verification codes to anyone including the Matrix staff.",
  "pages.Account.pageTitle": "Account",
  "pages.Account.MyReferralID": "My Referral ID",
  "pages.Account.LoginPassword": "Login Password",
  "pages.Account.Limit": "Limit",
  "pages.Account.GoogleAuthentication": "Two-Factor Authentication",
  "pages.Account.EstimatedCommissionValue": "Estimated Commission",
  "pages.Account.CommissionRate": "Commission Rate",
  "pages.Account.Change": "Change",
  "navBar.lang": "11",
  "name": "Name",
  "message.title.error": "Error",
  "message.success.register": "Registered Successfully!",
  "message.success.passwordUpdate": "Password update succeeded, please login again.",
  "message.success.passwordReset": "Password reset successfully .",
  "message.success.checkEmail": "Please check your email box.",
  "menu.welcome": "Welcome",
  "menu.result.success": "Success",
  "menu.result.fail": "Fail",
  "menu.result": "Result",
  "menu.register.result": "Register Result",
  "menu.register": "Register",
  "menu.profile.basic": "Basic Profile",
  "menu.profile.advanced": "Advanced Profile",
  "menu.profile": "Profile",
  "menu.more-blocks": "More Blocks",
  "menu.login": "Login",
  "menu.list.table-list": "Search Table",
  "menu.list.search-list.projects": "Search List (Projects)",
  "menu.list.search-list.articles": "Search List (Articles)",
  "menu.list.search-list.applications": "Search List (Applications)",
  "menu.list.search-list": "Search List",
  "menu.list.card-list": "Card List",
  "menu.list.basic-list": "Basic List",
  "menu.list": "List",
  "menu.home": "Home",
  "menu.form.step-form.result": "Step Form (Finished)",
  "menu.form.step-form.info": "Step Form (fill in transfer information)",
  "menu.form.step-form.confirm": "Step Form (confirm transfer information)",
  "menu.form.step-form": "Step Form",
  "menu.form.basic-form": "Basic Form",
  "menu.form.advanced-form": "Advanced Form",
  "menu.form": "Form",
  "menu.exception.trigger": "Trigger",
  "menu.exception.server-error": "500",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.500": "500",
  "menu.exception.404": "404",
  "menu.exception.403": "403",
  "menu.exception": "Exception",
  "menu.editor.mind": "Mind Editor",
  "menu.editor.koni": "Koni Editor",
  "menu.editor.flow": "Flow Editor",
  "menu.editor": "Graphic Editor",
  "menu.dashboard.workplace": "Workplace",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.analysis": "Analysis",
  "menu.dashboard": "Dashboard",
  "menu.account.trigger": "Trigger Error",
  "menu.account.settings": "Account Settings",
  "menu.account.logout": "Logout",
  "menu.account.center": "Account Center",
  "menu.account": "Account",
  "layout.user.link.terms": "Terms",
  "layout.user.link.privacy": "Privacy",
  "layout.user.link.help": "Help",
  "label.referralId": "Referral ID",
  "label.password": "Password",
  "label.ga": "GA Code",
  "label.email": "Email or Username",
  "input.placeholder.referralId": "Referral ID (Optional)",
  "input.placeholder.password": "Please enter your password",
  "input.placeholder.oldPassword": "Please enter old password",
  "input.placeholder.newPassword": "Please enter new password",
  "input.placeholder.ga": "GA Code Enabled",
  "input.placeholder.enter": "Please enter",
  "input.placeholder.emailVerifyCode": "Email Verification Code (6 numbers)",
  "input.placeholder.email": "Please enter your email",
  "input.placeholder.confirmPassword": "Please confirm password",
  "input.error.passwordsNotMatch": "The two passwords are different. Please reenter it.",
  "input.error.invalidEmail": "Invalid email address.",
  "input.error.empty": "Required field.",
  "input.error.confirmPasswordEmpty": "Please confirm your password.",
  "errorCode.OLD_GA_CODE_WRONG": "Wrong old GA code.",
  "errorCode.NEW_GA_CODE_WRONG": "Wrong GA Code",
  "errorCode.GA_NOT_OPEN": "GA is not set.",
  "errorCode.GA_NOT_BOUND": "GA is not bound",
  "errorCode.GA_NOTNULL": "GA Not Null",
  "errorCode.GA_INVALID": "GA verification code error or expired.",
  "errorCode.GA_CODE_WRONG": "Wrong GA Code ",
  "errorCode.GA_BIND_REQUEST_EXPIRED": "GA Binding Request Expired",
  "errorCode.GA_ALREADY_BOUND": "GA code already bound",
  "components.kyc.Title.link": "Link",
  "components.kyc.Title.kyc": "KYC",
  "components.kyc.Status.pending": "Your KYC verification is still pending",
  "components.kyc.Status.haveNotSet": "Reminder: You haven't started",
  "components.kyc.Status.haveNotCompleted": "Reminder: You haven't completed",
  "components.kyc.Status.enhancedKYC": "Reminder: You need to submit additional documents",
  "components.Header.menuTradeHistory": "Trade History",
  "components.Header.menuSupportCenter": "Support Center",
  "components.Header.menuSupport": "Support",
  "components.Header.menuSubmitRequest": "Submit Request",
  "components.Header.menuRegister": "Register",
  "components.Header.menuOrders": "Orders",
  "components.Header.menuOrderHistory": "Order History",
  "components.Header.menuOpenOrders": "Open Orders",
  "components.Header.menuNews": "News",
  "components.Header.menuMonthlyStatements": "Monthly Account Statement",
  "components.Header.menuLogin": "Login",
  "components.Header.menuCrypto": "Virtual Assets",
  "components.Header.menuAssets": "Assets",
  "components.Header.menuAPI": "API Management",
  "components.Header.menuAccount": "Account",
  "components.Footer.menuUserProtocol": "Support Center",
  "components.Footer.menuSupport": "Support",
  "components.Footer.menuStatementOfClauses": "Customer Support",
  "components.Footer.menuSocial": "Social",
  "components.Footer.menuReturnCommission": "Referral",
  "components.Footer.menuPrivacyServices": "Privacy Services",
  "components.Footer.menuPlatformSupport": "Services",
  "components.Footer.menuLegalStatement": "Legal & Privacy",
  "components.Footer.menuFees": "Fee Schedule",
  "components.Footer.menuContactUS": "Contact Us",
  "components.Footer.menuCompanyProfile": "Our Company",
  "components.Footer.menuAPIDocument": "API Document",
  "components.Footer.menuAnnouncement": "Announcement",
  "components.Footer.menuAboutUS": "About Us",
  "component.tagSelect.expand": "Expand",
  "component.tagSelect.collapse": "Collapse",
  "component.tagSelect.all": "All",
  "component.SymbolToggle.volume": "24h Volume",
  "component.SymbolToggle.pair": "Pair",
  "component.SymbolToggle.lastPrice": "Last Price",
  "component.SymbolToggle.change": "24h Change",
  "component.priceOrder.total": "Total",
  "component.priceOrder.sell": "Sell",
  "component.priceOrder.price": "Price",
  "component.priceOrder.optionText": "Select Depth",
  "component.priceOrder.depthStep6": "1000",
  "component.priceOrder.depthStep5": "100",
  "component.priceOrder.depthStep4": "10",
  "component.priceOrder.depthStep3": "1",
  "component.priceOrder.depthStep2": "0.1",
  "component.priceOrder.depthStep1": "{size}",
  "component.priceOrder.buyAndSell": "All",
  "component.priceOrder.buy": "Buy",
  "component.priceOrder.amount": "Amount",
  "component.priceOrder.all": "All",
  "component.OrderHistoryBox.empty": "You have no order history",
  "component.OrderBox.openOrder": "Open Orders",
  "component.OrderBox.more": "More",
  "component.OrderBox.historyOrder": "Order History",
  "component.noticeIcon.view-more": "View more",
  "component.noticeIcon.empty": "No notifications",
  "component.noticeIcon.cleared": "Cleared",
  "component.noticeIcon.clear": "Clear",
  "component.modal.title.warning": "Warning",
  "component.modal.title.error": "Error",
  "component.hudbar.lastPrice": "Last Price",
  "component.hudbar.24hVolume": "24h Volume",
  "component.hudbar.24hLow": "24h Low",
  "component.hudbar.24hHigh": "24h High",
  "component.hudbar.24hChange": "24h Change",
  "component.header.nav.trade": "Start Trading",
  "component.globalHeader.search.example3": "Search example 3",
  "component.globalHeader.search.example2": "Search example 2",
  "component.globalHeader.search.example1": "Search example 1",
  "component.globalHeader.search": "Search",
  "component.globalHeader.notification.empty": "You have checked all notifications.",
  "component.globalHeader.notification": "Notification",
  "component.globalHeader.message.empty": "You have checked all messsages.",
  "component.globalHeader.message": "Message",
  "component.globalHeader.help": "Help",
  "component.globalHeader.event.empty": "You have checked all the to-do items.",
  "component.globalHeader.event": "To-do items",
  "component.common.marketOrder": "Market",
  "app.settings.security.weak": "Weak",
  "app.settings.security.strong": "Strong",
  "app.settings.security.set": "Set",
  "app.settings.security.question-description": "Please set your security question, which can effectively protect the account security.",
  "app.settings.security.question": "Security Question",
  "app.settings.security.phone-description": "Phone bound",
  "app.settings.security.phone": "Security Phone",
  "app.settings.security.password-description": "Current password strength",
  "app.settings.security.password": "Account Password",
  "app.settings.security.modify": "Modify",
  "app.settings.security.mfa-description": "MFA device not bound, to start the confirmation twice after binding",
  "app.settings.security.mfa": "MFA Device",
  "app.settings.security.medium": "Medium",
  "app.settings.security.email-description": "Email bound",
  "app.settings.security.email": "Backup Email",
  "app.settings.security.bind": "Bind",
  "app.settings.open": "Open",
  "app.settings.notification.todo-description": "The to-do list will be notified in the form of message.",
  "app.settings.notification.todo": "To-do Notification",
  "app.settings.notification.password-description": "Messages from other users will be notified in the form of a station letter",
  "app.settings.notification.password": "Account Password",
  "app.settings.notification.messages-description": "System information will be notified in the form of message.",
  "app.settings.notification.messages": "System Messages",
  "app.settings.menuMap.security": "Security Settings",
  "app.settings.menuMap.notification": "New Message Notification",
  "app.settings.menuMap.binding": "Account Binding",
  "app.settings.menuMap.basic": "Basic Settings",
  "app.settings.close": "Close",
  "app.settings.binding.taobao-description": "No Taobao currently bound.",
  "app.settings.binding.taobao": "Binding Taobao",
  "app.settings.binding.dingding-description": "No DingTalk currently bound.",
  "app.settings.binding.dingding": "Binding DingTalk",
  "app.settings.binding.bind": "Bind",
  "app.settings.binding.alipay-description": "No Alipay currently bound.",
  "app.settings.binding.alipay": "Binding Alipay",
  "app.settings.basic.update": "Update Information",
  "app.settings.basic.profile-placeholder": "Brief introduction about yourself",
  "app.settings.basic.profile-message": "Please input your personal profile!",
  "app.settings.basic.profile": "Personal profile",
  "app.settings.basic.phone-message": "Please input your phone number!",
  "app.settings.basic.phone": "Phone Number",
  "app.settings.basic.nickname-message": "Please input your Nickname!",
  "app.settings.basic.nickname": "Nickname",
  "app.settings.basic.geographic-message": "Please input your geographic info!",
  "app.settings.basic.geographic": "Province or city",
  "app.settings.basic.email-message": "Please input your email!",
  "app.settings.basic.email": "Email",
  "app.settings.basic.country-message": "Please input your country!",
  "app.settings.basic.country": "Country / Region",
  "app.settings.basic.change-avatar": "Change avatar",
  "app.settings.basic.avatar": "Avatar",
  "app.settings.basic.address-message": "Please input your address!",
  "app.settings.basic.address": "Street Address",
  "app.setting.weakmode": "Weak Mode",
  "app.setting.topmenu": "Top Menu Layout",
  "app.setting.themecolor.volcano": "Volcano",
  "app.setting.themecolor.sunset": "Sunset Orange",
  "app.setting.themecolor.purple": "Golden Purple",
  "app.setting.themecolor.green": "Polar Green",
  "app.setting.themecolor.geekblue": "Geek Glue",
  "app.setting.themecolor.dust": "Dust Red",
  "app.setting.themecolor.daybreak": "Daybreak Blue (Default)",
  "app.setting.themecolor.cyan": "Cyan",
  "app.setting.themecolor": "Theme Color",
  "app.setting.sidemenu": "Side Menu Layout",
  "app.setting.production.hint": "Setting panel shows in development environment only, please manually modify",
  "app.setting.pagestyle.light": "Light style",
  "app.setting.pagestyle.dark": "Dark style",
  "app.setting.pagestyle": "Page style setting",
  "app.setting.othersettings": "Other Settings",
  "app.setting.navigationmode": "Navigation Mode",
  "app.setting.hideheader.hint": "Works when Hidden Header is enabled",
  "app.setting.hideheader": "Hide Header when scrolling",
  "app.setting.fixedsidebar.hint": "Works on Side Menu Layout",
  "app.setting.fixedsidebar": "Fixed Sidebar",
  "app.setting.fixedheader": "Fixed Header",
  "app.setting.copyinfo": "Copy succeeded, please replace default settings in src/models/setting.js.",
  "app.setting.copy": "Copy Setting",
  "app.setting.content-width.fluid": "Fluid",
  "app.setting.content-width.fixed": "Fixed",
  "app.setting.content-width": "Content Width",
  "app.pwa.serviceworker.updated.ok": "Refresh",
  "app.pwa.serviceworker.updated.hint": "Please press the \"Refresh\" button to reload current page",
  "app.pwa.serviceworker.updated": "New content is available",
  "app.pwa.offline": "You are offline now",
  "app.preview.down.block": "Download this page to your local project",
  "app.containers.WithdrawPage.value": "Value",
  "app.containers.WithdrawPage.trade": "Go to Trade",
  "app.containers.WithdrawPage.tipGA": "After Two-Factor verification, the withdrawal address can be added.",
  "app.containers.WithdrawPage.tip2": "After making a withdrawal, you can track its progress on the history page.",
  "app.containers.WithdrawPage.tip1": "Once you submitted the withdrawal request, we will send you a confirmation email. Please click the confirmation link in the email.",
  "app.containers.WithdrawPage.tip": "Please note",
  "app.containers.WithdrawPage.time": "Time",
  "app.containers.WithdrawPage.submit": "Submit",
  "app.containers.WithdrawPage.status": "Status",
  "app.containers.WithdrawPage.pointError": "Amount decimal places must be no more than {decimalPlaces}",
  "app.containers.WithdrawPage.number": "Amount",
  "app.containers.WithdrawPage.noMsg": "You have no withdrawal history.",
  "app.containers.WithdrawPage.moreError": "Beyond the maximum withdrawal limit",
  "app.containers.WithdrawPage.more": "View All",
  "app.containers.WithdrawPage.limit": "Withdrawal Limit",
  "app.containers.WithdrawPage.lessError": "Beyond the minimum withdrawal limit",
  "app.containers.WithdrawPage.important4": "Maximum withdrawal",
  "app.containers.WithdrawPage.important3": "Do not withdraw directly to a crowdfund or ICO address",
  "app.containers.WithdrawPage.important2": "",
  "app.containers.WithdrawPage.important1": "Minimum withdrawal",
  "app.containers.WithdrawPage.important": "Important",
  "app.containers.WithdrawPage.history": "History",
  "app.containers.WithdrawPage.header": "Withdrawals",
  "app.containers.WithdrawPage.get": "You Will Get",
  "app.containers.WithdrawPage.gaCode": "GA code",
  "app.containers.WithdrawPage.filled": "confirm",
  "app.containers.WithdrawPage.fee": "Transaction Fee",
  "app.containers.WithdrawPage.emptyError": "This field is required.",
  "app.containers.WithdrawPage.delete": "Delete",
  "app.containers.WithdrawPage.currency": "Currency",
  "app.containers.WithdrawPage.amountError": "Amount must be larger than {baseAmount}",
  "app.containers.WithdrawPage.amount": "Amount",
  "app.containers.WithdrawPage.addressName": "Address",
  "app.containers.WithdrawPage.address": "Withdrawal Address",
  "app.containers.WithdrawPage.addmsg": "Add the address",
  "app.containers.WithdrawPage.add": "Add",
  "app.containers.tradeBox.priceDiff": "The price difference is {rate}%, please confirm that you want to make this order.",
  "app.containers.ResetPage.vcode": "Verification code",
  "app.containers.ResetPage.rePassword": "Confirm Password",
  "app.containers.ResetPage.newPassword": "New Password",
  "app.containers.ResetPage.lengthError": "8 characters at least for password with numbers and uppercase letters.",
  "app.containers.ResetPage.header": "Reset Password",
  "app.containers.ResetPage.getCode": "Send Email",
  "app.containers.ResetPage.button": "Save",
  "app.containers.RegisterValidatePage.tip": "If you have not received the email, please try the following:",
  "app.containers.RegisterValidatePage.step4": "Make sure your email function is working properly.",
  "app.containers.RegisterValidatePage.step3": "Add Matrix to your email address whitelist.",
  "app.containers.RegisterValidatePage.step2": "Please check your spam or junk mail.",
  "app.containers.RegisterValidatePage.step1": "Make sure the email address you provided is correct.",
  "app.containers.RegisterValidatePage.REGISTRATION_REQUEST_EXPIRED": "Email verification code expired",
  "app.containers.RegisterValidatePage.header": "Email Verification",
  "app.containers.RegisterValidatePage.getCode": "Send Email",
  "app.containers.RegisterValidatePage.button": "Confirm",
  "app.containers.RegisterPage.REGISTRATION_REQUEST_EXPIRED": "Email verification code expired",
  "app.containers.RegisterPage.referralId": "Referral ID",
  "app.containers.RegisterPage.password": "Password",
  "app.containers.RegisterPage.name": "Name",
  "app.containers.RegisterPage.login": "Sign In",
  "app.containers.RegisterPage.lengthError": "8 characters at least for password with numbers and uppercase letters.",
  "app.containers.RegisterPage.header": "Create an Account",
  "app.containers.RegisterPage.getCode": "Send Email",
  "app.containers.RegisterPage.emptyError": "Required field.",
  "app.containers.RegisterPage.emailError": "Please enter the email with correct format.",
  "app.containers.RegisterPage.email": "Email",
  "app.containers.RegisterPage.copassword": "Confirm Password",
  "app.containers.RegisterPage.confirmError": "Passwords are not the same.",
  "app.containers.RegisterPage.code": "Verification code",
  "app.containers.RegisterPage.button": "Sign up",
  "app.containers.RegisterPage.AUTH_GA_NOTNULL": "Please enter Two-Factor Authentication code.",
  "app.containers.RegisterPage.AUTH_GA_INVALID": "Two-Factor Authentication code error.",
  "app.containers.RegisterPage.alreadyRegister": "Already Registered ?",
  "app.containers.RegisterPage.ageAgreementTip": "Please agree over 21 years old.",
  "app.containers.RegisterPage.agreement": "I agree",
  "app.containers.RegisterPage.agreeLink": "Matrix's Terms of Use",
  "app.containers.RegisterPage.ageAgreement": " I am over 21 years old.",
  "app.containers.RechargePage.value": "Value",
  "app.containers.RechargePage.trade": "Go to Trade",
  "app.containers.RechargePage.tip4": "history page",
  "app.containers.RechargePage.tip3": "After making a deposit, you can track its progress on the history page.",
  "app.containers.RechargePage.tip2": " more network confirmations.",
  "app.containers.RechargePage.tip1": "Coins will be deposited immediately after",
  "app.containers.RechargePage.tip": "Please note",
  "app.containers.RechargePage.time": "Time",
  "app.containers.RechargePage.status": "Status",
  "app.containers.RechargePage.qrcode": "Show QR Code",
  "app.containers.RechargePage.number": "Amount",
  "app.containers.RechargePage.noMsg": "You have no deposit history.",
  "app.containers.RechargePage.more": "More",
  "app.containers.RechargePage.history": "History",
  "app.containers.RechargePage.header": "Deposit",
  "app.containers.RechargePage.filled": "Confirm",
  "app.containers.RechargePage.currency": "Currency",
  "app.containers.RechargePage.cpoy": "Copy Address",
  "app.containers.RechargePage.attentionText2": "to this deposit address. Sending any other coin or token to this address may result in the loss of your deposit.",
  "app.containers.RechargePage.attentionText1": "Send only",
  "app.containers.RechargePage.attention": "Important",
  "app.containers.RechargePage.addressName": "To Address",
  "app.containers.RechargePage.address": "Address",
  "app.containers.PasswordPage.tip": "For security purposes, no withdrawals are permitted within 24 hours after resetting the password.",
  "app.containers.PasswordPage.savePassword": "Confirm",
  "app.containers.PasswordPage.oldPassword": "Old Password",
  "app.containers.PasswordPage.oldoldError": "Passwords are not the same.",
  "app.containers.PasswordPage.newPassword": "New Password",
  "app.containers.PasswordPage.lengthError": "8 characters at least for password with numbers and uppercase letters.",
  "app.containers.PasswordPage.header": "This is the Password Page container!",
  "app.containers.PasswordPage.emptyError": "Required field.",
  "app.containers.PasswordPage.confirmPassword": "Confirm New Password",
  "app.containers.PasswordPage.change": "Change Your Password",
  "app.containers.PasswordPage.account": "Account",
  "app.containers.LoginPage.register": "Register",
  "app.containers.LoginPage.noAccount": "No account yet? ",
  "app.containers.LoginPage.header": "Login",
  "app.containers.LoginPage.GA_NOTNULL": "Please enter GA verification code.",
  "app.containers.LoginPage.GA_INVALID": "Two-Factor Authentication code error.",
  "app.containers.LoginPage.forgotPassword": "Forgot Password?",
  "app.containers.LoginPage.button": "Log In",
  "app.containers.LoginPage.AUTH_GA_NOTNULL": "Please enter Two-Factor Authentication code.",
  "app.containers.LoginPage.AUTH_GA_INVALID": "Two-Factor Authentication code error.",
  "app.containers.LoginPage.alreadyLoginTip": "You have already logged in with one account, please go to the homepage. Be aware that you can only log in with one account.",
  "app.containers.ForgotpasswordPage.message": "For security purposes, no withdrawals are permitted within 24 hours after resetting any password.",
  "app.containers.ForgotpasswordPage.header": "Forgot Password",
  "app.containers.ForgotpasswordPage.emptyError": "This field is required.",
  "app.containers.ForgotpasswordPage.emailError": "Please enter the email with correct format.",
  "app.containers.ForgotpasswordPage.email": "Email",
  "app.containers.ForgotpasswordPage.button": "Submit",
  "app.containers.BalancePage.withdraw": "Withdraw",
  "app.containers.BalancePage.trade": "Trade",
  "app.containers.BalancePage.totalEstimate": "Total Estimate",
  "app.containers.BalancePage.remainder": "Available Assets ",
  "app.containers.BalancePage.recharge": "Deposit",
  "app.containers.BalancePage.locked": "Locked",
  "app.containers.BalancePage.hideZeroBalance": "Hide small balances",
  "app.containers.BalancePage.header": "Assets",
  "app.containers.BalancePage.fullname": "Full Name",
  "app.containers.BalancePage.coin": "Currency",
  "app.containers.BalancePage.block": "Frozen Assets",
  "app.containers.BalancePage.amount": "Assets",
  "app.containers.BalancePage.about": "USD Valuation"
}
